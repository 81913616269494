import cx from "classnames";

import { Heading } from "@/components/Heading";

import "./Card.scss";
import {
  Shadow,
  Spacing,
  spacing,
  boxShadow,
  Radius,
  radius as bRadius,
  BackdropFilter,
  backdropFilter as bFilter,
} from "@/lib/design-tokens";
import { tokenDefKeyToCssVar } from "@/lib/design-token-utils";

type CardProps = {
  title?: string;
  subtitle?: string;
  level?: 1 | 2 | 3;
  children?: React.ReactNode;
  className?: string;
  as?: "article" | "section" | "nav" | "aside" | "div";
  padding?: Spacing;
  shadow?: Shadow;
  radius?: Radius;
  backdropFilter?: BackdropFilter;
};

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  level = 2,
  children,
  className,
  as = "section",
  padding,
  shadow,
  radius,
  backdropFilter,
}) => {
  const Wrapper = as as React.ElementType;
  return (
    <Wrapper
      className={cx("Card", className)}
      style={{
        padding: padding ? tokenDefKeyToCssVar(spacing, padding) : undefined,
        boxShadow: shadow ? tokenDefKeyToCssVar(boxShadow, shadow) : undefined,
        borderRadius: radius ? tokenDefKeyToCssVar(bRadius, radius) : undefined,
        backdropFilter: backdropFilter ? tokenDefKeyToCssVar(bFilter, backdropFilter) : undefined,
      }}
    >
      {title && (
        <div className="Card__Header">
          <Heading level={level} size="sm">
            {title}
          </Heading>
          {subtitle && <div className="Card__Header__Subtitle">{subtitle}</div>}
        </div>
      )}
      <div className="Card__Body">{children}</div>
    </Wrapper>
  );
};

export { Card };
