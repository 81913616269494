/**
 * WebSocket Service
 *
 * This module provides a flexible WebSocket connection management system.
 * It handles:
 * - Creating and managing WebSocket connections
 * - Network status event handling
 * - Listener registration for different message types
 */

// Interface for WebSocket connection state
export interface WebSocketConnection {
  ws: WebSocket | null;
  isOpen: boolean;
  isConnecting: boolean;
  listeners: Map<string, Set<(message: any) => void>>;
  reconnectAttempts: number;
  reconnectTimeout: number | null;
  url: string | null;
  params?: string; // Store params as string for reconnection
  manuallyClosing?: boolean; // Flag to indicate intentional closure
}

// Connection store for different WebSocket endpoints
export const connections: Map<string, WebSocketConnection> = new Map();

// Maximum number of reconnection attempts
const MAX_RECONNECT_ATTEMPTS = 10;
// Base delay for exponential backoff (in milliseconds)
const BASE_RECONNECT_DELAY = 1000;
// Maximum delay for exponential backoff (in milliseconds)
const MAX_RECONNECT_DELAY = 30000;

/**
 * Creates a WebSocket connection or returns an existing one
 * @param connectionId Unique identifier for this connection
 * @param url The WebSocket URL to connect to
 * @param params Optional URL parameters
 * @returns The WebSocket connection object
 */
export function createWebSocketConnection(
  connectionId: string,
  url: string,
  params?: URLSearchParams,
): WebSocketConnection {
  // Check if connection already exists
  let connection = connections.get(connectionId);

  // If connection exists and is open, return it
  if (connection?.isOpen) {
    return connection;
  }

  // Create new connection if it doesn't exist
  if (!connection) {
    connection = {
      ws: null,
      isOpen: false,
      isConnecting: false,
      listeners: new Map(),
      reconnectAttempts: 0,
      reconnectTimeout: null,
      url: null,
    };
    connections.set(connectionId, connection);
  }

  // Connect if not already connecting
  if (!connection.isConnecting) {
    connectWebSocket(connectionId, url, params);
  }

  return connection;
}

/**
 * Connect to a WebSocket endpoint
 * @param connectionId Unique identifier for this connection
 * @param url The WebSocket URL to connect to
 * @param params Optional URL parameters
 */
export function connectWebSocket(
  connectionId: string,
  url: string,
  params?: URLSearchParams,
): void {
  const connection = connections.get(connectionId);
  if (!connection) {
    return;
  }

  // If already connecting or open, don't reconnect
  if (connection.isConnecting || connection.isOpen) {
    return;
  }

  connection.isConnecting = true;
  connection.url = url;

  // Store the params for reconnection
  if (params) {
    connection.params = params.toString();
  }

  try {
    // Create a new WebSocket connection
    const fullUrl = params ? `${url}?${params}` : url;
    const ws = new WebSocket(fullUrl);

    ws.onopen = () => {
      connection.isOpen = true;
      connection.isConnecting = false;
      connection.ws = ws;
      connection.reconnectAttempts = 0;
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        const messageType = data.type;

        // Find all listeners for this message type
        const listeners = connection.listeners.get(messageType);
        if (listeners && listeners.size > 0) {
          // Notify all listeners
          listeners.forEach((listener) => {
            try {
              listener(data);
            } catch (error) {
              console.error(
                `Error in WebSocket listener for ${messageType}:`,
                error,
              );
            }
          });
        }
      } catch (error) {
        console.error(
          `Error parsing WebSocket message for ${connectionId}:`,
          error,
          event.data,
        );
      }
    };

    ws.onerror = (error) => {
      console.error(`WebSocket ${connectionId} error:`, error);
    };

    ws.onclose = (event) => {
      connection.isOpen = false;
      connection.isConnecting = false;
      connection.ws = null;

      // Only attempt to reconnect if the connection was not closed intentionally
      if (event.code !== 1000 && !connection.manuallyClosing) {
        // Handle policy violation (1008) which is used for authentication issues
        if (event.code === 1008) {
          console.error(
            `WebSocket closed due to policy violation: ${event.reason}`,
          );
        }
        attemptReconnect(connectionId);
      }

      // Reset the manually closing flag
      connection.manuallyClosing = false;
    };
  } catch (error) {
    console.error(`Error connecting to WebSocket ${connectionId}:`, error);
    connection.isConnecting = false;
    // Attempt to reconnect after a delay
    attemptReconnect(connectionId);
  }
}

/**
 * Attempt to reconnect to the WebSocket with exponential backoff
 * @param connectionId Unique identifier for the connection to reconnect
 */
function attemptReconnect(connectionId: string): void {
  const connection = connections.get(connectionId);
  if (!connection) {
    return;
  }

  if (connection.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
    console.log(
      `Maximum reconnection attempts (${MAX_RECONNECT_ATTEMPTS}) reached for ${connectionId}. Giving up.`,
    );
    return;
  }

  // Calculate delay with exponential backoff
  const delay = Math.min(
    BASE_RECONNECT_DELAY * Math.pow(2, connection.reconnectAttempts),
    MAX_RECONNECT_DELAY,
  );

  console.log(
    `Attempting to reconnect ${connectionId} in ${delay}ms (attempt ${connection.reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS})`,
  );

  // Clear any existing timeout
  if (connection.reconnectTimeout !== null) {
    window.clearTimeout(connection.reconnectTimeout);
  }

  // Set a timeout to reconnect
  connection.reconnectTimeout = window.setTimeout(() => {
    connection.reconnectAttempts++;

    // Reconnect using the stored URL and params
    if (connection.url) {
      if (connection.params) {
        // Recreate URLSearchParams from the stored string
        const params = new URLSearchParams(connection.params);
        connectWebSocket(connectionId, connection.url, params);
      } else {
        connectWebSocket(connectionId, connection.url);
      }
    } else {
      console.error(`No URL available for reconnection of ${connectionId}`);
    }
  }, delay);
}

/**
 * Register a listener for a specific message type
 * @param connectionId The connection ID to register the listener for
 * @param messageType The type of message to listen for
 * @param callback The callback to invoke when a message of this type is received
 * @returns A function to unregister the listener
 */
export function registerWebSocketListener(
  connectionId: string,
  messageType: string,
  callback: (message: any) => void,
): () => void {
  const connection = connections.get(connectionId);
  if (!connection) {
    return () => {};
  }

  // Get or create the set of listeners for this message type
  let listeners = connection.listeners.get(messageType);
  if (!listeners) {
    listeners = new Set();
    connection.listeners.set(messageType, listeners);
  }

  // Add the callback to the set of listeners
  listeners.add(callback);

  // Return a function to unregister the listener
  return () => {
    const connection = connections.get(connectionId);
    if (!connection) return;

    const listeners = connection.listeners.get(messageType);
    if (listeners) {
      listeners.delete(callback);
      if (listeners.size === 0) {
        connection.listeners.delete(messageType);
      }
    }
  };
}

/**
 * Close a WebSocket connection
 * @param connectionId The connection ID to close
 */
export function closeWebSocketConnection(connectionId: string): void {
  const connection = connections.get(connectionId);
  if (!connection) {
    return;
  }

  // Check if there are any active listeners before closing
  const hasActiveListeners = Array.from(connection.listeners.values()).some(
    (listeners) => listeners.size > 0,
  );

  if (hasActiveListeners) {
    return;
  }

  // Set the manually closing flag to prevent reconnection attempts
  connection.manuallyClosing = true;

  if (connection.reconnectTimeout !== null) {
    window.clearTimeout(connection.reconnectTimeout);
    connection.reconnectTimeout = null;
  }

  if (connection.ws && (connection.isOpen || connection.isConnecting)) {
    console.log(`Manually closing WebSocket connection ${connectionId}`);
    connection.ws.close(1000, "Intentional close");
    connection.isOpen = false;
    connection.isConnecting = false;
    connection.ws = null;
  }

  // Clear all listeners
  connection.listeners.clear();
  connection.reconnectAttempts = 0;
  connection.url = null;
}

/**
 * Close all WebSocket connections
 */
export function closeAllWebSocketConnections(): void {
  connections.forEach((_, connectionId) => {
    closeWebSocketConnection(connectionId);
  });
}

/**
 * Initialize WebSocket event listeners for network status changes
 */
export function initializeWebSocketEventListeners(): void {
  window.addEventListener("online", () => {
    console.log(
      "Network connection restored. Attempting to reconnect WebSockets...",
    );

    // Attempt to reconnect all connections
    connections.forEach((connection, connectionId) => {
      if (!connection.isOpen && !connection.isConnecting && connection.url) {
        connection.reconnectAttempts = 0; // Reset reconnect attempts on network restore
        connectWebSocket(connectionId, connection.url);
      }
    });
  });

  window.addEventListener("offline", () => {
    console.log(
      "Network connection lost. WebSockets will attempt to reconnect when online.",
    );
  });
}

// Initialize network event listeners when the module is imported
initializeWebSocketEventListeners();

/**
 * Helper function to create WebSocket URL based on current hostname
 * @param path The WebSocket path (e.g., '/ws/user/')
 * @param params Optional URL parameters
 * @returns The complete WebSocket URL
 */
export function createWebSocketUrl(
  path: string,
  params?: URLSearchParams,
): string {
  const protocol = window.location.hostname.includes("local.pingintel")
    ? "ws"
    : "wss";
  const port = window.location.hostname.includes("local.pingintel")
    ? ":8002"
    : "";
  const baseUrl = `${protocol}://${window.location.hostname}${port}${path}`;

  return params ? `${baseUrl}?${params}` : baseUrl;
}
