import React, { FC, ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import cx from "classnames";

import "./PVTabs.scss";

type TabsContextType = {
  activeTabIndex: number;
  setActiveTabIndex: (tabIndex: number) => void;
};

const TabsContext = createContext<TabsContextType | null>(null);

const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};

export const TabsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <TabsContext.Provider
      value={{ activeTabIndex: activeTabIndex, setActiveTabIndex }}
    >
      {children}
    </TabsContext.Provider>
  );
};

type TabsChildContextType = {
  tabIndex: number;
};

const TabsChildContext = createContext<TabsChildContextType | null>(null);

const useTabsChildContext = () => {
  const context = useContext(TabsChildContext);
  if (!context) {
    throw new Error(
      "useTabsChildContext must be used within a TabsChildProvider"
    );
  }
  return context;
};

export const TabsChildProvider: FC<{
  tabIndex: number;
  children: ReactNode;
}> = ({ tabIndex, children }) => {
  return (
    <TabsChildContext.Provider value={{ tabIndex }}>
      {children}
    </TabsChildContext.Provider>
  );
};

type PVTabsProps = {
  children: ReactNode;
};

export const PVTabs: FC<PVTabsProps> = ({ children }) => {
  return (
    <TabsProvider>
      <div className="PVTabs">{children}</div>
    </TabsProvider>
  );
};

type PVTabsListProps = {
  children: ReactNode;
  className?: string;
};

export const PVTabsList: FC<PVTabsListProps> = ({ children, className }) => {
  const appliedClasses = cx("PVTabsList", className);

  return (
    <div className={appliedClasses}>
      {React.Children.map(children, (child, index) => (
        <TabsChildProvider tabIndex={index}>{child}</TabsChildProvider>
      ))}
    </div>
  );
};

type PVTabsListItemProps = {
  children: ReactNode;
};

export const PVTabsListItem: FC<PVTabsListItemProps> = ({ children }) => {
  const { activeTabIndex, setActiveTabIndex } = useTabsContext();
  const { tabIndex } = useTabsChildContext();

  const appliedClasses = cx("PVTabsListItem", {
    "PVTabsListItem--IsActive": activeTabIndex === tabIndex,
  });

  return (
    <div className={appliedClasses} onClick={() => setActiveTabIndex(tabIndex)}>
      {children}
    </div>
  );
};

type PVTabsContentProps = {
  children: ReactNode;
};

export const PVTabsContent: FC<PVTabsContentProps> = ({ children }) => {
  return (
    <div className="PVTabsContent">
      {React.Children.map(children, (child, index) => (
        <TabsChildProvider tabIndex={index}>{child}</TabsChildProvider>
      ))}
    </div>
  );
};

type PVTabsContentItemProps = {
  children: ReactNode;
  className?: string;
};

export const PVTabsContentItem: FC<PVTabsContentItemProps> = ({
  children,
  className,
}) => {
  const { activeTabIndex } = useTabsContext();
  const { tabIndex } = useTabsChildContext();

  const appliedClasses = cx("PVTabsContentItem", className);

  if (activeTabIndex !== tabIndex) {
    return null;
  }

  return <div className={appliedClasses}>{children}</div>;
};
