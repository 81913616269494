// @ts-nocheck
import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import debounce from "lodash/debounce";
import { ErrorCard } from "@repo/ping-design";

const TOAST_STYLES = {
  minWidth: "300px",
};

const resetErrorDisplayed = debounce(() => {
  // only displace single error when backend goes down
  window.hasDisplayedApiError = false;
}, 3000);

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (!window.hasDisplayedApiError) {
        let errorMsg = "";
        if (action.payload?.originalStatus === 500) {
          errorMsg =
            "A server error has occurred. Our development team has been notified. Please try again later.";
        } else {
          errorMsg =
            action.payload?.data?.detail ||
            "There was a problem with your request.";
        }

        toast.error(errorMsg, {
          duration: 2000,
          style: TOAST_STYLES,
        });

        window.hasDisplayedApiError = true;
        resetErrorDisplayed();
      }
    }

    return next(action);
  };

export const rtkQueryMapsErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (!window.hasDisplayedApiError) {
        let errorMsg = "";
        if (action.payload?.originalStatus === 500) {
          errorMsg =
            "A server error has occurred. Our development team has been notified. Please try again later.";
        } else {
          errorMsg =
            action.payload?.data?.detail ||
            "There was a problem with your request.";
        }

        const currentPath = window.location.pathname;

        if (currentPath.includes("/new")) {
          if (currentPath.includes("/maps/pli")) {
            toast.custom(
              (t) => (
                <ErrorCard
                  title="Error"
                  error={errorMsg}
                  onClose={() => {
                    toast.dismiss();
                  }}
                  variant="border"
                />
              ),
              { duration: 2000, style: TOAST_STYLES, position: "top-center" },
            );
          } else {
            toast.custom(
              (t) => (
                <div style={{ position: "relative", top: "44px" }}>
                  <ErrorCard
                    title="Error"
                    error={errorMsg}
                    onClose={() => {
                      toast.dismiss();
                    }}
                    variant="border"
                  />
                </div>
              ),
              { duration: 2000, style: TOAST_STYLES, position: "top-center" },
            );
          }
        } else {
          toast.error(errorMsg, {
            duration: 2000,
            style: TOAST_STYLES,
          });
        }

        window.hasDisplayedApiError = true;
        resetErrorDisplayed();
      }
    }

    return next(action);
  };
