import { ButtonHTMLAttributes, ReactNode } from "react";
import cx from "classnames";

import "./PingButton.scss";

type PingButtonProps = {
  label?: string;
  variant?: "normal" | "primary";
  className?: string;
  children?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const PingButton = ({
  label,
  variant = "normal",
  className,
    children,
  ...restProps
}: PingButtonProps) => {
  const appliedClasses = cx("PingButton", className, {
    PingButton__Primary: variant === "primary",
  });

  return (
    <button {...restProps} className={appliedClasses}>
      {children || label}
    </button>
  );
};
