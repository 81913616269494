import { memo, Fragment, useState, Key } from "react";
import cx from "classnames";
import DataGrid, {
  DataGridHandle,
  DataGridProps,
  SelectColumn,
} from "react-data-grid";

import "./DataGrid.scss";

export const SelectColumnNoSelectAll = {
  ...SelectColumn,
  ...{ name: "select-row" },
  renderHeaderCell: () => null,
};

type RowWithId<K> = { id: K };

type PingDataGridProps<
  R extends RowWithId<K>,
  SR,
  K extends Key,
> = DataGridProps<R, SR, K> & {
  isFetching?: boolean;
  shouldSuppressBorder?: boolean;
  selectedRowIds?: K[];
  rowClass?: (row: R, rowIdx: number) => string;
  setParentRef?: (ref: DataGridHandle | null) => void;
  onSelectedRowsChange?: (selectedRows: Set<Key>) => void;
  rowHeight?: number;
};

export const PDataGrid = <
  R extends RowWithId<K>,
  SR = unknown,
  K extends Key = Key,
>({
  columns,
  className,
  isFetching = false,
  shouldSuppressBorder = false,
  selectedRowIds,
  rowClass,
  rowHeight,
  setParentRef,
  onSelectedRowsChange,
  ...restProps
}: PingDataGridProps<R, SR, K>) => {
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<Key>>(
    () => new Set<Key>(),
  );

  const appliedClasses = cx("rdg-light DataGrid", className, {
    "DataGrid--SuppressBorder": shouldSuppressBorder,
  });

  const handleSelectedRowsChange = (newSelectedRows: Set<Key>) => {
    setSelectedRows(newSelectedRows);
    onSelectedRowsChange?.(newSelectedRows);
  };

  const columnsWithEmptyHandling = columns.map((column) => {
    const originalRenderer = column.renderCell;

    return {
      ...column,
      renderCell: (props) => {
        // If there's a custom renderer already, use it
        if (originalRenderer) {
          const renderedValue = originalRenderer(props);
          // Check if the rendered value is empty or undefined
          if (
            renderedValue === null ||
            renderedValue === undefined ||
            renderedValue === ""
          ) {
            return <>—</>;
          }
          return renderedValue;
        }

        // For regular value rendering
        const value = props.row[column.key as keyof R];
        if (value === null || value === undefined || value === "") {
          return <>—</>;
        }
        return value;
      },
    };
  });

  return (
    <DataGrid
      selectedRows={selectedRows}
      onSelectedRowsChange={handleSelectedRowsChange}
      ref={(refValue) => setParentRef?.(refValue)}
      className={appliedClasses}
      renderers={{
        noRowsFallback: isFetching ? (
          <LoadingRowsRenderer numColumns={columns.length} />
        ) : null,
      }}
      rowHeight={rowHeight || 32}
      headerRowHeight={40}
      columns={columnsWithEmptyHandling}
      rowClass={(row, rowIdx) => {
        const parentRowClass = rowClass?.(row, rowIdx);
        const isSelected = selectedRowIds?.includes(row.id) ?? false;

        return cx(
          "DataGrid__Row",
          isSelected && "DataGrid__Row--IsSelected",
          parentRowClass,
        );
      }}
      {...restProps}
    />
  );
};

// Most displays won't have more than 50 rows visible at the same time, so we'll
// use that as the default.
const DEFAULT_NUM_ROWS = 50;

const LoadingRowsRenderer = memo(
  ({
    rows = DEFAULT_NUM_ROWS,
    numColumns: columns,
  }: {
    rows?: number;
    numColumns: number;
  }) => {
    return (
      <div className="DataGrid__LoadingRowsRenderer">
        {Array.from({ length: rows }).map((_, i) => (
          <Fragment key={`row-${i}`}>
            {Array.from({ length: columns }).map((_, j) => (
              <div
                key={`cell-${i}-${j}`}
                className="DataGrid__LoadingRowsRenderer__Cell rdg-cell"
              ></div>
            ))}
          </Fragment>
        ))}
      </div>
    );
  },
);
