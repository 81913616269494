import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { PingButton, PingLucideIcon } from "@repo/ping-react-js";
import { PingVisionModal } from "../../components/modals/PingVisionModal";
import { useAppSelector } from "utils/redux";
import {
  useCreateNavFilterMutation,
  useUpdateNavFilterMutation,
  useDeleteNavFilterMutation,
} from "services/pvSlice";
import { useGetNav } from "features/submission-dashboard/queries";
import { useSubmissionsQueryGlobal } from "../../contexts/SubmissionsQueryGlobalContext.tsx";
import CustomViewForm from "./CustomViewForm";

import "./CustomViewManagementPage.scss";

// WIP file
const CustomViewManagementPage: React.FC = () => {
  const { data: navData } = useGetNav();
  const [createNavFilter, { isLoading: isCreating }] =
    useCreateNavFilterMutation();
  const [updateNavFilter, { isLoading: isUpdating }] =
    useUpdateNavFilterMutation();
  const [deleteNavFilter, { isLoading: isDeleting }] =
    useDeleteNavFilterMutation();
  const { handleSlugChangeForSubmissionsQuery } = useSubmissionsQueryGlobal();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  // State is managed via isEditModalOpen instead

  // Form state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("Filter");
  /* Please note that all readiness scores are multiplied by 100 before saving on the server, and divided by 100 when displayed */
  const [advancedFilters, setAdvancedFilters] = useState<Record<string, any>>({
    readiness: "",
    readiness__gt: "",
    readiness__lt: "",
    readiness_operator: "equal",
    claimed_by_id: "",
    workflow_status_id: "",
    team_id: "",
  });
  const [sortField, setSortField] = useState<
    "inception_date" | "received_time"
  >("received_time");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Get data from store
  const settings = useAppSelector((state) => state.settings.settings);
  const teams = settings?.teams;
  const workflowStatuses = settings?.submission_status;
  const customViews =
    navData?.views?.filter((view) => view.view_type === "USER_CUSTOM") || [];

  const resetForm = () => {
    setName("");
    setDescription("");
    setIcon("Filter");
    setAdvancedFilters({
      readiness: "",
      readiness__gt: "",
      readiness__lt: "",
      readiness_operator: "equal",
      claimed_by_id: [],
      workflow_status_id: [],
      team_id: [],
    });
    setSortField("received_time");
    setSortDirection("asc");
    setSelectedFilter(null);
  };

  /**
   * Creates a default advanced filters object with empty values
   * @returns A new advanced filters object with default values
   */
  const createDefaultAdvancedFilters = (): Record<string, any> => {
    return {
      readiness: "",
      readiness__gt: "",
      readiness__lt: "",
      readiness_operator: "equal",
      claimed_by_id: [],
      workflow_status_id: [],
      team_id: [],
    };
  };

  /**
   * Converts a filter object from the server format to the form state format
   * @param filterObj - The filter object from the server
   * @returns Advanced filters object for form state
   */
  const convertServerFilterToFormState = (
    filterObj: Record<string, any> = {},
  ): Record<string, any> => {
    const newAdvancedFilters = createDefaultAdvancedFilters();

    // Extract values from the filter
    Object.entries(filterObj).forEach(([key, value]) => {
      if (key === "readiness") {
        newAdvancedFilters.readiness = value / 100;
        newAdvancedFilters.readiness_operator = "equal";
      } else if (key === "readiness__gt") {
        newAdvancedFilters.readiness__gt = value / 100;
        newAdvancedFilters.readiness_operator = "greater_than";
      } else if (key === "readiness__lt") {
        newAdvancedFilters.readiness__lt = value / 100;
        newAdvancedFilters.readiness_operator = "less_than";
      } else if (
        ["workflow_status_id", "team_id", "claimed_by_id"].includes(key)
      ) {
        // Handle array fields
        newAdvancedFilters[key] = Array.isArray(value) ? value : [value];
      } else {
        newAdvancedFilters[key] = value;
      }
    });

    return newAdvancedFilters;
  };

  /**
   * Converts form state advanced filters to server filter format
   * @param advancedFilters - The advanced filters from form state
   * @returns Filter object for server
   */
  const convertFormStateToServerFilter = (
    advancedFilters: Record<string, any>,
  ): Record<string, any> => {
    const filter: Record<string, any> = {};

    // Only include non-empty values in the filter
    Object.entries(advancedFilters).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        // Special handling for readiness score based on operator
        if (key === "readiness_operator") {
          // Skip the operator as it's handled with the readiness fields
          return;
        } else if (
          key === "readiness" &&
          value &&
          advancedFilters.readiness_operator === "equal"
        ) {
          filter.readiness = parseInt(value as string, 10) * 100;
        } else if (
          key === "readiness__gt" &&
          value &&
          (advancedFilters.readiness_operator === "greater_than" ||
            advancedFilters.readiness_operator === "range")
        ) {
          filter.readiness__gt = parseInt(value as string, 10) * 100;
        } else if (
          key === "readiness__lt" &&
          value &&
          (advancedFilters.readiness_operator === "less_than" ||
            advancedFilters.readiness_operator === "range")
        ) {
          filter.readiness__lt = parseInt(value as string, 10) * 100;
        } else if (
          ["workflow_status_id", "team_id", "claimed_by_id"].includes(key)
        ) {
          // Handle array fields
          if (Array.isArray(value) && value.length > 0) {
            filter[key] = value.map((v) => parseInt(v, 10));
          } else if (typeof value === "string" && value !== "") {
            filter[key] = [parseInt(value, 10)];
          }
        } else if (
          !["readiness", "readiness__gt", "readiness__lt"].includes(key)
        ) {
          // Include other non-readiness fields
          filter[key] = value;
        }
      }
    });

    return filter;
  };

  /**
   * Parses the order_by string from server format
   * @param orderBy - The order_by string (e.g. "received_time:asc")
   * @returns Object with sortField and sortDirection
   */
  const parseOrderBy = (orderBy: string = "received_time:asc") => {
    const [field, direction] = orderBy.split(":");
    return {
      field: field as "inception_date" | "received_time",
      direction: direction as "asc" | "desc",
    };
  };

  /**
   * Loads a filter for editing by setting form state from a filter object
   * @param filter - The filter object from the server
   */
  const loadFilterForEditing = (filter: any) => {
    setSelectedFilter(filter);
    setName(filter.name);
    setDescription(filter.description || "");
    setIcon(filter.icon || "Filter");

    // Convert filter object to form state
    const newAdvancedFilters = convertServerFilterToFormState(filter.filter);
    setAdvancedFilters(newAdvancedFilters);

    // Parse order_by if available
    if (filter.order_by) {
      const { field, direction } = parseOrderBy(filter.order_by);
      setSortField(field);
      setSortDirection(direction);
    }

    setIsEditModalOpen(true);
  };

  /**
   * Prepares filter data for sending to the server
   * @returns The prepared filter data object
   */
  const prepareFilterData = () => {
    // Convert form state to server filter format
    const filter = convertFormStateToServerFilter(advancedFilters);

    return {
      name,
      description,
      view_type: "USER_CUSTOM",
      icon,
      filter,
      order_by: `${sortField}:${sortDirection}`,
    };
  };

  const handleCreateView = async () => {
    try {
      const data = prepareFilterData();

      // Create the filter with sort configuration
      await createNavFilter(data).unwrap();

      // Close modal and reset form
      setIsCreateModalOpen(false);
      resetForm();

      // Show success message
      toast.success("Custom view created successfully");
    } catch (error) {
      console.error("Failed to create custom view:", error);
      toast.error("Failed to create custom view");
    }
  };

  const handleUpdateView = async () => {
    if (!selectedFilter) return;

    try {
      const data = prepareFilterData();

      // Update the filter
      await updateNavFilter({
        slug: selectedFilter.slug,
        data,
      }).unwrap();

      // Close modal and reset form
      setIsEditModalOpen(false);
      resetForm();

      // Show success message
      toast.success("Custom view updated successfully");
    } catch (error) {
      console.error("Failed to update custom view:", error);
      toast.error("Failed to update custom view");
    }
  };

  const handleDeleteView = async () => {
    if (!selectedFilter) return;

    try {
      await deleteNavFilter(selectedFilter.slug).unwrap();

      // Close modal and reset selection
      setIsDeleteModalOpen(false);
      setSelectedFilter(null);

      // Show success message
      toast.success("Custom view deleted successfully");
    } catch (error) {
      console.error("Failed to delete custom view:", error);
      toast.error("Failed to delete custom view");
    }
  };

  const handleViewClick = (view: any) => {
    handleSlugChangeForSubmissionsQuery(
      `custom-views/custom-view-${view.slug}`,
    );
  };

  return (
    <div className="CustomViewsPage">
      <div className="CustomViewsPage__Header">
        <h1>Custom Views</h1>
        {customViews.length > 0 && (
          <PingButton
            onClick={() => setIsCreateModalOpen(true)}
            variant="primary"
            className="CustomViewsPage__CreateButton"
          >
            <PingLucideIcon iconName="Plus" />
            <span>Create New Filter</span>
          </PingButton>
        )}
      </div>

      <div className="CustomViewsPage__Content">
        {customViews.length === 0 ? (
          <div className="CustomViewsPage__EmptyState">
            <PingLucideIcon iconName="Filter" size={48} />
            <p>
              Create a custom view to filter submissions based on your criteria.
            </p>
            <PingButton
              onClick={() => setIsCreateModalOpen(true)}
              variant="primary"
              className="CustomViewsPage__CreateButton"
            >
              <PingLucideIcon iconName="Plus" />
              <span>Create New Filter</span>
            </PingButton>
          </div>
        ) : (
          <div className="CustomViewsPage__Grid">
            {customViews.map((view) => (
              <div key={view.slug} className="CustomViewsPage__Card">
                <div className="CustomViewsPage__Card__Header">
                  <PingLucideIcon iconName={view.icon || "Filter"} />
                  <span>{view.name}</span>
                </div>
                <p>{view.description}</p>

                {Object.keys(view?.filter || {}).length > 0 && (
                  <div className="CustomViewsPage__Card__Filters">
                    <span>Filters:</span>
                    <ul>
                      {Object.entries(view?.filter || {}).map(
                        ([key, value]) => {
                          // Format the display of different filter types
                          let displayValue = "";

                          if (key === "readiness") {
                            const readinessValue =
                              typeof value === "object" ? value?.value : value;

                            displayValue = `Readiness Score: = ${readinessValue / 100} %`;
                          } else if (key === "readiness__gt") {
                            displayValue = `Readiness Score: > ${value / 100} %`;
                          } else if (key === "readiness__lt") {
                            displayValue = `Readiness Score: < ${value / 100} %`;
                          } else if (key === "claimed_by_id") {
                            const users = settings?.users || [];
                            const user = users.filter((u) =>
                              (value as number[])?.includes(u.id),
                            );
                            displayValue = `User: ${user?.map((u) => u.username).join(", ") || value}`;
                          } else if (key === "workflow_status_id") {
                            if (Array.isArray(value)) {
                              const statusNames = value
                                .map((id) => {
                                  const status = workflowStatuses?.find(
                                    (s) => s.id.toString() === id.toString(),
                                  );
                                  return `${status?.name} (id: ${status?.id})`;
                                })
                                .join(", ");
                              displayValue = `Status: ${statusNames}`;
                            } else {
                              const status = workflowStatuses?.find(
                                (s) => s.id.toString() === value.toString(),
                              );
                              displayValue = `Status: ${status?.name || value}`;
                            }
                          } else if (key === "team_id") {
                            if (Array.isArray(value)) {
                              const teamNames = value
                                .map((id) => {
                                  const team = teams?.find(
                                    (t) =>
                                      t.team_id.toString() === id.toString(),
                                  );
                                  return team?.team_name || id;
                                })
                                .join(", ");
                              displayValue = `Team: ${teamNames}`;
                            } else {
                              const team = teams?.find(
                                (t) =>
                                  t.team_id.toString() === value.toString(),
                              );
                              displayValue = `Team: ${team?.team_name || value}`;
                            }
                          } else {
                            displayValue = `${key}: ${JSON.stringify(value)}`;
                          }

                          return <li key={key}>{displayValue}</li>;
                        },
                      )}
                    </ul>
                  </div>
                )}

                {view.order_by && (
                  <div className="CustomViewsPage__Card__Sort">
                    <span>Sort:</span>
                    <p>
                      {(() => {
                        const [field, direction] = view.order_by.split(":");
                        const fieldLabel =
                          field === "received_time"
                            ? "Received Time"
                            : "Inception Date";
                        const directionLabel =
                          direction === "asc" ? "Oldest First" : "Newest First";
                        return `${fieldLabel} (${directionLabel})`;
                      })()}
                    </p>
                  </div>
                )}

                <div className="CustomViewsPage__Card__Footer">
                  <div className="CustomViewsPage__Card__Actions">
                    <PingButton
                      variant="normal"
                      onClick={() => handleViewClick(view)}
                    >
                      View Submissions
                    </PingButton>
                    <PingButton
                      variant="normal"
                      onClick={() => {
                        setSelectedFilter(view);
                        loadFilterForEditing(view);
                        setIsEditModalOpen(true);
                      }}
                    >
                      Edit
                    </PingButton>
                    <PingButton
                      variant="normal"
                      onClick={() => {
                        setSelectedFilter(view);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </PingButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Create Modal */}
      <PingVisionModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          resetForm();
        }}
        title="Create Custom View"
        renderContent={() => (
          <CustomViewForm
            formValues={{
              name,
              description,
              icon,
              advancedFilters,
              sortField,
              sortDirection,
            }}
            handlers={{
              setName,
              setDescription,
              setIcon,
              setAdvancedFilters,
              setSortField,
              setSortDirection,
            }}
            data={{
              teams,
              workflowStatuses,
              users: settings?.users,
            }}
          />
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsCreateModalOpen(false);
                resetForm();
              }}
            >
              Cancel
            </PingButton>
            <PingButton
              onClick={handleCreateView}
              disabled={!name || !description || isCreating}
            >
              Create
            </PingButton>
          </>
        )}
      />

      {/* Edit Modal */}
      <PingVisionModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          resetForm();
        }}
        title="Edit Custom View"
        renderContent={() => (
          <CustomViewForm
            formValues={{
              name,
              description,
              icon,
              advancedFilters,
              sortField,
              sortDirection,
            }}
            handlers={{
              setName,
              setDescription,
              setIcon,
              setAdvancedFilters,
              setSortField,
              setSortDirection,
            }}
            data={{
              teams,
              workflowStatuses,
              users: settings?.users,
            }}
          />
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsEditModalOpen(false);
                resetForm();
              }}
            >
              Cancel
            </PingButton>
            <PingButton
              onClick={handleUpdateView}
              disabled={!name || !description || isUpdating}
            >
              Update
            </PingButton>
          </>
        )}
      />

      {/* Delete Modal */}
      <PingVisionModal
        className={{ base: "CustomViewsPage__DeleteModal" }}
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedFilter(null);
        }}
        title="Delete Custom View"
        renderContent={() => (
          <p>
            Are you sure you want to delete the custom view "
            {selectedFilter?.name}"? This action cannot be undone.
          </p>
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsDeleteModalOpen(false);
                setSelectedFilter(null);
              }}
            >
              Cancel
            </PingButton>
            <PingButton onClick={handleDeleteView} disabled={isDeleting}>
              Delete
            </PingButton>
          </>
        )}
      />
    </div>
  );
};

export default CustomViewManagementPage;
