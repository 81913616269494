import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import {
  createWebSocketUrl,
  createWebSocketConnection,
  registerWebSocketListener,
  closeWebSocketConnection,
} from "./websocketService";

// Module-level variable to track the last query parameters
// This is defined outside the API to persist between calls.
// This is necessary because redux state updates don't happen fast
// enough to stop the cursor from being passed in when list query parameters change
// We'll still keep currentCursorId in the inbox redux state, up to date.
let lastSubmissionListQueryParams: Record<string, any> | null = null;

// Note: WebSocket event listeners are now initialized automatically when the WebSocket service is imported

import { baseQueryWithZodValidation } from "@repo/ping-react-js";

import { getApiBaseUrl } from "../utils";
import {
  handleMyIssuesNavItemCountUpdates,
  handleAddWorkflowStatusUpdate,
  handleInboxNavItemCountUpdates,
  handleListInsertOnSubmissionCreate,
  handleListInsertOnSubmissionUpdate,
  handleAddClaimantUpdate,
} from "../utils/client-side-list-updates";
import { updateSelectedSovItem } from "./selectedSovSlice";
import { updateNavAndQueryParams } from "../reducers/settings";
import {
  PING_VISION_DEFAULT_GRID_PAGE_SIZE,
  PING_VISION_DEFAULT_FIELDS,
} from "constants/ApiConstants";
import type { RootState } from "services/store";
import {
  NavigationResponse,
  ActivityItemType,
  EmailCorrespondenceResponse,
  emailCorrespondenceResponseSchema,
  GenericMutationResponse,
  genericMutationResponseSchema,
  BulkUpdateSubmissionResponse,
  BulkUpdateSubmissionRequest,
  bulkUpdateSubmissionResponseSchema,
  partialSubmissionDocumentResponseSchema,
  PartialSubmissionDocumentResponse,
  PartialSubmissionDocument,
} from "ts-types/ApiTypes";
import {
  SovDataType,
  SovDataTypePaginatedResponse,
  UserType,
} from "ts-types/DataTypes";

import { GetSubmissionsQueryParams } from "ts-types/QueryTypes";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithZodValidation(
    fetchBaseQuery({
      baseUrl: getApiBaseUrl(),
      prepareHeaders: (headers, { getState, endpoint }) => {
        if (endpoint !== "uploadDocument") {
          headers.set("Content-Type", "application/json");
        }
        const state = getState() as RootState;
        const accessToken = state.auth.accessToken;
        if (accessToken && !headers.has("Authorization")) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
      },
    }),
  ),

  tagTypes: ["PVNotes", "TeamMembers", "PVSubmissionsList", "NavFilters"],
  keepUnusedDataFor: 0, // Immediately remove cache entries when they become unused

  endpoints: (build) => ({
    /**
     * Get the current environment. The environment contains information about
     * the logged-in user as well as some general information.
     */
    getEnvironment: build.query<any, void>({
      query: () => ({
        url: `api/v1/environment`,
        method: "GET",
      }),
    }),

    /**
     * Get audit log for a single submission.
     */
    getSubmissionHistory: build.query<
      ActivityItemType[],
      { id: string; realTimeSubscriptions: Record<string, number[]> }
    >({
      query: ({ id }) => ({
        url: `api/v1/submission/${id}/history`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const subscriptions = arg.realTimeSubscriptions?.teams || [];

        if (!subscriptions.length) {
          return;
        }

        if (!accessToken) {
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(","),
          );
        }

        // Create a unique connection ID for this events WebSocket
        const eventsConnectionId = "events";

        // Use the createWebSocketUrl helper to generate the WebSocket URL
        const wsUrl = createWebSocketUrl("/ws/events/");

        // Create and connect to the WebSocket
        createWebSocketConnection(eventsConnectionId, wsUrl, searchParams);

        // Register a listener for submission history updates
        const unregisubmissionsHistoryListener = registerWebSocketListener(
          eventsConnectionId,
          "submission.history.list",
          (message: any) => {
            if (arg.id && arg.id !== message.data.id) return;
            const { data } = message.data;

            updateCachedData(() => {
              return data;
            });
          },
        );

        try {
          await cacheDataLoaded;
        } catch {
          unregisubmissionsHistoryListener();
        }

        // Clean up the listener when the cache entry is removed
        await cacheEntryRemoved;

        // Unregister the listener
        unregisubmissionsHistoryListener();

        // The closeWebSocketConnection function now checks for active listeners
        // before actually closing the connection, so it's safe to call it here
        closeWebSocketConnection(eventsConnectionId);
      },
    }),

    /**
     * Get a list of submissions. This endpoint supports infinite scroll.
     * Calling this endpoint also subscribes to real-time updates for the
     * specified subscriptions.
     *
     * NOTE: be careful while invalidating the tags for this endpoint!
     *
     * Invalidating the cache for this endpoint will clear the entire infinite
     * scroll cache, and the next request will start loading from whatever the
     * last cursor ID happened to be. This is okay if you're navigating to a new
     * page, but it will result in unpredictable behavior if you do it while on
     * an existing page with many submissions already loaded.
     */
    getSubmissions: build.query<
      SovDataTypePaginatedResponse,
      GetSubmissionsQueryParams
    >({
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        },
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const subscriptions = arg.realTimeSubscriptions?.teams;
        const submission_statuses = state.settings?.settings?.submission_status;

        if (!accessToken) {
          return;
        }

        if (!subscriptions.length) {
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(","),
          );
        }

        // Create a unique connection ID for this events WebSocket
        const eventsConnectionId = "events";

        // Use the createWebSocketUrl helper to generate the WebSocket URL
        const wsUrl = createWebSocketUrl("/ws/events/");

        // Create and connect to the WebSocket
        createWebSocketConnection(eventsConnectionId, wsUrl, searchParams);

        // Register listeners for submission updates and creation
        const unregisterUpdateListener = registerWebSocketListener(
          eventsConnectionId,
          "submission.update",
          (message: any) => {
            const state = getState() as RootState;
            let { changed_fields } = message.data;
            const { id, changed_documents, changed_jobs } = message.data;

            handleMyIssuesNavItemCountUpdates(message, state, dispatch);
            handleInboxNavItemCountUpdates(message, dispatch);
            handleAddWorkflowStatusUpdate(message, dispatch);
            handleAddClaimantUpdate(message, dispatch);

            // DO NOT CHANGE: the backend does not send the client
            // workflow_status__name, so we need to query it and append it
            // to the Redux state manually. This avoids a query inside the
            // re-saved hook.
            if (changed_fields?.workflow_status_id) {
              const workflow_status__name = submission_statuses?.find(
                (s) => s.id === changed_fields.workflow_status_id,
              )?.name;
              changed_fields = { ...changed_fields, workflow_status__name };
            }

            // Update the selected item if it matches the updated item's ID
            if (state.selectedSov.selectedSovItem?.id === id) {
              dispatch(
                updateSelectedSovItem({
                  id,
                  changed_fields,
                  changed_documents,
                  changed_jobs,
                }),
              );
            }

            updateCachedData((draft) => {
              const itemIndex = draft.results.findIndex(
                (item) => item.id === id,
              );

              // the item is in the list and we need to update it. The submission list component
              // will ultimately filter it out if needed based on redux slices like workflowUpdatesSlice and claimantUpdatesSlice
              // and functions from the submissions list global context.

              if (itemIndex !== -1) {
                draft.results[itemIndex] = {
                  ...draft.results[itemIndex],
                  ...changed_fields,
                };
                if (changed_documents?.length) {
                  changed_documents.forEach((newDocument) => {
                    const docIndex = draft.results[
                      itemIndex
                    ].documents.findIndex((doc) => doc.id === newDocument.id);
                    if (docIndex !== -1) {
                      draft.results[itemIndex].documents[docIndex] =
                        newDocument;
                    } else {
                      draft.results[itemIndex].documents.push(newDocument);
                    }
                  });
                }
                if (changed_jobs?.length) {
                  changed_jobs.forEach((newJob) => {
                    const jobIndex = draft.results[itemIndex].jobs.findIndex(
                      (doc) => doc.job_id === newJob.job_id,
                    );
                    if (jobIndex !== -1) {
                      draft.results[itemIndex].jobs[jobIndex] = newJob;
                    } else {
                      draft.results[itemIndex].jobs.push(newJob);
                    }
                  });
                }
              } else {
                // if the item isn't already in the list, we still want to see if it should be included
                // with the current set of filters

                handleListInsertOnSubmissionUpdate(
                  message,
                  draft,
                  arg,
                  state,
                  dispatch,
                );
              }
            });
          },
        );

        const unregisterCreateListener = registerWebSocketListener(
          eventsConnectionId,
          "submission.create",
          (message: any) => {
            const state = getState() as RootState;
            handleListInsertOnSubmissionCreate(
              message,
              updateCachedData,
              arg,
              state,
              dispatch,
            );
            handleInboxNavItemCountUpdates(message, dispatch);
          },
        );

        try {
          await cacheDataLoaded;
        } catch {
          unregisterUpdateListener();
          unregisterCreateListener();
        }

        // Clean up the listeners when the cache entry is removed
        await cacheEntryRemoved;

        // Unregister the listeners
        unregisterUpdateListener();
        unregisterCreateListener();

        // The closeWebSocketConnection function now checks for active listeners
        // before actually closing the connection, so it's safe to call it here
        closeWebSocketConnection(eventsConnectionId);
      },

      query: ({
        fields,
        search,
        advancedSearchFields,
        cursorId,
        realTimeSubscriptions,
      }) => {
        // Create a copy of the current query parameters (excluding cursor)
        const currentQueryParams = {
          fields,
          search,
          advancedSearchFields,
          realTimeSubscriptions,
        };

        // Check if parameters have changed (excluding cursor)
        let effectiveCursorId = cursorId;
        if (lastSubmissionListQueryParams && cursorId) {
          const paramsChanged = !isEqual(
            currentQueryParams,
            lastSubmissionListQueryParams,
          );

          if (paramsChanged) {
            effectiveCursorId = null;
          }
        }

        // Store the current query parameters for the next call
        lastSubmissionListQueryParams = currentQueryParams;

        const searchParams = new URLSearchParams();

        if (effectiveCursorId) {
          searchParams.append("cursor_id", effectiveCursorId);
        }

        searchParams.append(
          "page_size",
          PING_VISION_DEFAULT_GRID_PAGE_SIZE.toString(),
        );

        searchParams.append("fields", fields?.join(",") || "");

        if (search) {
          searchParams.append("search", search);
        }

        if (advancedSearchFields) {
          Object.entries(advancedSearchFields).forEach(([key, value]) => {
            searchParams.append(key, value);
          });
        }

        return {
          url: `api/v1/submission?${searchParams.toString()}`,
          method: "GET",
        };
      },

      providesTags: () => [{ type: "PVSubmissionsList", id: "LIST" }],

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // We create a custom cache key for this query because we're using
        // infinite scroll. By default, RTK Query replaces the results of the
        // query in the cache with new results every time any of the query args
        // change. In our case, we want to ignore the cursorId when determining
        // the cache key, so that we can keep appending new results to the cache
        // instead of replacing them.
        const queryArgsWithoutCursorId = omit(queryArgs, "cursorId");
        return {
          endpointName,
          ...queryArgsWithoutCursorId,
        };
      },

      merge: (existing, incoming, { arg }) => {
        // Merge incoming cache data with existing cache data. Because we use
        // infinite scroll for this endpoint, we nee to make sure we append
        // incoming results to existing results instead of throwing them away.

        // If we don't have any data, or if the cursor ID is null (indicating an
        // initial request), return the incoming data.
        if (!existing || !arg.cursorId) {
          return incoming;
        }

        // Merge existing and incoming results
        const newResults = [...existing.results, ...incoming.results];

        return {
          ...incoming,
          results: newResults,
        };
      },
    }),

    /**
     * Get a list of submissions. This endpoint does not support infinite
     * scroll directly, but is used for that currently in the duplicate modal which manages
     * the concerns of infinite scroll in addition to what this endpoint does.
     *
     * This endpoint also does not create any WebSocket subscriptions.
     */
    getNotRealTimeSubmissions: build.query<
      SovDataTypePaginatedResponse,
      { cursor_id?: string; search?: string; limit?: number; team_id?: string }
    >({
      query: ({ cursor_id, search, limit = 100, team_id }) => {
        const params: Record<string, string | boolean | number> = {
          page_size: limit,
          fields: PING_VISION_DEFAULT_FIELDS.join(","),
          ...(team_id && { team_id }),
        };

        if (search) {
          params.search = search;
        }

        if (cursor_id) {
          params.cursor_id = cursor_id;
        }

        return {
          url: `api/v1/submission`,
          method: "GET",
          params,
        };
      },
      keepUnusedDataFor: 0, // Don't keep the data in cache
      forceRefetch: () => {
        return true; // Always refetch when the query is called
      },
    }),

    /**
     * Get a single submission by ID.
     */
    getSingleSubmission: build.query<
      SovDataTypePaginatedResponse,
      {
        id: string;
        teamId?: number;
        fields?: string[];
        advancedSearchFields?: Record<string, string> | null;
      }
    >({
      query: ({
        id,
        teamId,
        fields = PING_VISION_DEFAULT_FIELDS,
        advancedSearchFields,
      }) => {
        const params: Record<string, string | number> = {
          fields: fields.join(","),
          id,
          ...(teamId && { team_id: teamId }),
        };

        if (advancedSearchFields) {
          Object.entries(advancedSearchFields).forEach(([key, value]) => {
            params[key] = value;
          });
        }

        return {
          url: `api/v1/submission`,
          method: "GET",
          params,
        };
      },
    }),

    /**
     * Upload a document for a submission.
     */
    uploadDocument: build.mutation<
      GenericMutationResponse,
      { accessToken: string; id: string; file: FormData }
    >({
      query: ({ accessToken, id, file }) => ({
        url: `api/v1/submission/${id}/document`,
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        extraOptions: {
          dataSchema: genericMutationResponseSchema,
        },
      }),
    }),

    /**
     * Bulk update the status of multiple submissions.
     */
    bulkUpdateSubmission: build.mutation<
      BulkUpdateSubmissionResponse,
      BulkUpdateSubmissionRequest
    >({
      query: ({ ids, changes }) => ({
        url: `api/v1/submission/bulkupdate`,
        method: "POST",
        body: { ids, changes },
        extraOptions: {
          dataSchema: bulkUpdateSubmissionResponseSchema,
        },
      }),
    }),

    /**
     * Change the triage status of a submission.
     */
    changeSubmissionTriageStatus: build.mutation<
      { status: string },
      { id: string; status: string }
    >({
      query: ({ id, status }) => ({
        url: `api/v1/submission/${id}/change_status/`,
        method: "PATCH",
        body: { workflow_status_id: status },
      }),
    }),

    /**
     * Mark a submission as a duplicate of another submission.
     *
     * TODO: marking a submission as a duplicate should result in a WebSocket
     * message that updates the submission in-place.
     */
    markSubmissionAsDuplicate: build.mutation<
      { status: string },
      { id: string; duplicate_of_submission_id: string }
    >({
      query: ({ id, duplicate_of_submission_id }) => ({
        url: `api/v1/submission/${id}/mark_as_duplicate/`,
        method: "PATCH",
        body: { duplicate_of_submission_id: duplicate_of_submission_id },
      }),
    }),

    /**
     * Update an attachment for a submission. Used for renaming, changing
     * document type, and archiving.
     */
    updateSubmissionDocument: build.mutation<
      PartialSubmissionDocumentResponse,
      {
        id: string;
        filename: string;
        data: PartialSubmissionDocument;
      }
    >({
      query: ({ id, filename, data }) => ({
        url: `api/v1/submission/${id}/document/${filename}`,
        method: "PATCH",
        body: data,
      }),
      extraOptions: {
        dataSchema: partialSubmissionDocumentResponseSchema,
      },
    }),

    /**
     * Manually ask SOVFixer to parse an SOV file attached to a submission.
     */
    parseSovFile: build.mutation<
      { message: string; sovid: string },
      { id: string; filename: string }
    >({
      query: ({ id, filename }) => ({
        url: `api/v1/submission/${id}/document/${filename}/sovfixer-parse`,
        method: "POST",
      }),
    }),

    /**
     * Manually ask SOVFixer to parse all SOV files attached to a submission.
     */
    processFiles: build.mutation<
      { message: string; sovid: string },
      { id: string; filenames: string[] }
    >({
      query: ({ id, filenames }) => ({
        url: `api/v1/submission/${id}/sovfixer-parse`,
        method: "POST",
        body: { filenames },
      }),
    }),

    /**
     * Update a submission.
     */
    updateSubmission: build.mutation<
      Partial<SovDataType> & Record<string, unknown>,
      { id: string; data: Partial<SovDataType> }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/submission/${id}/`,
        method: "PATCH",
        body: data,
      }),
    }),

    /**
     * Get the current user's teams.
     */
    getUserTeams: build.query<
      { id: number; name: string; membership_type: string; team_id: number }[],
      void
    >({
      query: () => ({
        url: `api/v1/user/teams/`,
        method: "GET",
      }),
    }),

    /**
     * Get the navigation items for the current user. Used to populate the nav
     * sidebar's views, and also to provide details for each individual custom view.
     * This endpoint also subscribes to real-time updates for custom views.
     */
    getNav: build.query<
      NavigationResponse,
      { realTimeSubscriptions?: Record<string, number[]> }
    >({
      query: () => ({
        url: `api/v1/nav`,
        method: "GET",
      }),
      providesTags: ["NavFilters"],
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch,
        },
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const subscriptions = arg.realTimeSubscriptions?.teams || [];

        if (!subscriptions.length) {
          return;
        }

        if (!accessToken) {
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);

        // Create a unique connection ID for this navigation WebSocket
        const navConnectionId = "navigation";

        // Use the createWebSocketUrl helper to generate the WebSocket URL
        const wsUrl = createWebSocketUrl("/ws/user/");

        // Create and connect to the WebSocket
        createWebSocketConnection(navConnectionId, wsUrl, searchParams);

        try {
          await cacheDataLoaded;

          // Register a listener for nav.view.update messages
          const unregisterNavListener = registerWebSocketListener(
            navConnectionId,
            "nav.view.update",
            (message: any) => {
              if (
                !message.data ||
                !message.data.nav_item ||
                !message.data.action
              ) {
                return;
              }

              const { nav_item, action } = message.data;

              updateCachedData((draft) => {
                const state = getState();

                // Make sure draft.views exists and is an array
                if (!draft.views || !Array.isArray(draft.views)) {
                  return;
                }

                if (
                  action === "create" ||
                  (action === "update" &&
                    !draft.views.find(
                      (view: any) => view.slug === nav_item.slug,
                    ))
                ) {
                  // in reality, it is really the update event that is sending
                  // a brand new filter to the frontend
                  draft.views.push(nav_item);
                  const updatedNav = JSON.parse(
                    JSON.stringify(state.settings.nav),
                  );
                  updatedNav.views.push(nav_item);
                  dispatch(
                    updateNavAndQueryParams({
                      nav: updatedNav,
                    }),
                  );
                } else if (action === "update") {
                  // Update an existing NavView item
                  const index = draft.views.findIndex(
                    (view: any) => view.slug === nav_item.slug,
                  );
                  if (index !== -1) {
                    draft.views[index] = nav_item;
                    const updatedNav = JSON.parse(
                      JSON.stringify(state.settings.nav),
                    );
                    updatedNav.views = updatedNav.views.map((view: any) =>
                      view.originalCustomViewSlug === nav_item.slug
                        ? nav_item
                        : view,
                    );
                    dispatch(
                      updateNavAndQueryParams({
                        nav: updatedNav,
                      }),
                    );
                  }
                } else if (action === "delete") {
                  // Remove a NavView item
                  draft.views = draft.views.filter(
                    (view: any) => view.slug !== nav_item.slug,
                  );
                  const updatedNav = JSON.parse(
                    JSON.stringify(state.settings.nav),
                  );
                  updatedNav.views = updatedNav.views.filter(
                    (view: any) =>
                      view.originalCustomViewSlug !== nav_item.slug,
                  );
                  dispatch(
                    updateNavAndQueryParams({
                      nav: updatedNav,
                    }),
                  );
                }
              });
            },
          );

          // When this cache entry is removed, clean up the listener and close the connection
          await cacheEntryRemoved;

          // Unregister the listener
          unregisterNavListener();

          // The closeWebSocketConnection function now checks for active listeners
          // before actually closing the connection, so it's safe to call it here
          closeWebSocketConnection(navConnectionId);
        } catch {
          // Ensure WebSocket is closed on error
          closeWebSocketConnection(navConnectionId);
        }
      },
    }),

    /**
     * Create a new custom navigation filter
     */
    createNavFilter: build.mutation<GenericMutationResponse, any>({
      query: (data) => ({
        url: `api/v1/nav`,
        method: "POST",
        body: data,
      }),
    }),

    /**
     * Update an existing custom navigation filter
     * Uses PATCH to only update the provided fields
     */
    updateNavFilter: build.mutation<
      GenericMutationResponse,
      { slug: string; data: any }
    >({
      query: ({ slug, data }) => ({
        url: `api/v1/nav/${slug}`,
        method: "PATCH",
        body: data,
      }),
    }),

    /**
     * Delete a custom navigation filter
     */
    deleteNavFilter: build.mutation<GenericMutationResponse, string>({
      query: (slug) => ({
        url: `api/v1/nav/${slug}/delete`,
        method: "DELETE",
      }),
    }),

    /**
     * Get the current user's settings. This includes the user's teams, their
     * profile, and the submission statuses available to them.
     */
    getSettings: build.query<any, any>({
      query: () => ({
        url: `api/v1/settings`,
        method: "GET",
      }),
    }),

    /**
     * Get the email correspondence for a submission. This returns an email
     * thread that we can render in the documents panel.
     */
    getEmailCorrespondence: build.query<
      EmailCorrespondenceResponse,
      { sovid: string }
    >({
      query: ({ sovid }) => ({
        url: `api/v1/submission/${sovid}/correspondence`,
      }),
      extraOptions: {
        dataSchema: emailCorrespondenceResponseSchema,
      },
    }),

    /**
     * Create a note for a submission.
     */
    createNote: build.mutation({
      query: ({ id, text }) => ({
        url: `api/v1/submission/note/`,
        method: "POST",
        body: { submission: id, text: text },
      }),
      invalidatesTags: ["PVNotes"],
    }),

    /**
     * Get the members of a team.
     */
    getTeamMembers: build.query<UserType[], number>({
      query: (teamId) => ({
        url: `api/v1/memberships/?team_id=${teamId}`,
        method: "GET",
      }),
      providesTags: ["TeamMembers"],
    }),

    /**
     * Add a member to a team.
     */
    createTeamMember: build.mutation<
      UserType,
      {
        teamId: number;
        first_name: string;
        last_name: string;
        user_email: string;
        membership_type: string;
      }
    >({
      query: ({ teamId, ...body }) => ({
        url: `api/v1/memberships/`,
        method: "POST",
        body: {
          team_id: teamId,
          ...body,
        },
      }),
      invalidatesTags: ["TeamMembers"],
    }),

    /**
     * Delete a member from a team.
     */
    deleteTeamMember: build.mutation<void, { membershipId: number }>({
      query: ({ membershipId }) => ({
        url: `api/v1/memberships/${membershipId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TeamMembers"],
    }),

    /**
     * Update a member's role in a team.
     */
    updateTeamMember: build.mutation<
      any,
      {
        membershipId: number;
        membership_type: string;
        user_email: string;
      }
    >({
      query: ({ membershipId, membership_type, user_email }) => {
        return {
          url: `api/v1/memberships/${membershipId}/`,
          method: "PATCH",
          body: {
            membership_type,
            user_email,
          },
        };
      },
      invalidatesTags: ["TeamMembers"],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useBulkUpdateSubmissionMutation,
  useGetNavQuery,
  useUploadDocumentMutation,
  useGetSubmissionHistoryQuery,
  useGetSubmissionsQuery,
  useGetNotRealTimeSubmissionsQuery,
  useChangeSubmissionTriageStatusMutation,
  useMarkSubmissionAsDuplicateMutation,
  useUpdateSubmissionMutation,
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useUpdateSubmissionDocumentMutation,
  useParseSovFileMutation,
  useProcessFilesMutation,
  useGetEmailCorrespondenceQuery,
  useGetTeamMembersQuery,
  useCreateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useGetUserTeamsQuery,
  useUpdateTeamMemberMutation,
  useGetSingleSubmissionQuery,
  useCreateNavFilterMutation,
  useUpdateNavFilterMutation,
  useDeleteNavFilterMutation,
} = api;
