import * as React from "react";
import ReactDOMServer from "react-dom/server";
import { NON_GEOCODING_PIN_SIZE } from "../Constants";

type PinType = {
  color: string;
  size: number;
  onDoubleClick?: (e: any) => void;
  opacity?: number;
  strokeWidth?: number;
};

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none",
};

const ICONS = {
  SOURCE_INFO:
    "M9,6a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6h0a6,6,0,0,1-6,6h0A6,6,0,0,1,9,6Zm6,6V21",
  SUGGESTED:
    "M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703 C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z",
  ACCEPTED:
    "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
};

const SVGPathIcon = ({
  pathData,
  color = "#000000",
  strokeColor = "#000000",
  size = 24,
  opacity = 1,
  strokeWidth = 1,
  viewBox = "0 0 24 24",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      fill={color}
      fillOpacity={opacity}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    >
      <path d={pathData} />
    </svg>
  );
};

export const GET_PATH_ICON = (
  iconKey,
  color = "#000000",
  size = 24,
  opacity = 1,
  strokeWidth = 1,
  viewBox = null,
) => {
  const pathData = ICONS[iconKey];

  if (!pathData) {
    console.error(`Icon with key "${iconKey}" not found in ICONS object`);
    return null;
  }

  let iconViewBox = viewBox;
  if (!iconViewBox) {
    switch (iconKey) {
      case "SOURCE_INFO":
        iconViewBox = "0 -2 24 24";
        break;
      case "SUGGESTED":
        iconViewBox = "0 -1 15 16";
        break;
      case "ACCEPTED":
        iconViewBox = "-2 0 24 24";
        break;
      default:
        iconViewBox = "0 0 24 24";
    }
  }

  const svgString = ReactDOMServer.renderToString(
    <SVGPathIcon
      pathData={pathData}
      color={color}
      size={size}
      opacity={opacity}
      strokeWidth={strokeWidth}
      viewBox={iconViewBox}
    />,
  );

  const encodedSvg = btoa(svgString);
  return `data:image/svg+xml;base64,${encodedSvg}`;
};

export const GET_NEW_GEOCODER_PIN = (color = "#000000") => {
  const svgString = ReactDOMServer.renderToString(
    <svg
      width={32}
      height={32}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.75C19 13.616 15.866 16.75 12 16.75C8.13401 16.75 5 13.616 5 9.75C5 5.88401 8.13401 2.75 12 2.75C15.866 2.75 19 5.88401 19 9.75Z"
        fill={color}
      />
      <path
        d="M12 16.75C15.866 16.75 19 13.616 19 9.75C19 5.88401 15.866 2.75 12 2.75C8.13401 2.75 5 5.88401 5 9.75C5 13.616 8.13401 16.75 12 16.75ZM12 16.75V21.25"
        stroke="#2D2E45"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>,
  );
  const encodedSvg = btoa(svgString);
  return `data:image/svg+xml;base64,${encodedSvg}`;
};

export const GET_CIRCLE_PIN_ICON = (
  color: string,
  size: number = NON_GEOCODING_PIN_SIZE,
  opacity: number = 1,
  strokeWidth: number = 1,
) => {
  const svgString = ReactDOMServer.renderToString(
    <GoogleCirclePin
      color={color}
      size={size}
      opacity={opacity}
      strokeWidth={strokeWidth}
    />,
  );
  const encodedSvg = btoa(svgString);

  return `data:image/svg+xml;base64,${encodedSvg}`;
};

const GoogleCirclePin = ({
  size,
  onDoubleClick,
  color = "black",
  opacity = 1,
  strokeWidth = 1,
}: PinType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      onDoubleClick={onDoubleClick}
      style={{ ...pinStyle, fill: color }}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - strokeWidth / 2}
        fill={color}
        stroke="black"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
    </svg>
  );
};

export default React.memo(GoogleCirclePin);
