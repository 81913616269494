import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "services/pvSlice.ts";

/**
 * Represents a single update to a claimant's status.
 * Tracks who claimed a submission and when it happened.
 */
interface ClaimantUpdate {
  /** Unique identifier for the submission that was claimed */
  submissionId: string;
  /** Timestamp when the claim occurred (in milliseconds since epoch) */
  timestamp: number;
  /** ID of the user who initiated the update */
  userId: number;
  /** ID of the user who claimed the submission */
  claimedById: number;
}

/**
 * State structure for the claimant updates slice.
 * Maintains a history of recent claimant changes and related metadata.
 */
interface ClaimantUpdatesState {
  /** List of recent claimant updates, ordered by most recent first */
  recentUpdates: ClaimantUpdate[];
  /** Maximum number of updates to store in memory */
  maxUpdates: number;
  /** Arguments from the last submission query, used to determine when to clear updates */
  lastQueryArgs?: any;
}

/**
 * Initial state for the claimant updates slice.
 */
const initialStateClaimantUpdates: ClaimantUpdatesState = {
  recentUpdates: [],
  maxUpdates: 2000, // Store up to 2000 recent updates before pruning
  lastQueryArgs: null,
};

/**
 * Redux slice for managing claimant updates throughout the application.
 * Handles adding new updates, clearing updates, and synchronizing with submission queries.
 */
export const claimantUpdatesSlice = createSlice({
  name: "claimantUpdates",
  initialState: initialStateClaimantUpdates,
  reducers: {
    /**
     * Adds a new claimant update to the state.
     * If an update for the same submission already exists, it's replaced with the new one.
     * Maintains the list size by pruning old updates when exceeding maxUpdates.
     *
     * @param state - Current state of the slice
     * @param action - Action containing the new claimant update
     */
    addClaimantUpdate: (state, action: PayloadAction<ClaimantUpdate>) => {
      // Check if this submission already has an update in the list
      const existingIndex = state.recentUpdates.findIndex(
        (update) => update.submissionId === action.payload.submissionId,
      );

      // Remove the existing update if found
      if (existingIndex !== -1) {
        state.recentUpdates.splice(existingIndex, 1);
      }

      // Add the new update at the beginning of the array
      state.recentUpdates.unshift(action.payload);

      // Prune the list if it exceeds the maximum size
      if (state.recentUpdates.length > state.maxUpdates) {
        state.recentUpdates = state.recentUpdates.slice(0, state.maxUpdates);
      }
    },
    /**
     * Clears all claimant updates from the state.
     * Used when a complete reset is needed, such as when logging out or changing views.
     *
     * @param state - Current state of the slice
     */
    clearClaimantUpdates: (state) => {
      state.recentUpdates = [];
    },
  },
  /**
   * Extra reducers to handle side effects from other slices and API calls.
   */
  extraReducers: (builder) => {
    /**
     * Handles the successful completion of a getSubmissions query.
     * Clears recent updates when query parameters (other than cursor) have changed.
     * This prevents showing outdated claimant information when filter criteria change.
     */
    builder.addMatcher(
      api.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        // Extract the current query arguments from the action
        const currentArgs = action.meta.arg.originalArgs;

        // Get the previous query arguments stored in state
        const prevArgs = state.lastQueryArgs;

        // Handle first request case
        if (!prevArgs) {
          state.lastQueryArgs = currentArgs;
          return;
        }

        // Create copies of the args without cursor for comparison
        // This allows pagination to occur without clearing updates
        const currentArgsWithoutCursor = {
          ...currentArgs,
          cursorId: undefined,
        };
        const prevArgsWithoutCursor = { ...prevArgs, cursorId: undefined };

        // Compare the stringified objects to detect non-cursor changes
        // (e.g., filter changes, sort changes, etc.)
        const hasNonCursorChanges =
          JSON.stringify(currentArgsWithoutCursor) !==
          JSON.stringify(prevArgsWithoutCursor);

        // Clear updates only when substantive query parameters have changed
        if (hasNonCursorChanges) {
          state.recentUpdates = [];
        }

        // Store current args for future comparisons
        state.lastQueryArgs = currentArgs;
      },
    );
  },
});

/**
 * Export the action creators for use throughout the application.
 * These actions can be dispatched to add or clear claimant updates.
 */
export const { addClaimantUpdate, clearClaimantUpdates } =
  claimantUpdatesSlice.actions;

/**
 * Export the reducer as the default export for use in the Redux store configuration.
 */
export default claimantUpdatesSlice.reducer;
