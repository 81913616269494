import React, { FC, useState, useEffect } from 'react';
import { SovDataType } from 'ts-types/DataTypes';
import { PVTabs, PVTabsList, PVTabsListItem, PVTabsContent, PVTabsContentItem } from './PVTabs';
import {
  PingSelectInput,
  PingTextInput,
  PingTextarea,
} from "@repo/ping-react-js";

import './PVScrubberMode.scss';

// US States for dropdown menus
const US_STATES = [
  { value: "", label: "Select a state" },
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];

type PVScrubberModeProps = {
  selectedItem: SovDataType;
};

type ScrubberData = {
  buildingInfo: {
    bldNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    location: string;
    geocodingType: string;
  };
  hazardInfo: {
    floodZone: string;
    dtcLowRes: string;
    dtcHighRes: string;
    wildFire: string;
    slosh: string;
    crime: string;
    eqRisk: string;
    distanceToHydrant: string;
    distanceToFireStation: string;
    distanceToSink: string;
  };
  copeInfo: {
    construction: string;
    occupancy: string;
    yearBuilt: string;
    roofType: string;
    roofShape: string;
    roofingYear: string;
    stories: string;
    buildings: string;
    basement: string;
    sqFootage: string;
    wiringYear: string;
    plumbingYear: string;
    heatingYear: string;
    sprinklerPercent: string;
    fireAlarm: string;
    lb: {
      construction: string;
      occupancy: string;
      yearBuilt: string;
      roofType: string;
      roofShape: string;
      roofingYear: string;
      stories: string;
      buildings: string;
      basement: string;
      sqFootage: string;
      wiringYear: string;
      plumbingYear: string;
      heatingYear: string;
      sprinklerPercent: string;
      fireAlarm: string;
    };
    original: {
      construction: string;
      occupancy: string;
      yearBuilt: string;
      roofType: string;
      roofShape: string;
      roofingYear: string;
      stories: string;
      buildings: string;
      basement: string;
      sqFootage: string;
      wiringYear: string;
      plumbingYear: string;
      heatingYear: string;
      sprinklerPercent: string;
      fireAlarm: string;
    };
  };
  tivLimits: {
    building: string;
    contents: string;
    other: string;
    businessInterruption: string;
    tiv: string;
  };
  clientBroker: {
    insured: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    country: string;
    fein: string;
    sic: string;
    naic: string;
    glCode: string;
    businessPhone: string;
    website: string;
  }

  retailBroker: {
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    office: string;
    broker: string;
    brokerEmail: string;
    assistant: string;
    assistantEmail: string;

  };
  wholesaleBroker: {
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    office: string;
    broker: string;
    brokerEmail: string;
    assistant: string;
    assistantEmail: string;
  };

  policyDates: {
    inceptionDate: string;
    expiryDate: string;
  }

};

const mockData: ScrubberData = {
  buildingInfo: {
    bldNumber: 'BLD123456',
    address1: '123 Main St',
    address2: 'Suite 100',
    city: 'San Francisco',
    state: 'CA',
    zip: '94105',
    location: '37.7749° N, 122.4194° W',
    geocodingType: 'Address Match'
  },
  hazardInfo: {
    floodZone: 'Zone X',
    dtcLowRes: 'Low',
    dtcHighRes: 'Medium',
    wildFire: 'Moderate',
    slosh: 'Category 3',
    crime: 'Low',
    eqRisk: 'Minimal',
    distanceToHydrant: '500 ft',
    distanceToFireStation: '2 miles',
    distanceToSink: '5 miles'
  },
  copeInfo: {
    construction: 'frame',
    occupancy: 'Office Building',
    yearBuilt: '1985',
    roofType: 'Built-Up',
    roofShape: 'Flat',
    roofingYear: '2012',
    stories: '3',
    buildings: '1',
    basement: '0',
    sqFootage: '25,000',
    wiringYear: '2000',
    plumbingYear: '2000',
    heatingYear: '2003',
    sprinklerPercent: '100%',
    fireAlarm: 'Central Station',
    lb: {
      construction: 'Masonry',
      occupancy: 'Office Building',
      yearBuilt: '1987',
      roofType: 'Built-Up',
      roofShape: 'Flat',
      roofingYear: '2012',
      stories: '3',
      buildings: '1',
      basement: '0',
      sqFootage: '25,000',
      wiringYear: '2000',
      plumbingYear: '2000',
      heatingYear: '2003',
      sprinklerPercent: '100%',
      fireAlarm: 'Central Station'
    },
    original: {
      construction: 'Concrete',
      occupancy: 'Commercial Office',
      yearBuilt: '1987',
      roofType: 'Built-Up',
      roofShape: 'Flat',
      roofingYear: '2012',
      stories: '3',
      buildings: '1',
      basement: '0',
      sqFootage: '25,000',
      wiringYear: '2000',
      plumbingYear: '2000',
      heatingYear: '2003',
      sprinklerPercent: '100%',
      fireAlarm: 'Central Station'
    }
  },
  tivLimits: {
    building: '1000000',
    contents: '500000',
    other: '100000',
    businessInterruption: '250000',
    tiv: '1850000'
  },
  clientBroker: {
    insured: 'Example Corp',
    address1: '456 Market St',
    address2: 'Floor 12',
    city: 'San Francisco',
    state: 'CA',
    zip: '94105',
    county: 'San Francisco',
    country: 'USA',
    fein: '12-3456789',
    sic: '5812',
    naic: '72211',
    glCode: 'GL123456',
    businessPhone: '415-555-1234',
    website: 'www.example.com'
  },
  retailBroker: {
    company: 'Retail Broker Corp',
    address1: '789 Broker Ave',
    address2: 'Suite 200',
    city: 'San Francisco',
    state: 'CA',
    zip: '94107',
    phoneNumber: '415-555-0123',
    office: 'SF Office',
    broker: 'John Smith',
    brokerEmail: 'john@retailbroker.com',
    assistant: 'Jane Doe',
    assistantEmail: 'jane@retailbroker.com'
  },
  wholesaleBroker: {
    company: 'Wholesale Broker Inc',
    address1: '321 Wholesale Blvd',
    address2: 'Floor 5',
    city: 'San Francisco',
    state: 'CA',
    zip: '94111',
    phoneNumber: '415-555-0456',
    office: 'Main Office',
    broker: 'Bob Wilson',
    brokerEmail: 'bob@wholesalebroker.com',
    assistant: 'Alice Brown',
    assistantEmail: 'alice@wholesalebroker.com'
  },
  policyDates: {
    inceptionDate: '2023-05-08',
    expiryDate: '2024-05-08'
  }
};

export const PVScrubberMode: FC<PVScrubberModeProps> = ({ selectedItem }) => {
  const [data, setData] = useState<ScrubberData>(mockData);

  const handleChange = (section: keyof ScrubberData, field: string, value: string) => {
    setData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  useEffect(() => {
    // In a real implementation, this would sync with the server
    console.log('Data updated:', data);
  }, [data]);

  return (
    <div className="PVScrubberMode">
      <div className="PVScrubberMode__Content">
        <div className="PVScrubberMode__ScrollContainer">
          <PVTabs>
            <PVTabsList>
              <PVTabsListItem>Building</PVTabsListItem>
              <PVTabsListItem>Client/Broker</PVTabsListItem>
            </PVTabsList>
            <PVTabsContent>
              <PVTabsContentItem>
                <form className="PVScrubberMode__Form">
                  <section className="PVScrubberMode__Section">
                    <h2>Building Info</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="building-number">Building Number</label>
                        <PingTextInput
                          id="building-number"
                          type="text"
                          defaultValue={data.buildingInfo.bldNumber}
                          onChange={(e) => handleChange('buildingInfo', 'bldNumber', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="address-1">Address 1</label>
                        <PingTextInput
                          id="address-1"
                          type="text"
                          defaultValue={data.buildingInfo.address1}
                          onChange={(e) => handleChange('buildingInfo', 'address1', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="address-2">Address 2</label>
                        <PingTextInput
                          id="address-2"
                          type="text"
                          defaultValue={data.buildingInfo.address2}
                          onChange={(e) => handleChange('buildingInfo', 'address2', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="city">City</label>
                        <PingTextInput
                          id="city"
                          type="text"
                          defaultValue={data.buildingInfo.city}
                          onChange={(e) => handleChange('buildingInfo', 'city', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="state">State</label>
                        <PingSelectInput
                          id="state"
                          options={US_STATES}
                          defaultValue={data.buildingInfo.state}
                          onChange={(e) => handleChange('buildingInfo', 'state', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="zip">Zip</label>
                        <PingTextInput
                          id="zip"
                          type="text"
                          defaultValue={data.buildingInfo.zip}
                          onChange={(e) => handleChange('buildingInfo', 'zip', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="location">Location</label>
                        <PingTextInput
                          id="location"
                          type="text"
                          defaultValue={data.buildingInfo.location}
                          onChange={(e) => handleChange('buildingInfo', 'location', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="geocoding-type">Geocoding Type</label>
                        <PingTextInput
                          id="geocoding-type"
                          type="text"
                          defaultValue={data.buildingInfo.geocodingType}
                          onChange={(e) => handleChange('buildingInfo', 'geocodingType', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>
                  <section className="PVScrubberMode__Section">
                    <h2>Hazard Info</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label>Flood Zone</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.floodZone}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>DTC Low Resolution</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.dtcLowRes}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>DTC High Resolution</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.dtcHighRes}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Wild Fire</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.wildFire}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>SLOSH</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.slosh}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Distance to Hydrant</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.distanceToHydrant}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Distance to Fire Station</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.distanceToFireStation}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Distance to Sink</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.distanceToSink}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Crime</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.crime}</div>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label>Earthquake Risk</label>
                        <div className="PVScrubberMode__ReadOnly">{data.hazardInfo.eqRisk}</div>
                      </div>
                    </div>
                  </section>

                  <section className="PVScrubberMode__Section">
                    <h2>COPE Info</h2>
                    <div className="PVScrubberMode__Grid PVScrubberMode__Grid--three-columns">
                      <div className="PVScrubberMode__ColumnHeader">COPE</div>
                      <div className="PVScrubberMode__ColumnHeader">LB</div>
                      <div className="PVScrubberMode__ColumnHeader">Original</div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="construction">Construction</label>
                        <PingSelectInput
                          id="construction"
                          options={[
                            { value: "", label: "Select type" },
                            { value: "frame", label: "Frame" },
                            { value: "masonry", label: "Masonry" },
                            { value: "steel", label: "Steel" },
                            { value: "concrete", label: "Concrete" }
                          ]}
                          defaultValue={data.copeInfo.construction}
                          onChange={(e) => handleChange('copeInfo', 'construction', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.construction}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.construction}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="occupancy">Occupancy</label>
                        <PingTextInput
                          id="occupancy"
                          type="text"
                          defaultValue={data.copeInfo.occupancy}
                          onChange={(e) => handleChange('copeInfo', 'occupancy', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.occupancy}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.occupancy}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="year-built">Year Built</label>
                        <PingTextInput
                          id="year-built"
                          type="text"
                          defaultValue={data.copeInfo.yearBuilt}
                          onChange={(e) => handleChange('copeInfo', 'yearBuilt', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.yearBuilt}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.yearBuilt}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="roof-type">Roof Type</label>
                        <PingTextInput
                          id="roof-type"
                          type="text"
                          defaultValue={data.copeInfo.roofType}
                          onChange={(e) => handleChange('copeInfo', 'roofType', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.roofType}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.roofType}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="roof-shape">Roof Shape</label>
                        <PingTextInput
                          id="roof-shape"
                          type="text"
                          defaultValue={data.copeInfo.roofShape}
                          onChange={(e) => handleChange('copeInfo', 'roofShape', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.roofShape}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.roofShape}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="roofing-year">Roofing Year</label>
                        <PingTextInput
                          id="roofing-year"
                          type="text"
                          defaultValue={data.copeInfo.roofingYear}
                          onChange={(e) => handleChange('copeInfo', 'roofingYear', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.roofingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.roofingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="stories">Stories</label>
                        <PingTextInput
                          id="stories"
                          type="text"
                          defaultValue={data.copeInfo.stories}
                          onChange={(e) => handleChange('copeInfo', 'stories', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.stories}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.stories}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="buildings">Buildings</label>
                        <PingTextInput
                          id="buildings"
                          type="text"
                          defaultValue={data.copeInfo.buildings}
                          onChange={(e) => handleChange('copeInfo', 'buildings', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.buildings}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.buildings}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="basement">Basement</label>
                        <PingTextInput
                          id="basement"
                          type="text"
                          defaultValue={data.copeInfo.basement}
                          onChange={(e) => handleChange('copeInfo', 'basement', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.basement}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.basement}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="square-footage">Square Footage</label>
                        <PingTextInput
                          id="square-footage"
                          type="text"
                          defaultValue={data.copeInfo.sqFootage}
                          onChange={(e) => handleChange('copeInfo', 'sqFootage', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.sqFootage}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.sqFootage}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="wiring-year">Wiring Year</label>
                        <PingTextInput
                          id="wiring-year"
                          type="text"
                          defaultValue={data.copeInfo.wiringYear}
                          onChange={(e) => handleChange('copeInfo', 'wiringYear', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.wiringYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.wiringYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="plumbing-year">Plumbing Year</label>
                        <PingTextInput
                          id="plumbing-year"
                          type="text"
                          defaultValue={data.copeInfo.plumbingYear}
                          onChange={(e) => handleChange('copeInfo', 'plumbingYear', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.plumbingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.plumbingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="heating-year">Heating Year</label>
                        <PingTextInput
                          id="heating-year"
                          type="text"
                          defaultValue={data.copeInfo.heatingYear}
                          onChange={(e) => handleChange('copeInfo', 'heatingYear', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.heatingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.heatingYear}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="sprinkler-percent">Sprinkler Percent</label>
                        <PingTextInput
                          id="sprinkler-percent"
                          type="text"
                          defaultValue={data.copeInfo.sprinklerPercent}
                          onChange={(e) => handleChange('copeInfo', 'sprinklerPercent', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.sprinklerPercent}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.sprinklerPercent}</span>
                      </div>
                      <div className="PVScrubberMode__Field PVScrubberMode__Field--row">
                        <label htmlFor="fire-alarm">Fire Alarm</label>
                        <PingTextInput
                          id="fire-alarm"
                          type="text"
                          defaultValue={data.copeInfo.fireAlarm}
                          onChange={(e) => handleChange('copeInfo', 'fireAlarm', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.lb.fireAlarm}</span>
                      </div>
                      <div className="PVScrubberMode__Field">
                        <span>{data.copeInfo.original.fireAlarm}</span>
                      </div>
                    </div>
                  </section>
                  <section className="PVScrubberMode__Section">
                    <h2>TIV/Limits</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="building-limit">Building</label>
                        <PingTextInput
                          id="building-limit"
                          type="text"
                          defaultValue={data.tivLimits.building}
                          onChange={(e) => handleChange('tivLimits', 'building', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="contents-limit">Contents</label>
                        <PingTextInput
                          id="contents-limit"
                          type="text"
                          defaultValue={data.tivLimits.contents}
                          onChange={(e) => handleChange('tivLimits', 'contents', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="other-limit">Other</label>
                        <PingTextInput
                          id="other-limit"
                          type="text"
                          defaultValue={data.tivLimits.other}
                          onChange={(e) => handleChange('tivLimits', 'other', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="business-interruption">Bus. Interruption</label>
                        <PingTextInput
                          id="business-interruption"
                          type="text"
                          defaultValue={data.tivLimits.businessInterruption}
                          onChange={(e) => handleChange('tivLimits', 'businessInterruption', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="tiv">TIV</label>
                        <PingTextInput
                          id="tiv"
                          type="text"
                          defaultValue={data.tivLimits.tiv}
                          onChange={(e) => handleChange('tivLimits', 'tiv', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>
                </form>
              </PVTabsContentItem>

              <PVTabsContentItem>
                <form className="PVScrubberMode__Form">
                  <section className="PVScrubberMode__Section">
                    <h2>Insured</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="insured">Insured</label>
                        <PingTextInput
                          id="insured"
                          type="text"
                          defaultValue={data.clientBroker.insured}
                          onChange={(e) => handleChange('clientBroker', 'insured', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="client-address-1">Address 1</label>
                        <PingTextInput
                          id="client-address-1"
                          type="text"
                          defaultValue={data.clientBroker.address1}
                          onChange={(e) => handleChange('clientBroker', 'address1', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="client-address-2">Address 2</label>
                        <PingTextInput
                          id="client-address-2"
                          type="text"
                          defaultValue={data.clientBroker.address2}
                          onChange={(e) => handleChange('clientBroker', 'address2', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="client-city">City</label>
                        <PingTextInput
                          id="client-city"
                          type="text"
                          defaultValue={data.clientBroker.city}
                          onChange={(e) => handleChange('clientBroker', 'city', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="client-state">State</label>
                        <PingSelectInput
                          id="client-state"
                          options={US_STATES}
                          defaultValue={data.clientBroker.state}
                          onChange={(e) => handleChange('clientBroker', 'state', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="client-zip">Zip</label>
                        <PingTextInput
                          id="client-zip"
                          type="text"
                          defaultValue={data.clientBroker.zip}
                          onChange={(e) => handleChange('clientBroker', 'zip', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="county">County</label>
                        <PingTextInput
                          id="county"
                          type="text"
                          defaultValue={data.clientBroker.county}
                          onChange={(e) => handleChange('clientBroker', 'county', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="country">Country</label>
                        <PingTextInput
                          id="country"
                          type="text"
                          defaultValue={data.clientBroker.country}
                          onChange={(e) => handleChange('clientBroker', 'country', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="website">Website</label>
                        <PingTextInput
                          id="website"
                          type="text"
                          defaultValue={data.clientBroker.website}
                          onChange={(e) => handleChange('clientBroker', 'website', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="fein">FEIN</label>
                        <PingTextInput
                          id="fein"
                          type="text"
                          defaultValue={data.clientBroker.fein}
                          onChange={(e) => handleChange('clientBroker', 'fein', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="sic">SIC</label>
                        <PingTextInput
                          id="sic"
                          type="text"
                          defaultValue={data.clientBroker.sic}
                          onChange={(e) => handleChange('clientBroker', 'sic', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="naic">NAIC</label>
                        <PingTextInput
                          id="naic"
                          type="text"
                          defaultValue={data.clientBroker.naic}
                          onChange={(e) => handleChange('clientBroker', 'naic', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="gl-code">GL Code</label>
                        <PingTextInput
                          id="gl-code"
                          type="text"
                          defaultValue={data.clientBroker.glCode}
                          onChange={(e) => handleChange('clientBroker', 'glCode', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="business-phone">Business Phone</label>
                        <PingTextInput
                          id="business-phone"
                          type="tel"
                          defaultValue={data.clientBroker.businessPhone}
                          onChange={(e) => handleChange('clientBroker', 'businessPhone', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>

                  {/* Retail Broker - Full width section */}
                  <section className="PVScrubberMode__Section PVScrubberMode__Section">
                    <h2>Retail Broker</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-company">Company</label>
                        <PingTextInput
                          id="retail-company"
                          type="text"
                          defaultValue={data.retailBroker.company}
                          onChange={(e) => handleChange('retailBroker', 'company', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-address-1">Address 1</label>
                        <PingTextInput
                          id="retail-address-1"
                          type="text"
                          defaultValue={data.retailBroker.address1}
                          onChange={(e) => handleChange('retailBroker', 'address1', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-address-2">Address 2</label>
                        <PingTextInput
                          id="retail-address-2"
                          type="text"
                          defaultValue={data.retailBroker.address2}
                          onChange={(e) => handleChange('retailBroker', 'address2', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-city">City</label>
                        <PingTextInput
                          id="retail-city"
                          type="text"
                          defaultValue={data.retailBroker.city}
                          onChange={(e) => handleChange('retailBroker', 'city', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-state">State</label>
                        <PingSelectInput
                          id="retail-state"
                          options={US_STATES}
                          defaultValue={data.retailBroker.state}
                          onChange={(e) => handleChange('retailBroker', 'state', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-zip">Zip</label>
                        <PingTextInput
                          id="retail-zip"
                          type="text"
                          defaultValue={data.retailBroker.zip}
                          onChange={(e) => handleChange('retailBroker', 'zip', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-phone">Phone Number</label>
                        <PingTextInput
                          id="retail-phone"
                          type="tel"
                          defaultValue={data.retailBroker.phoneNumber}
                          onChange={(e) => handleChange('retailBroker', 'phoneNumber', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-office">Office</label>
                        <PingTextInput
                          id="retail-office"
                          type="text"
                          defaultValue={data.retailBroker.office}
                          onChange={(e) => handleChange('retailBroker', 'office', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-broker">Broker</label>
                        <PingTextInput
                          id="retail-broker"
                          type="text"
                          defaultValue={data.retailBroker.broker}
                          onChange={(e) => handleChange('retailBroker', 'broker', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-broker-email">Broker Email</label>
                        <PingTextInput
                          id="retail-broker-email"
                          type="email"
                          defaultValue={data.retailBroker.brokerEmail}
                          onChange={(e) => handleChange('retailBroker', 'brokerEmail', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-assistant">Assistant</label>
                        <PingTextInput
                          id="retail-assistant"
                          type="text"
                          defaultValue={data.retailBroker.assistant}
                          onChange={(e) => handleChange('retailBroker', 'assistant', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="retail-assistant-email">Assistant Email</label>
                        <PingTextInput
                          id="retail-assistant-email"
                          type="email"
                          defaultValue={data.retailBroker.assistantEmail}
                          onChange={(e) => handleChange('retailBroker', 'assistantEmail', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>

                  {/* Wholesale Broker - Full width section */}
                  <section className="PVScrubberMode__Section PVScrubberMode__Section">
                    <h2>Wholesale Broker</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-company">Company</label>
                        <PingTextInput
                          id="wholesale-company"
                          type="text"
                          defaultValue={data.wholesaleBroker.company}
                          onChange={(e) => handleChange('wholesaleBroker', 'company', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-address-1">Address 1</label>
                        <PingTextInput
                          id="wholesale-address-1"
                          type="text"
                          defaultValue={data.wholesaleBroker.address1}
                          onChange={(e) => handleChange('wholesaleBroker', 'address1', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-address-2">Address 2</label>
                        <PingTextInput
                          id="wholesale-address-2"
                          type="text"
                          defaultValue={data.wholesaleBroker.address2}
                          onChange={(e) => handleChange('wholesaleBroker', 'address2', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-city">City</label>
                        <PingTextInput
                          id="wholesale-city"
                          type="text"
                          defaultValue={data.wholesaleBroker.city}
                          onChange={(e) => handleChange('wholesaleBroker', 'city', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-state">State</label>
                        <PingSelectInput
                          id="wholesale-state"
                          options={US_STATES}
                          defaultValue={data.wholesaleBroker.state}
                          onChange={(e) => handleChange('wholesaleBroker', 'state', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-zip">Zip</label>
                        <PingTextInput
                          id="wholesale-zip"
                          type="text"
                          defaultValue={data.wholesaleBroker.zip}
                          onChange={(e) => handleChange('wholesaleBroker', 'zip', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-phone">Phone Number</label>
                        <PingTextInput
                          id="wholesale-phone"
                          type="tel"
                          defaultValue={data.wholesaleBroker.phoneNumber}
                          onChange={(e) => handleChange('wholesaleBroker', 'phoneNumber', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-office">Office</label>
                        <PingTextInput
                          id="wholesale-office"
                          type="text"
                          defaultValue={data.wholesaleBroker.office}
                          onChange={(e) => handleChange('wholesaleBroker', 'office', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-broker">Broker</label>
                        <PingTextInput
                          id="wholesale-broker"
                          type="text"
                          defaultValue={data.wholesaleBroker.broker}
                          onChange={(e) => handleChange('wholesaleBroker', 'broker', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-broker-email">Broker Email</label>
                        <PingTextInput
                          id="wholesale-broker-email"
                          type="email"
                          defaultValue={data.wholesaleBroker.brokerEmail}
                          onChange={(e) => handleChange('wholesaleBroker', 'brokerEmail', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-assistant">Assistant</label>
                        <PingTextInput
                          id="wholesale-assistant"
                          type="text"
                          defaultValue={data.wholesaleBroker.assistant}
                          onChange={(e) => handleChange('wholesaleBroker', 'assistant', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="wholesale-assistant-email">Assistant Email</label>
                        <PingTextInput
                          id="wholesale-assistant-email"
                          type="email"
                          defaultValue={data.wholesaleBroker.assistantEmail}
                          onChange={(e) => handleChange('wholesaleBroker', 'assistantEmail', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="PVScrubberMode__Section">
                    <h2>Policy Dates</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="inception-date">Inception</label>
                        <PingTextInput
                          id="inception-date"
                          type="date"
                          defaultValue={data.policyDates.inceptionDate}
                          onChange={(e) => handleChange('policyDates', 'inceptionDate', e.target.value)}
                        />
                      </div>
                      <div className="PVScrubberMode__Field">
                        <label htmlFor="expiry-date">Expiry</label>
                        <PingTextInput
                          id="expiry-date"
                          type="date"
                          defaultValue={data.policyDates.expiryDate}
                          onChange={(e) => handleChange('policyDates', 'expiryDate', e.target.value)}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="PVScrubberMode__Section PVScrubberMode__Section--full-width">
                    <h2>AI Summary</h2>
                    <div className="PVScrubberMode__Grid">
                      <div className="PVScrubberMode__Field--full">
                        <label htmlFor="ai-summary" className="sr-only" >AI Summary</label>
                        <PingTextarea
                          id="ai-summary"
                          placeholder="AI-generated summary will appear here..."
                          rows={4}
                          className="PVScrubberMode__TextArea"
                        />
                      </div>
                    </div>
                  </section>
                </form>
              </PVTabsContentItem>
            </PVTabsContent>
          </PVTabs>
        </div>
      </div>
    </div>
  );
};
