import React, { useState, useEffect } from "react";
import { PingButton } from "@repo/ping-react-js";
import { useSelector, useDispatch } from "react-redux";
import "./VersionChecker.scss";
import { RootState } from "src/services/store";
import { initializeVersionWebSocket } from "../services/versionApi";

export const VersionChecker: React.FC = () => {
  const dispatch = useDispatch();

  // Get current version and auth token from Redux store
  const currentVersion = useSelector(
    (state: RootState) => state.version.currentVersion,
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth?.accessToken,
  );

  // Store the initially loaded version to compare against updates
  const [initialVersion, setInitialVersion] = useState<string | null>(null);
  // Track if we should show the new version notification
  const [hasNewVersion, setHasNewVersion] = useState(false);

  // Initialize WebSocket connection when auth token is available
  useEffect(() => {
    if (accessToken) {
      initializeVersionWebSocket(accessToken, dispatch);
    }
  }, [accessToken, dispatch]);

  // Set the initial version when component first mounts or when currentVersion is first available
  useEffect(() => {
    if (currentVersion && !initialVersion) {
      setInitialVersion(currentVersion);
    }
  }, [currentVersion, initialVersion]);

  // Detect version changes
  useEffect(() => {
    if (initialVersion && currentVersion && initialVersion !== currentVersion) {
      setHasNewVersion(true);
    }
  }, [currentVersion, initialVersion]);

  const handleRefresh = () => {
    window.location.reload();
  };

  if (!hasNewVersion) {
    return null;
  }

  return (
    <div className="VersionChecker">
      <span>A new app version is available!</span>
      <PingButton onClick={handleRefresh} label="Refresh" />
    </div>
  );
};
