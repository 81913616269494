import React from "react";
import {
  PingTextInput as PingInput,
  PingSelectInput as PingSelect,
  PingMultiCheckbox,
} from "@repo/ping-react-js";

/**
 * Props for the CustomViewForm component
 */
interface CustomViewFormProps {
  /** Form field values */
  formValues: {
    name: string;
    description: string;
    icon: string;
    advancedFilters: Record<string, any>;
    sortField: "inception_date" | "received_time";
    sortDirection: "asc" | "desc";
  };
  /** Handlers for form field changes */
  handlers: {
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    setIcon: (icon: string) => void;
    setAdvancedFilters: (filters: Record<string, any>) => void;
    setSortField: (field: "inception_date" | "received_time") => void;
    setSortDirection: (direction: "asc" | "desc") => void;
  };
  /** Data for form options */
  data: {
    teams?: Array<{ team_id: string | number; team_name: string }>;
    workflowStatuses?: Array<{ id: string | number; name: string }>;
    users?: Array<{ id: string | number; username: string }>;
  };
}

/**
 * A reusable form component for creating and editing custom views
 */
const CustomViewForm: React.FC<CustomViewFormProps> = ({
  formValues,
  handlers,
  data,
}) => {
  const { name, description, advancedFilters, sortField, sortDirection } =
    formValues;
  const {
    setName,
    setDescription,
    setAdvancedFilters,
    setSortField,
    setSortDirection,
  } = handlers;
  const { teams, workflowStatuses, users } = data;

  /**
   * Handles changes to the readiness operator and updates the form state accordingly
   */
  const handleReadinessOperatorChange = (newOperator: string) => {
    // Create a new filters object with the updated operator
    const updatedFilters = {
      ...advancedFilters,
      readiness_operator: newOperator,
      readiness: advancedFilters.readiness || "",
      readiness__gt: advancedFilters.readiness__gt || "",
      readiness__lt: advancedFilters.readiness__lt || "",
    };

    updatedFilters.readiness = "";
    updatedFilters.readiness__gt = "";
    updatedFilters.readiness__lt = "";

    setAdvancedFilters(updatedFilters);
  };

  /* Accessibility Note - we should carefully check the labels and other accessibility attributes around the filters and multicheckboxes
    at some point. Label association should be improved. */

  return (
    <form className="CustomViewsPage__Form">
      <div className="CustomViewsPage__FormGroup">
        <label htmlFor="name">Name</label>
        <PingInput
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter view name"
          required
        />
      </div>

      <div className="CustomViewsPage__FormGroup">
        <label htmlFor="description">Description</label>
        <PingInput
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter view description"
          required
        />
      </div>

      <div className="CustomViewsPage__FormGroup">
        <label>Advanced Filters</label>
        <div className="CustomViewsPage__AdvancedFilters">
          <div className="CustomViewsPage__FilterOption">
            <label>Readiness score %</label>
            <div className="CustomViewsPage__RangeFilter">
              <PingSelect
                value={advancedFilters.readiness_operator || "equal"}
                onChange={(e) => handleReadinessOperatorChange(e.target.value)}
                options={[
                  { value: "equal", label: "=" },
                  { value: "greater_than", label: ">" },
                  { value: "less_than", label: "<" },
                  { value: "range", label: "Range" },
                ]}
              />
              {advancedFilters.readiness_operator === "equal" && (
                <PingInput
                  type="number"
                  min="0"
                  value={advancedFilters.readiness || ""}
                  onChange={(e) =>
                    setAdvancedFilters({
                      ...advancedFilters,
                      readiness: e.target.value,
                    })
                  }
                  placeholder="For example, 5 for 5%"
                />
              )}
              {advancedFilters.readiness_operator === "greater_than" && (
                <PingInput
                  type="number"
                  min="0"
                  value={advancedFilters.readiness__gt || ""}
                  onChange={(e) =>
                    setAdvancedFilters({
                      ...advancedFilters,
                      readiness__gt: e.target.value,
                    })
                  }
                  placeholder="For example, 5 for 5%"
                />
              )}
              {advancedFilters.readiness_operator === "less_than" && (
                <PingInput
                  type="number"
                  min="0"
                  value={advancedFilters.readiness__lt || ""}
                  onChange={(e) =>
                    setAdvancedFilters({
                      ...advancedFilters,
                      readiness__lt: e.target.value,
                    })
                  }
                  placeholder="For example, 5 for 5%"
                />
              )}
              {advancedFilters.readiness_operator === "range" && (
                <div className="CustomViewsPage__RangeInputs">
                  <PingInput
                    type="number"
                    min="0"
                    value={advancedFilters.readiness__gt || ""}
                    onChange={(e) =>
                      setAdvancedFilters({
                        ...advancedFilters,
                        readiness__gt: e.target.value,
                      })
                    }
                    placeholder="For example, 5 for 5%"
                  />
                  <span className="CustomViewsPage__RangeSeparator">to</span>
                  <PingInput
                    type="number"
                    min="0"
                    value={advancedFilters.readiness__lt || ""}
                    onChange={(e) =>
                      setAdvancedFilters({
                        ...advancedFilters,
                        readiness__lt: e.target.value,
                      })
                    }
                    placeholder="For example, 5 for 5%"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="CustomViewsPage__FilterOption">
            <label>Assigned to</label>
            <PingMultiCheckbox
              label="Assigned to"
              selected={
                Array.isArray(advancedFilters.claimed_by_id)
                  ? advancedFilters.claimed_by_id.map((id) => id.toString())
                  : []
              }
              onChange={(values) =>
                setAdvancedFilters({
                  ...advancedFilters,
                  claimed_by_id: values,
                })
              }
              options={[
                ...(users || []).map((user) => ({
                  value: user.id.toString(),
                  label: user.username,
                })),
              ]}
              maintainOrder={true}
            />
          </div>

          <div className="CustomViewsPage__FilterOption">
            <label>Status</label>
            <PingMultiCheckbox
              label="Status"
              selected={
                Array.isArray(advancedFilters.workflow_status_id)
                  ? advancedFilters.workflow_status_id.map((id) =>
                      id.toString(),
                    )
                  : []
              }
              onChange={(values) =>
                setAdvancedFilters({
                  ...advancedFilters,
                  workflow_status_id: values,
                })
              }
              options={[
                ...(workflowStatuses || []).map((status) => ({
                  value: status.id.toString(),
                  label: `${status.name} (id: ${status.id})`,
                })),
              ]}
              maintainOrder={true}
            />
          </div>

          <div className="CustomViewsPage__FilterOption">
            <label>Team</label>
            <PingMultiCheckbox
              label="Team"
              selected={
                Array.isArray(advancedFilters.team_id)
                  ? advancedFilters.team_id.map((id) => id.toString())
                  : []
              }
              onChange={(values) =>
                setAdvancedFilters({
                  ...advancedFilters,
                  team_id: values,
                })
              }
              options={[
                ...(teams || []).map((team) => ({
                  value: team?.team_id?.toString(),
                  label: team?.team_name,
                })),
              ]}
              maintainOrder={true}
            />
          </div>
        </div>
      </div>

      <div className="CustomViewsPage__FormGroup">
        <label>Sort Configuration</label>
        <div className="CustomViewsPage__SortConfig">
          <div className="CustomViewsPage__SortField">
            <label htmlFor="sort_field">Sort Field</label>
            <PingSelect
              id="sort_field"
              value={sortField}
              onChange={(e) =>
                setSortField(
                  e.target.value as "inception_date" | "received_time",
                )
              }
              options={[
                { value: "received_time", label: "Received Time" },
                { value: "inception_date", label: "Inception Date" },
              ]}
            />
          </div>
          <div className="CustomViewsPage__SortDirection">
            <label htmlFor="sort_direction">Sort Direction</label>
            <PingSelect
              id="sort_direction"
              value={sortDirection}
              onChange={(e) =>
                setSortDirection(e.target.value as "asc" | "desc")
              }
              options={[
                { value: "desc", label: "Newest First" },
                { value: "asc", label: "Oldest First" },
              ]}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomViewForm;
