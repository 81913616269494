import PingLucideIcon from "../icons/PingLucideIcon";
import "./PingCheckboxInput.scss";

type PingCheckboxInputProps = {
  label: string;
  name: string;
  isChecked: boolean;
  onChange: (value: string, isChecked: boolean) => void;
  className?: string;
  secondaryLabel?: string;
};

export const PingCheckboxInput = ({
  label,
  secondaryLabel,
  name,
  isChecked,
  onChange,
  className = "",
}: PingCheckboxInputProps) => {
  return (
    <div className={`PingCheckboxInput ${className}`}>
      <div>
        <input
          type="checkbox"
          name={name}
          className="PingCheckboxInput__Checkbox"
          checked={isChecked}
          onChange={(e) => onChange(name, e.target.checked)}
        />
        <PingLucideIcon className="PingCheckboxInput__Icon" iconName="Check" />
      </div>
      <label className="PingCheckboxInput__Label">{label}</label>
      {secondaryLabel && (
        <label className="PingCheckboxInput__SecondaryLabel">
          {secondaryLabel}
        </label>
      )}
    </div>
  );
};
