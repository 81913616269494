import {
  ComponentType,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
  forwardRef,
} from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  Placement,
} from "@floating-ui/react";
import { Icon } from "../Icon";
import * as LucideIcons from "lucide-react";
import cx from "classnames";
import { Card } from "@/components/Card";
import "./Dropdown.scss";

/**
 * Represents an item in a dropdown menu. `label` can be either a string or a ReactNode
 * for custom rendering. `value` is the unique identifier or value of the item.
 */
type DropdownItem = {
  label: string | ReactNode;
  value: string;
};

/**
 * A list of dropdown items.
 */
type DropdownItems = DropdownItem[];

/**
 * A dropdown selection can be one of two types:
 *
 *   - A simple value of type `string`.
 *   - A custom value of type `Record<string, string>`
 *
 * This enum is used to discriminate between the two kinds of values.
 */
enum DropdownSelectionType {
  SimpleValue = "simpleValue",
  FormValue = "formValue",
}

/**
 * Represents the value of a dropdown selection. This can be either a simple
 * string value or a custom form value.
 *
 * `type` contains the type of the selection (simple value or form value).
 * `value` contains the actual value of the selection. For simple values, it's a
 * `string`. For form values, it's a `Record<string, string>`.
 */
type DropdownSelectionValue =
  | { type: DropdownSelectionType.SimpleValue; value: string }
  | {
      type: DropdownSelectionType.FormValue;
      value: Record<string, string | number>;
    };

/**
 * The parameters passed to the optional `renderCustomForm` function. `onSubmit`
 * is a regular React form submit handler. `onDismiss` is used to dismiss the
 * custom form and return the dropdown to its normal state.
 */
type RenderCustomFormProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onDismiss: () => void;
};

/**
 * A string to represent a custom form value. It doesn't mean anything, I just
 * picked a string that's likely to be unique.
 *
 * If the user selects a custom form value that's not part of the `options`
 * list, pass this as the `selected` to indicate a custom selection.
 */
const DropdownFormValue = "@@PingDesign@@Dropdown@@FormValue@@" as const;

/**
 * See `DropdownFormValue`.
 */
type DropdownFormValueType = typeof DropdownFormValue;

/**
 * Dropdown orientation options
 */
type DropdownOrientation = "down" | "up";

type DropdownProps = {
  options: DropdownItems;
  selected?: string | null | DropdownFormValueType;
  placeholder?: string;
  onChange(value: DropdownSelectionValue): void;
  renderCustomForm?({
    onSubmit,
    onDismiss,
  }: RenderCustomFormProps): React.ReactNode;
  label?: string;
  iconName?: keyof typeof LucideIcons | ReactElement | ComponentType<any>;
  iconProps?: Record<string, any>;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  size?: "md" | "lg" | "icon";
  variant?: "default" | "translucent";
  orientation?: DropdownOrientation;
  offsetX?: number;
  offsetY?: number;
  customTrigger?: ReactNode;
  required?: boolean;
  openChanged?: (open: boolean) => void;
};

/**
 * A dropdown component that allows users to select from a list of options or
 * optionally display a custom form.
 */
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      options,
      selected,
      onChange,
      placeholder,
      renderCustomForm,
      label,
      iconName,
      iconProps = {},
      disabled = false,
      error = false,
      className,
      size = "md",
      variant = "default",
      orientation = "down",
      offsetX = 0,
      offsetY = 4,
      customTrigger,
      openChanged,
      required = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDisplayingCustomForm, setIsDisplayingCustomForm] = useState(false);
    const placement: Placement =
      orientation === "down" ? "bottom-start" : "top-start";

    const onOpenChange = useCallback(
      (open: boolean) => {
        if (disabled) return;
        if (openChanged) openChanged(open);
        setIsOpen(open);
        // If the user has selected a custom form value, we need to force the
        // display of the custom form every time the menu is opened. If a custom
        // value is not selected, then we need to make sure the user is returned
        // to the main menu.
        if (open && selected === DropdownFormValue) {
          setIsDisplayingCustomForm(true);
        } else if (open && selected !== DropdownFormValue) {
          setIsDisplayingCustomForm(false);
        }
      },
      [selected, disabled, openChanged],
    );

    const { context, refs, floatingStyles } = useFloating({
      open: isOpen,
      onOpenChange,
      placement: placement,
      middleware: [
        offset({
          mainAxis: offsetY,
          crossAxis: offsetX,
        }),
        flip(),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      dismiss,
    ]);

    const getSelectedOptionLabel = useCallback(() => {
      if (selected === DropdownFormValue) {
        return "Custom";
      }
      if (selected) {
        const selectedOption = options.find(
          (option) => option.value === selected,
        );
        if (selectedOption) {
          return selectedOption.label;
        }
      }
      return placeholder || "No selection";
    }, [options, selected, placeholder]);

    const selectedOptionLabel = getSelectedOptionLabel();

    const onClickMenuItem = useCallback(
      (value: string) => {
        if (disabled) return;
        onChange({ type: DropdownSelectionType.SimpleValue, value });
        setIsOpen(false);
      },
      [onChange, disabled],
    );

    const onCloseCustomForm = useCallback(() => {
      setIsDisplayingCustomForm(false);
    }, []);

    const onSubmitCustomForm = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disabled) return;
        const formData = new FormData(e.currentTarget);
        const formDataAsObject: Record<string, string> = {};
        formData.forEach((value, key) => {
          formDataAsObject[key] = value.toString();
        });
        onChange({
          type: DropdownSelectionType.FormValue,
          value: formDataAsObject,
        });
      },
      [onChange, disabled],
    );

    const renderIcon = () => {
      if (!iconName) return null;
      if (typeof iconName === "string") {
        return (
          <Icon
            iconName={iconName as keyof typeof LucideIcons}
            {...iconProps}
          />
        );
      }
      if (isValidElement(iconName)) {
        return iconName;
      }
      const IconComponent = iconName as ComponentType<any>;
      return <IconComponent {...iconProps} />;
    };

    const dropdownClasses = cx(
      "Dropdown",
      {
        "Dropdown--Disabled": disabled,
        "Dropdown--Error": error,
        "Dropdown--Large": size === "lg",
        "Dropdown--Icon": size === "icon",
        "Dropdown--Up": orientation === "up",
        "Dropdown--Translucent": variant === "translucent",
      },
      className,
    );

    const triggerClasses = cx("Dropdown__Trigger", {
      "Dropdown__Trigger--IsOpen": isOpen,
      "Dropdown__Trigger--Error": error,
      "Dropdown__Trigger--Disabled": disabled,
      "Dropdown__Trigger--IconFilled":
        size === "icon" && selected !== placeholder,
    });

    const triggerLabelClasses = cx("Dropdown__Trigger__Label", {
      "Dropdown__Trigger__Label--Filled": selected !== placeholder,
    });

    const renderLabelContent = (option: DropdownItem) => {
      return option.label;
    };

    return (
      <div className={dropdownClasses} ref={ref}>
        {label && size !== "icon" && (
          <div
            className={cx("Dropdown__Label", {
              "Dropdown__Label--Error": error,
            })}
          >
            {label}
            {required && <span className="Dropdown__Required">*</span>}
          </div>
        )}
        {customTrigger ? (
          <div ref={refs.setReference} {...getReferenceProps()}>
            {customTrigger}
          </div>
        ) : (
          <div
            className={triggerClasses}
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            {iconName && (
              <div className="Dropdown__Trigger__Prefix">{renderIcon()}</div>
            )}
            {size !== "icon" && (
              <div className={triggerLabelClasses}>
                {typeof selectedOptionLabel === "string"
                  ? selectedOptionLabel
                  : selectedOptionLabel}
              </div>
            )}
            {isOpen ? (
              <div className="Dropdown__Trigger__Suffix">
                <Icon
                  iconName="ChevronUp"
                  className="Dropdown__Trigger__Icon"
                />
              </div>
            ) : (
              <div className="Dropdown__Trigger__Suffix">
                <Icon
                  iconName="ChevronDown"
                  className="Dropdown__Trigger__Icon"
                />
              </div>
            )}
          </div>
        )}
        {isOpen && !disabled && (
          <div
            className="Dropdown__Menu"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {!isDisplayingCustomForm && (
              <>
                {options.map((option) => (
                  <div
                    className="Dropdown__Menu__Item"
                    key={option.value}
                    onClick={() => onClickMenuItem(option.value)}
                  >
                    {renderLabelContent(option)}
                  </div>
                ))}
                {renderCustomForm && (
                  <div
                    className="Dropdown__Menu__Item"
                    key="dropdown-custom-form-983urhifjk"
                    onClick={() => !disabled && setIsDisplayingCustomForm(true)}
                  >
                    Custom
                  </div>
                )}
              </>
            )}
            {isDisplayingCustomForm && (
              <Card>
                {renderCustomForm({
                  onSubmit: onSubmitCustomForm,
                  onDismiss: onCloseCustomForm,
                })}
              </Card>
            )}
          </div>
        )}
      </div>
    );
  },
);

export {
  Dropdown,
  DropdownSelectionType,
  DropdownFormValue,
  type DropdownFormValueType,
};
