import { useMemo, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "src/utils/redux";
import omit from "lodash/omit";
import { useTeamName, usePingId } from "utils/hooks";
import isFunction from "lodash/isFunction";

import {
  PingSearchQueryBuilderField,
  TEXT_SEGMENT_KEY,
  areObjectsEqual,
} from "@repo/ping-react-js";

import { PING_VISION_DEFAULT_FIELDS } from "constants/ApiConstants";
import {
  useGetSubmissionsQuery,
  useGetSubmissionHistoryQuery,
  useGetSingleSubmissionQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { GetSubmissionsQueryParams } from "ts-types/QueryTypes";
import { setCurrentCursorId } from "reducers/inbox";
import { useHistory } from "react-router-dom";

export const SEARCH_PARAM_NAME = "search";

// Put any url search params here that should be omitted from the search query
export const omittedSearchFields = ["selected", "mode"];

const getName = (t: Record<string, string>) => t.username || "";

export const useGetAdvancedSearchFields = (): {
  advancedSearchFields: PingSearchQueryBuilderField[];
} => {
  const settings = useAppSelector((state) => state.settings.settings);
  const advancedSearchFields: PingSearchQueryBuilderField[] = useMemo(() => {
    return [
      {
        label: "Readiness score",
        fieldName: "readiness",
        type: "range",
      },
      {
        label: "User",
        fieldName: "claimed_by_id",
        type: "dropdown",
        options: settings?.users?.map((t) => ({
          label: getName(t),
          value: t.id.toString(),
        })),
      },
      {
        label: "Workflow Status",
        fieldName: "workflow_status_id",
        type: "dropdown",
        options: settings?.submission_status?.map((s) => ({
          label: s.name,
          value: s.id.toString(),
        })),
      },
      {
        label: "Team Name",
        fieldName: "team_id",
        type: "dropdown",
        options: settings?.teams?.map((t) => ({
          label: t.team_name,
          value: t.team_id.toString(),
        })),
      },
    ];
  }, [settings]);

  return { advancedSearchFields };
};

export const useGetSubmissionHistoryList = () => {
  const pingId = usePingId();
  const settings = useAppSelector((state) => state.settings.settings);
  const teams = settings?.teams?.map((t) => t.team_id) || [];
  return useGetSubmissionHistoryQuery(
    { id: pingId || "", realTimeSubscriptions: { teams: teams || [] } },
    { skip: !pingId },
  );
};

/**
 * Checks if a value is a valid JSON object
 * @param item - The value to check
 */
const isJson = (item: any) => {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch {
    return false;
  }

  return typeof value === "object" && value !== null;
};

/**
 * Helper function to convert a value to a number array, after parsing any JSON
 * Handles three cases:
 * 1. String with comma-separated values (e.g., "1,2,3")
 * 2. Single string or number value
 * 3. Array of strings or numbers
 */
const convertToNumberArray = (
  value: string | number | string[] | number[] | boolean,
): number[] => {
  const parsedValue = isJson(value) ? JSON.parse(value.toString()) : value;

  // Case 1: String with comma-separated values
  if (typeof parsedValue === "string" && parsedValue.includes(",")) {
    return parsedValue.split(",").map((item) => +item.trim());
  }
  // Case 2: Single value (string or number)
  else if (!Array.isArray(parsedValue)) {
    return [+parsedValue];
  }
  // Case 3: Array of values
  else {
    return parsedValue.map((id) => +id);
  }
};

/**
 * Parses and normalizes search values by converting string numbers to actual numbers
 * and ensuring consistent format for array values
 * @param searchValues - The search values to parse
 * @returns Cleaned search values with proper types
 */
export const parsedSearchValues = (
  searchValues: Record<string, string | number | number[] | string[] | boolean>,
) => {
  if (!searchValues) {
    return {};
  }

  let cleanedValues: {
    [key: string]: string | number | number[] | string[] | boolean;
  } = {
    ...searchValues,
  };

  // Fields that should be converted to number arrays
  const numberArrayFields = ["workflow_status_id", "team_id", "claimed_by_id"];

  // Process each field that needs to be converted to a number array
  numberArrayFields.forEach((field) => {
    if (cleanedValues[field]) {
      cleanedValues[field] = convertToNumberArray(cleanedValues[field]);
    }
  });

  // Fields that should be converted to numbers
  const numberFields = ["readiness", "readiness__lt", "readiness__gt"];
  numberFields.forEach((field) => {
    if (cleanedValues[field]) {
      cleanedValues[field] = parseInt(cleanedValues[field].toString(), 10);
    }
  });

  if (cleanedValues.claimed_by_id__isnull === "true") {
    cleanedValues.claimed_by_id__isnull = true;
  }

  if (cleanedValues.claimed_by_id__isnull === "false") {
    cleanedValues.claimed_by_id__isnull = false;
  }

  return cleanedValues;
};

/**
 * Hook that memoizes the result of parsedSearchValues to prevent unnecessary recalculations
 * @param searchValues - The search values to parse
 * @returns Memoized cleaned search values with proper types
 */
export const useParsedSearchValues = (
  searchValues?: Record<string, string | number | number[] | string[]>,
) => {
  return useMemo(() => parsedSearchValues(searchValues || {}), [searchValues]);
};

/**
 * Generates a unique query key for a submission based on the inbox slug and team ID.
 * This key is used for caching and identifying specific submission lists.
 * The key format helps maintain consistent filtering criteria across components.
 *
 * @param inboxSlug - The slug identifier for the inbox/view
 * @param teamName - The team identifier
 * @returns A formatted string to be used as a query key
 */
export const getSubmissionQueryKeyForSlug = (
  inboxSlug: string | null | undefined,
  teamName: string | null | undefined,
) => {
  if (!inboxSlug) {
    return "";
  }

  if (inboxSlug === "my-issues") {
    return "my-issues";
  } else if (inboxSlug?.startsWith("custom-views/")) {
    return inboxSlug;
  } else if (inboxSlug?.startsWith("custom-view")) {
    return `custom-views/${inboxSlug}`;
  } else if (teamName) {
    return `${teamName?.replace(/ /g, "-")}-${inboxSlug}`;
  }

  return "";
};

/**
 * Extracts the slug from a pathname that starts with "/submission/"
 * If the pathname doesn't start with "/submission/", returns the full pathname
 *
 * @param pathname - The pathname from the location object
 * @returns The slug (text after the last slash) or the full pathname if not a submission route
 */
export const extractSubmissionSlug = (pathname: string): string => {
  if (pathname.startsWith("/submission/")) {
    // Extract everything after the last slash
    return pathname.split("/").pop() || "";
  }
  return pathname;
};

export const useGetSubmissionList = ({
  skipOverride = false,
}: {
  skipOverride?: boolean;
} = {}) => {
  // Note from Lori - right now I only figured out how to use this query in two places directly - the dashboard and list for submissions.
  // Other places take results from inbox.sovs or we risk having multiple server requests.

  const sortConfig = useAppSelector((state) => state.settings.globalSortConfig);
  const teamName = useTeamName();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const slug = extractSubmissionSlug(history.location.pathname);
  // useHistory is the most up to date place to access URL values, which prevents critical timing issues
  // re cursor clears, in the useGetSubmissionList query.
  const searchParamsObject = Object.fromEntries(
    new URLSearchParams(history.location.search),
  );
  const searchValuesWithoutOmitted = omit(
    searchParamsObject,
    omittedSearchFields,
  );
  const searchValues = useParsedSearchValues(searchValuesWithoutOmitted);
  const currentCursorId = useAppSelector(
    (state) => state.inbox.currentCursorId,
  );
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );
  const userId =
    useAppSelector((state) => state.settings?.envData?.user?.id) || 0;
  const settings = useAppSelector((state) => state.settings.settings);

  // When we load the page, we pass all of the teams you're a member of to the
  // backend for real-time subscriptions. This allows the backend to send you
  // updates for all submissions via push.
  const teams = settings?.teams?.map((t) => t.team_id) || [];

  // Store previous slug to detect changes
  const prevParamsRef = useRef({
    searchValues,
    sortConfig,
    slug,
    teamName,
  });

  useEffect(() => {
    if (
      !areObjectsEqual(prevParamsRef.current, {
        slug,
        teamName,
        searchValues,
        sortConfig,
      }) &&
      navToQueryParams[getSubmissionQueryKeyForSlug(slug, teamName)]
    ) {
      // we never want to send the last query's cursor if a new query is made.
      // However this cursor update here is only for keeping the redux store in sync. Since the redux store change isn't
      // intercepted fast enough here, we have to do a cursor override within the getSubmissions query in pvslice.ts
      // when list parameters change.

      dispatch(setCurrentCursorId(null));

      prevParamsRef.current = {
        slug,
        teamName,
        searchValues,
        sortConfig,
      };
    }
  }, [dispatch, slug, teamName, searchValues, sortConfig, navToQueryParams]);

  const teamNames = settings?.teams?.map((team) => team.team_name) || [];

  // Determine if we should skip the query
  const shouldSkip =
    !sortConfig?.field ||
    !sortConfig?.direction ||
    !teams.length ||
    !navToQueryParams[getSubmissionQueryKeyForSlug(slug, teamName)] ||
    (teamName && !teamNames.includes(teamName.replace(/-/g, " "))) ||
    !slug;

  const key = getSubmissionQueryKeyForSlug(slug, teamName);

  let additionFilters = null;
  additionFilters = isFunction(navToQueryParams?.[key])
    ? navToQueryParams[key](userId)?.filter
    : (navToQueryParams?.[key]?.filter ?? null);

  let advancedSearchFields = {
    ...(additionFilters && typeof additionFilters === "object"
      ? additionFilters
      : {}),
    ...omit(searchValues, TEXT_SEGMENT_KEY),
    ...(searchValues[TEXT_SEGMENT_KEY]
      ? { search: searchValues[TEXT_SEGMENT_KEY] }
      : {}),
    sort_by: sortConfig?.field,
    sort_order: sortConfig?.direction,
  };

  const queryParams: GetSubmissionsQueryParams = {
    fields: PING_VISION_DEFAULT_FIELDS,
    advancedSearchFields,
    realTimeSubscriptions: { teams: teams || [] },
    cursorId: currentCursorId,
  };

  return useGetSubmissionsQuery(queryParams, {
    skip: skipOverride || shouldSkip,
    // Force a refetch whenever the args change, including cursor, sort type, sort direction, etc
    refetchOnMountOrArgChange: true,
  });
};

export const useGetSingleSubmission = (
  pingId: string | null,
  additionalFilters: object = {},
  options: { skip?: boolean } = {},
) => {
  return useGetSingleSubmissionQuery(
    {
      id: pingId || "",
      ...additionalFilters,
      fields: PING_VISION_DEFAULT_FIELDS,
    },
    { skip: options.skip || !pingId },
  );
};

export const useGetNav = () => {
  const settings = useAppSelector((state) => state.settings.settings);
  // Get all teams the user is a member of for real-time subscriptions
  const teams = settings?.teams?.map((t) => t.team_id) || [];

  return useGetNavQuery({ realTimeSubscriptions: { teams } });
};
