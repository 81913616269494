import { useMemo, useState } from "react";
import { orderBy } from "lodash";

import { PingMaterialIcon } from "@repo/ping-react-js";

import "./PingMultiCheckbox.scss";
import { PingCheckboxInput } from "./PingCheckboxInput";

type PingMultiCheckboxProps = {
  label: string;
  options: { value: string | number; label: string, perc?: string }[];
  selected: string[];
  onChange: (values: string[]) => void;
  limitToTop?: null | number;
  overrideUnavailableText?: string;
  maintainOrder?: boolean;
};

export const PingMultiCheckbox = ({
  label,
  options,
  selected,
  onChange,
  limitToTop = null,
  overrideUnavailableText,
  maintainOrder = false,
}: PingMultiCheckboxProps) => {
  const [isShowingAllOptions, setIsShowingAllOptions] = useState(
    limitToTop === null
  );

  const visibleOptions = useMemo(() => {
    const sortedOptions = maintainOrder ? options : orderBy(
      options,
      (option) => selected.includes(option.value.toString()),
      "desc"
    );

    if (isShowingAllOptions) {
      return sortedOptions;
    } else {
      return sortedOptions.slice(0, limitToTop);
    }
  }, [options, isShowingAllOptions, limitToTop, selected, maintainOrder]);

  if (visibleOptions.length === 0) {
    return (
      <div className="PingMultiCheckbox__Unavailable">
        {overrideUnavailableText ? overrideUnavailableText : `${label} data unavailable`}
      </div>
    );
  }
  
  return (
    <div className="PingMultiCheckbox">
      {visibleOptions.map((option) => (
        <PingCheckboxInput
          key={option.value}
          label={option.label}
          secondaryLabel={option.perc || ""}
          name={option.value.toString()}
          isChecked={selected?.includes(option.value.toString())}
          onChange={(_, isChecked) => {
            if (isChecked) {
              onChange([...selected, option.value.toString()]);
            } else {
              onChange(selected.filter((v) => v !== option.value.toString()));
            }
          }}
        />
      ))}
      {limitToTop && options?.length > limitToTop && (
        <button
          className="PingMultiCheckbox__ShowMoreButton"
          onClick={() => setIsShowingAllOptions(!isShowingAllOptions)}
        >
          <div>
            {isShowingAllOptions
              ? "Less options"
              : "More options"}
          </div>
          <PingMaterialIcon
            className="PingMultiCheckbox__ShowMoreButton__Icon"
            iconName={isShowingAllOptions ? "expand_less" : "expand_more"}
          />
        </button>
      )}
    </div>
  );
};
