import { useEffect } from "react";
import { useDispatch, Provider } from "react-redux";

import {
  useHistory,
  useLocation,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  setAccessToken,
  RouterContext,
  RootWrapper,
  useAuth,
  PingToaster,
} from "@repo/ping-react-js";

import { setupStore } from "services/store";
import { useGetSettingsQuery } from "services/pvSlice";
import { setIsEmployee } from "reducers/settings";
import { useAppSelector } from "utils/redux";
import { NotFoundPage } from "features/misc-pages";
import { FRONT_END_BASE_URL } from "constants/index";
import { VersionChecker } from "./VersionChecker";
import { SubmissionsQueryGlobalProvider } from "../contexts/SubmissionsQueryGlobalContext.tsx";

import PingVisionSubmissionDashboard from "features/submission-dashboard/PingVisionSubmissionDashboard";

const store = setupStore();

// Wrapper component to check team access.
const TeamAccessCheck: React.FC<{
  teamName: string;
  children: React.ReactNode;
}> = ({ teamName, children }) => {
  useGetSettingsQuery({});

  const settings = useAppSelector((state) => state.settings.settings);

  const teams = settings?.teams?.map((t) => t.team_name) || [];

  if (!teams?.length) {
    return null;
  }

  const modifiedTeamName = teamName?.replace(/-/g, " ");

  // Check if user has access to this team

  const hasTeamAccess = teams?.some((team) => team === modifiedTeamName);

  if (!hasTeamAccess) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};

const PingVisionRoutes = () => {
  const { accessToken, ssoUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      dispatch(setAccessToken(accessToken));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    dispatch(
      setIsEmployee(ssoUser?.email?.includes("@pingintel.com") ?? false),
    );
  }, [dispatch, ssoUser]);

  // We want to wait until the `accessToken` is set in redux before rendering
  // the routes. Otherwise, the `prepareHeaders()` function in
  // `fetchBaseQuery()` will not have the `accessToken` available to set the
  // `Authorization` header.
  const reduxAccessToken = useAppSelector((state) => state.auth.accessToken);
  if (!reduxAccessToken) {
    return null;
  }

  /* All new route patterns should be accounted for in the Django backend, in <pingvision />
   We want to catch invalid routes at the server level first
   where we can. This prevents unnecessary loading of client bundles, requests from the frontend, and web socket
   establishment. 

   We do /?$ for an optional trailing slash in Python/Django regex. In TS/React, for various (temporary) reasons, we are right
   now duplicating each route, with the slash and without the slash. 
   
   Details follow - 

   For whatever reason, the default strict false parameter is not working here. That normally guarantees that slashes are 
   considered as part of the Route path parameter.

   Also, we have dated React router packages and type mismatches. Also, in react-router-dom v6 (we are currently on v5), there won't 
   even be regex support and various parameters here like slug, team name, etc, use that.

   A good solution would be to make a wrapper route component when we upgrade react-router-dom - that should handle any regex for route params
   and prevent the duplication of routes for slashes. 
   */
  return (
    <RouterContext.Provider value={{ location, history }}>
      <Switch>
        {/* first route, with and without slash. Also matches my-issues. */}
        <Route path={`${FRONT_END_BASE_URL}/:slug([a-zA-Z-]+)`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        <Route path={`${FRONT_END_BASE_URL}/:slug([a-zA-Z-]+)/`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        {/* second route, with and without slash */}
        <Route path={`${FRONT_END_BASE_URL}/i/:pingId([0-9a-zA-Z-]+)`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        <Route path={`${FRONT_END_BASE_URL}/i/:pingId([0-9a-zA-Z-]+)/`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        {/* third route, with and without slash */}
        <Route path={`${FRONT_END_BASE_URL}/custom-views`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        <Route path={`${FRONT_END_BASE_URL}/custom-views/`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        {/* fourth route, with and without slash */}
        <Route
          path={`${FRONT_END_BASE_URL}/custom-views/:slug([0-9a-zA-Z-]+)`}
          exact
        >
          <PingVisionSubmissionDashboard />
        </Route>

        <Route
          path={`${FRONT_END_BASE_URL}/custom-views/:slug([0-9a-zA-Z-]+)/`}
          exact
        >
          <PingVisionSubmissionDashboard />
        </Route>
        {/* fifth route, with and without slash */}
        <Route
          path={`/teams/:teamName/team-members`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />
        <Route
          path={`/teams/:teamName/team-members/`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />

        {/* sixth route, with and without slash */}
        <Route
          path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />
        <Route
          path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)/`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />

        {/* seventh route, with and without slash */}
        <Route
          path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)/:pingId([0-9a-zA-Z-]+)`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />
        <Route
          path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)/:pingId([0-9a-zA-Z-]+)/`}
          exact
          render={({ match }) => (
            <TeamAccessCheck teamName={match.params.teamName}>
              <PingVisionSubmissionDashboard />
            </TeamAccessCheck>
          )}
        />

        {/* last two routes, home page, and not found */}
        <Route path="/">
          <Redirect to={`${FRONT_END_BASE_URL}/my-issues`} />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </RouterContext.Provider>
  );
};

const Root = () => {
  return (
    <RootWrapper VITE_APP_AUTH_URL={import.meta.env.VITE_APP_AUTH_URL}>
      <Provider store={store}>
        <BrowserRouter>
          <SubmissionsQueryGlobalProvider>
            <PingToaster position="bottom-right" />
            <PingVisionRoutes />
            <VersionChecker />
          </SubmissionsQueryGlobalProvider>
        </BrowserRouter>
      </Provider>
    </RootWrapper>
  );
};

export default Root;
