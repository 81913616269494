import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "services/pvSlice.ts";

/**
 * Tracks workflow status updates in the application.
 *
 * This slice is responsible for tracking changes to submission workflow statuses,
 * particularly those made by the current user. This allows the UI to reflect these
 * changes immediately without prematurely invalidating the API cache.
 */

/**
 * Represents a single update to a submission's workflow status.
 * Contains information about which submission was updated, when, and by whom.
 */
interface WorkflowUpdate {
  /** Unique identifier for the submission that was updated */
  submissionId: string;
  /** Timestamp when the update occurred (in milliseconds since epoch) */
  timestamp: number;
  /** ID of the new workflow status that was applied */
  workflowStatusId: number;
  /** ID of the user who made the workflow status change */
  userId: number;
}

/**
 * State structure for the workflow updates slice.
 * Maintains a history of recent workflow status changes and related metadata.
 */
interface WorkflowUpdatesState {
  /** List of recent workflow updates, ordered by most recent first */
  recentUpdates: WorkflowUpdate[];
  /** Maximum number of updates to store in memory before pruning old ones */
  maxUpdates: number;
  /** Arguments from the last submission query, used to determine when to clear updates */
  lastQueryArgs?: any;
}

/**
 * Initial state for the workflow updates slice.
 */
const initialState: WorkflowUpdatesState = {
  recentUpdates: [], // Start with empty updates list
  maxUpdates: 2000, // Store up to 2000 recent updates before pruning
  lastQueryArgs: null, // No previous query args on initialization
};

/**
 * Redux slice for managing workflow status updates throughout the application.
 * Handles adding new updates, clearing updates, and synchronizing with submission queries.
 */
export const workflowUpdatesSlice = createSlice({
  name: "workflowUpdates",
  initialState,
  reducers: {
    /**
     * Adds a new workflow status update to the state.
     * If an update for the same submission already exists, it's replaced with the new one.
     * Maintains the list size by pruning old updates when exceeding maxUpdates.
     *
     * @param state - Current state of the slice
     * @param action - Action containing the new workflow update
     */
    addWorkflowUpdate: (state, action: PayloadAction<WorkflowUpdate>) => {
      // Check if this submission already has an update in the list
      const existingIndex = state.recentUpdates.findIndex(
        (update) => update.submissionId === action.payload.submissionId,
      );

      // Remove the existing update if found to avoid duplicates
      if (existingIndex !== -1) {
        state.recentUpdates.splice(existingIndex, 1);
      }

      // Add the new update at the beginning of the array (most recent first)
      state.recentUpdates.unshift(action.payload);

      // Prune the list if it exceeds the maximum size to prevent memory issues
      if (state.recentUpdates.length > state.maxUpdates) {
        state.recentUpdates = state.recentUpdates.slice(0, state.maxUpdates);
      }
    },
    /**
     * Clears all workflow updates from the state.
     * Used when a complete reset is needed, such as when logging out or changing views.
     *
     * @param state - Current state of the slice
     */
    clearWorkflowUpdates: (state) => {
      state.recentUpdates = [];
    },
  },
  /**
   * Extra reducers to handle side effects from other slices and API calls.
   */
  extraReducers: (builder) => {
    /**
     * Handles the successful completion of a getSubmissions query.
     * Clears workflow updates when query parameters (other than cursor) have changed.
     * This prevents showing outdated workflow status information when filter criteria change.
     */
    builder.addMatcher(
      api.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        // Extract the current query arguments from the action
        const currentArgs = action.meta.arg.originalArgs;

        // Get the previous query arguments stored in state
        const prevArgs = state.lastQueryArgs;

        // Handle first request case
        if (!prevArgs) {
          state.lastQueryArgs = currentArgs;
          return;
        }

        // Create copies of the args without cursor for comparison
        // This allows pagination to occur without clearing updates
        const currentArgsWithoutCursor = {
          ...currentArgs,
          cursorId: undefined,
        };
        const prevArgsWithoutCursor = { ...prevArgs, cursorId: undefined };

        // Compare the stringified objects to detect non-cursor changes
        // (e.g., filter changes, sort changes, etc.)
        const hasNonCursorChanges =
          JSON.stringify(currentArgsWithoutCursor) !==
          JSON.stringify(prevArgsWithoutCursor);

        // Clear updates only when substantive query parameters have changed
        if (hasNonCursorChanges) {
          state.recentUpdates = [];
        }

        // Store current args for future comparisons
        state.lastQueryArgs = currentArgs;
      },
    );
  },
});

/**
 * Export the action creators for use throughout the application.
 * These actions can be dispatched to add or clear workflow updates.
 */
export const { addWorkflowUpdate, clearWorkflowUpdates } =
  workflowUpdatesSlice.actions;

/**
 * Export the reducer as the default export for use in the Redux store configuration.
 */
export default workflowUpdatesSlice.reducer;
