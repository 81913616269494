import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "services/pvSlice.ts";

/**
 * Interface for the pending submission IDs state.
 * Maintains an array of submission IDs that have not been added to the list
 * because the user hasn't reached the bottom yet. This prevents overcounting
 * of the submission list total when submissions are newly updated or created.
 *
 * The pendingSubmissionIds are automatically cleared in their slice when:
 * - The user reaches the end of the list (has_remaining in the payload becomes false)
 * - Any query parameter changes except for cursor_id (like filters, search terms, or sort order)
 */
interface PendingSubmissionIdsState {
  /**
   * Array of pending submission IDs that haven't been added to the list yet
   * because the user hasn't reached the bottom of the list.
   */
  pendingIds: string[];

  /**
   * Arguments from the last submission query, used to determine when to clear pending IDs.
   * This helps maintain pending IDs during pagination but reset them when other query parameters change.
   */
  lastQueryArgs?: any;
}

/**
 * Initial state for the pending submission IDs slice.
 */
const initialState: PendingSubmissionIdsState = {
  pendingIds: [], // Empty array to start with no pending IDs
  lastQueryArgs: null, // No previous query args on initialization
};

/**
 * Redux slice for managing pending submission IDs throughout the application.
 * This slice tracks which submission IDs should be added to the view when the user
 * loads more data or reaches the bottom of the list.
 * It provides actions to add, clear, and manage pending IDs.
 */
export const pendingSubmissionIdsSlice = createSlice({
  name: "pendingSubmissionIds",
  initialState,
  reducers: {
    /**
     * Adds a submission ID to the pendingIds list.
     * This action is used when a new submission is created but not added to the list
     * because the user hasn't reached the bottom yet.
     * Ensures that no duplicate IDs are added to the pending list.
     *
     * @param state - The current state of the slice
     * @param action - The action containing the submission ID to add to the pending list
     */
    addPendingSubmissionId: (state, action: PayloadAction<string>) => {
      const id = action.payload;

      // Only add the ID if it's not already in the array
      if (!state.pendingIds.includes(id)) {
        state.pendingIds = [...state.pendingIds, id];
      }
    },

    /**
     * Clears all submission IDs from the pendingIds list.
     * This action is used when hasRemainingSovs becomes false, indicating
     * that the user has reached the bottom of the list.
     *
     * @param state - The current state of the slice
     */
    clearPendingSubmissionIds: (state) => {
      state.pendingIds = [];
    },
  },
  /**
   * Extra reducers to handle side effects from other slices and API calls.
   */
  extraReducers: (builder) => {
    /**
     * Handles the successful completion of a getSubmissions query.
     * Clears pending IDs when:
     * 1. hasRemainingSovs becomes false (no cursor_id), indicating the user has reached the bottom of the list
     * 2. Query parameters (other than cursor) have changed, indicating a new search or filter
     */
    builder.addMatcher(
      api.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        // Extract the current query arguments from the action
        const currentArgs = action.meta.arg.originalArgs;

        // Get the previous query arguments stored in state
        const prevArgs = state.lastQueryArgs;

        // If there's no more data to load, clear the pending IDs
        // Check if we've reached the end of the list by checking if there's no cursor_id
        // This indicates there are no more pages to load
        if (!action.payload?.has_remaining) {
          state.pendingIds = [];
        }

        // Handle first request case
        if (!prevArgs) {
          state.lastQueryArgs = currentArgs;
          return;
        }

        // Create copies of the args without cursor for comparison
        // This allows pagination to occur without clearing pending IDs
        const currentArgsWithoutCursor = {
          ...currentArgs,
          cursorId: undefined,
        };
        const prevArgsWithoutCursor = { ...prevArgs, cursorId: undefined };

        // Compare the stringified objects to detect non-cursor changes
        // (e.g., filter changes, sort changes, search changes, etc.)
        const hasNonCursorChanges =
          JSON.stringify(currentArgsWithoutCursor) !==
          JSON.stringify(prevArgsWithoutCursor);

        // Clear pending IDs when substantive query parameters have changed
        if (hasNonCursorChanges) {
          state.pendingIds = [];
        }

        // Store current args for future comparisons
        state.lastQueryArgs = currentArgs;
      },
    );
  },
});

/**
 * Export the action creators for use throughout the application.
 * These actions can be dispatched to add or clear pending submission IDs.
 */
export const { addPendingSubmissionId, clearPendingSubmissionIds } =
  pendingSubmissionIdsSlice.actions;

/**
 * Export the reducer as the default export for use in the Redux store configuration.
 */
export default pendingSubmissionIdsSlice.reducer;
