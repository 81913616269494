interface IconProps extends React.SVGAttributes<SVGElement> {
  className?: string;
  size?: string | number;
  color?: string;
  strokeWidth?: string | number;
}

export const PingAreaIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M7 17L17 7M12 17L17 12M7 12L12 7M6 8L8 6M16 18L18 16M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingHideLandmarkIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M3 22H18M6 18V11M10 18V10M14 18V14M18 11V14M2 2L10 10M22 22L14 14M11.75 7H20L12 2L8.4 4.25M14 14L10 10M7 7H4L5.84615 5.84615"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingElevationProfileIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M14.75 4.5L11.0938 9H7.71875L4.0625 13.5M19 21H6.15146C4.8884 21 4.19081 19.5344 4.98728 18.5541L10.3125 12H13.6875L18.3358 6.27898C19.2264 5.18293 21 5.81264 21 7.22487V19C21 20.1046 20.1046 21 19 21Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Ping3DIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2.5 14.875C2.5 16.4628 4.067 17.75 6 17.75C7.933 17.75 9.5 16.4628 9.5 14.875C9.5 13.2872 8.10137 11.75 6 11.75M6 11.75C8.11235 11.75 9.5 10.3247 9.5 8.875C9.5 7.42525 7.933 6.25 6 6.25C4.067 6.25 2.5 7.42525 2.5 8.875M6 11.75H5M12.75 6.5H16.75C18.0761 6.5 19.3479 7.07946 20.2855 8.11091C21.2232 9.14236 21.75 10.5413 21.75 12C21.75 13.4587 21.2232 14.8576 20.2855 15.8891C19.3479 16.9205 18.0761 17.5 16.75 17.5H12.75V6.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const PingPlaceholderIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M15 18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18C9 16.3431 10.3431 15 12 15C13.6569 15 15 16.3431 15 18Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M18 9C19.6569 9 21 10.3431 21 12C21 13.6569 19.6569 15 18 15C16.3431 15 15 13.6569 15 12C15 10.3431 16.3431 9 18 9Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M6 9C7.65685 9 9 10.3431 9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const PingElevationIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M11.75 20.25H20.25M11.75 3.75L20.25 3.75M11.75 7.75L16.25 7.75M11.75 16.25H16.25M11.75 12H20.25M5.25 5.75C6.07843 5.75 6.75 5.07843 6.75 4.25C6.75 3.42157 6.07843 2.75 5.25 2.75C4.42157 2.75 3.75 3.42157 3.75 4.25C3.75 5.07843 4.42157 5.75 5.25 5.75ZM5.25 5.75L5.25 18.25M5.25 18.25C4.42157 18.25 3.75 18.9216 3.75 19.75C3.75 20.5784 4.42157 21.25 5.25 21.25C6.07843 21.25 6.75 20.5784 6.75 19.75C6.75 18.9216 6.07843 18.25 5.25 18.25Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingLocationIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M8 16.0142C5.32211 16.393 3.5 17.1402 3.5 18C3.5 19.2426 7.30558 20.25 12 20.25C16.6944 20.25 20.5 19.2426 20.5 18C20.5 17.1402 18.6779 16.393 16 16.0142M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12ZM12 12V17"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PingAccuracyIconLow = ({
  className,
  size = 24,
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16"
        stroke="#D52B50"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconMedium = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12"
        stroke="#F6AE1F"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconHigh = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8"
        stroke="#1FAF7B"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconVeryHigh = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#1FAF7B"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingNotGeocodedIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4866 11.9995L9.15329 2.66617C9.037 2.46097 8.86836 2.29029 8.66457 2.17154C8.46078 2.0528 8.22915 1.99023 7.99329 1.99023C7.75743 1.99023 7.52579 2.0528 7.322 2.17154C7.11822 2.29029 6.94958 2.46097 6.83329 2.66617L1.49995 11.9995C1.38241 12.2031 1.32077 12.4341 1.32129 12.6692C1.32181 12.9042 1.38447 13.135 1.50292 13.338C1.62136 13.5411 1.79138 13.7092 1.99575 13.8254C2.20011 13.9415 2.43156 14.0016 2.66662 13.9995H13.3333C13.5672 13.9993 13.797 13.9375 13.9995 13.8204C14.202 13.7032 14.3701 13.5349 14.487 13.3322C14.6038 13.1296 14.6653 12.8998 14.6653 12.6658C14.6652 12.4319 14.6036 12.2021 14.4866 11.9995Z"
        fill="#FF667D"
      />
      <path
        d="M8 6V8.66667M8 11.3333H8.00667"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingGeocodedIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99968 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99968C14.6663 4.31778 11.6816 1.33301 7.99968 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99968C1.33301 11.6816 4.31778 14.6663 7.99968 14.6663Z"
        fill="#A1DCBD"
      />
      <path
        d="M5.99967 7.99968L7.33301 9.33301L9.99967 6.66634"
        fill="#A1DCBD"
      />
      <path
        d="M6 8.00033L7.33333 9.33366L10 6.66699"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingHeatmapIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M4.66659 14.6663C6.50753 14.6663 7.99992 13.174 7.99992 11.333C7.99992 9.49206 6.50753 7.99967 4.66659 7.99967C2.82564 7.99967 1.33325 9.49206 1.33325 11.333C1.33325 13.174 2.82564 14.6663 4.66659 14.6663Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 9.99967C13.4727 9.99967 14.6666 8.80577 14.6666 7.33301C14.6666 5.86025 13.4727 4.66634 11.9999 4.66634C10.5272 4.66634 9.33325 5.86025 9.33325 7.33301C9.33325 8.80577 10.5272 9.99967 11.9999 9.99967Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 5.33301C6.43782 5.33301 7.33325 4.43758 7.33325 3.33301C7.33325 2.22844 6.43782 1.33301 5.33325 1.33301C4.22868 1.33301 3.33325 2.22844 3.33325 3.33301C3.33325 4.43758 4.22868 5.33301 5.33325 5.33301Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
