import { designTokens, TokenDef } from "./design-tokens";

const tokenDefKeyToCssVarName = (
  tokenDef: TokenDef,
  key: keyof typeof tokenDef.values
) => {
  return `--ping-${tokenDef.cssPrefix}-${key}`;
};

const tokenDefKeyToCssVar = (
  tokenDef: TokenDef,
  key: keyof typeof tokenDef.values
) => {
  return `var(${tokenDefKeyToCssVarName(tokenDef, key)})`;
};

const tokenDefToCssVars = (tokenDef: TokenDef) => {
  return Object.entries(tokenDef.values).map(
    ([key, value]) => `${tokenDefKeyToCssVarName(tokenDef, key)}: ${value};\n`
  );
};

const designTokenCssVars = designTokens
  .map((t) => tokenDefToCssVars(t))
  .flat()
  .join("");

const designTokensCssDecl = `:root {
    ${designTokenCssVars}
    }`;

export { tokenDefKeyToCssVar, designTokensCssDecl };
