import cx from "classnames";

import "./Heading.scss";

type HeadingProps = {
  level?: 1 | 2 | 3;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  children?: React.ReactNode;
  className?: string;
};

const Heading: React.FC<HeadingProps> = ({
  level = 1,
  size = "lg",
  children,
  className,
}) => {
  const appliedClasses = cx(className, "Heading", {
    "Heading--ExtraSmall": size === "xs",
    "Heading--Small": size === "sm",
    "Heading--Medium": size === "md",
    "Heading--Large": size === "lg",
    "Heading--ExtraLarge": size === "xl",
    "Heading--DoubleExtraLarge": size === "2xl",
  });
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
  return <HeadingTag className={appliedClasses}>{children}</HeadingTag>;
};

export { Heading };
