import { DOCUMENT_TYPE } from "ts-types/DataTypes";

export type DocumentType = Exclude<
  keyof typeof DOCUMENT_TYPE,
  "EMAIL_BODY_HTML" | "EMAIL_BODY_TXT"
>;

export const DOCUMENT_TYPES: DocumentType[] = Object.keys(DOCUMENT_TYPE).filter(
  (type) => type !== "EMAIL_BODY_HTML" && type !== "EMAIL_BODY_TXT",
) as DocumentType[];

export const excludedConvertTypes = [
  "SOVFIXER_JSON",
  "SOVFIXER_SCRUBBER",
  "SOVFIXER_OUTPUT",
  "EML",
];
