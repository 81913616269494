import { forwardRef, useEffect, useRef, useState } from "react";
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  useListNavigation,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from "@floating-ui/react";
import cx from "classnames";

import "./PingContextMenu.scss";

// not a keyboard accessible component, copied over from maps directory

const PingContextMenuItemButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
    labelContent?: React.ReactNode;
    disabled?: boolean;
    hasExtraPadding?: boolean;
  }
>(({ label, labelContent, disabled, hasExtraPadding, ...props }, ref) => {
  const appliedClasses = cx("PingContextMenuItem", {
    "PingContextMenuItem--HasExtraPadding": hasExtraPadding,
  });
  return (
    <button
      {...props}
      className={appliedClasses}
      ref={ref}
      role="menuitem"
      disabled={disabled}
    >
      {labelContent || label}
    </button>
  );
});

const PingContextMenuItemLink = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    label: string;
    labelContent?: React.ReactNode;
    hasExtraPadding?: boolean;
  }
>(({ label, labelContent, hasExtraPadding, ...props }, ref) => {
  const appliedClasses = cx("PingContextMenuItem", {
    "PingContextMenuItem--HasExtraPadding": hasExtraPadding,
  });
  return (
    <a {...props} className={appliedClasses} ref={ref} role="menuitem">
      {labelContent || label}
    </a>
  );
});

const PingContextMenuItemSectionTitle = ({ label }: { label: string }) => {
  return <div className="PingContextMenuItemSectionTitle">{label}</div>;
};

type PingContextMenuItem = {
  label: string;
  labelContent?: React.ReactNode;
  isSectionTitle?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

type PingContextMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  location: { x: number; y: number };
  setLocation: (location: { x: number; y: number }) => void;
  items: PingContextMenuItem[];
  useWhiteBackground?: boolean;
};

export const PingContextMenu = forwardRef<
  HTMLButtonElement,
  PingContextMenuProps & React.HTMLProps<HTMLButtonElement>
>(
  (
    { isOpen, setIsOpen, location, items, useWhiteBackground = false },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _forwardedRef,
  ) => {
    // We keep a copy of this variable locally so we can have more control over
    // when the menu opens.
    const [localIsOpen, setLocalIsOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);

    const { refs, floatingStyles, context } = useFloating({
      open: localIsOpen,
      onOpenChange: setIsOpen,
      middleware: [
        offset({ mainAxis: 5, alignmentAxis: 4 }),
        flip({
          fallbackPlacements: ["left-start"],
        }),
        shift({ padding: 10 }),
      ],
      placement: "right-start",
      strategy: "fixed",
      whileElementsMounted: autoUpdate,
    });

    const role = useRole(context, { role: "menu" });
    const dismiss = useDismiss(context);
    const listNavigation = useListNavigation(context, {
      listRef: listItemsRef,
      onNavigate: setActiveIndex,
      activeIndex,
    });

    const { getFloatingProps, getItemProps } = useInteractions([
      role,
      dismiss,
      listNavigation,
    ]);

    // const handleContextMenu = useCallback((event: MouseEvent) => {
    //   event.preventDefault();
    //   const newLocation = { x: event.clientX, y: event.clientY };
    //   setLocation(newLocation);
    //   setIsOpen(true);
    // }, [setLocation, setIsOpen]);

    // useEffect(() => {
    //   document.addEventListener('contextmenu', handleContextMenu);
    //   return () => {
    //     document.removeEventListener('contextmenu', handleContextMenu);
    //   };
    // }, [handleContextMenu]);

    // Sync location and open state with our local state.
    useEffect(() => {
      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: location.x,
            y: location.y,
            top: location.y,
            right: location.x,
            bottom: location.y,
            left: location.x,
          };
        },
      });

      setLocalIsOpen(isOpen);
    }, [refs, location, isOpen]);

    const hasSections = items.some((i) => i.isSectionTitle);

    return (
      <FloatingPortal>
        {localIsOpen && (
          <FloatingOverlay lockScroll>
            <FloatingFocusManager
              context={context}
              initialFocus={refs.floating}
            >
              <div
                className={cx("PingContextMenu", {
                  "PingContextMenu--white-bg": useWhiteBackground,
                })}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                {items.map((item, index) => {
                  if (!item) return null;

                  const floatingUiProps = getItemProps({
                    tabIndex: activeIndex === index ? 0 : -1,
                    ref(node: HTMLButtonElement) {
                      listItemsRef.current[index] = node;
                    },
                    onClick() {
                      item.onClick?.(null);
                      setIsOpen(false);
                    },
                    onMouseUp() {
                      item.onClick?.(null);
                      setIsOpen(false);
                    },
                  });

                  const allProps = { ...floatingUiProps, ...item };

                  if (item.href) {
                    return (
                      <PingContextMenuItemLink
                        key={item.label}
                        hasExtraPadding={hasSections}
                        {...allProps}
                      />
                    );
                  } else if (item.onClick) {
                    return (
                      <PingContextMenuItemButton
                        key={item.label}
                        hasExtraPadding={hasSections}
                        {...allProps}
                      />
                    );
                  } else if (item.isSectionTitle) {
                    return (
                      <PingContextMenuItemSectionTitle
                        key={item.label}
                        label={item.label}
                      />
                    );
                  }
                })}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    );
  },
);
