import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import classNames from "classnames";
import {
  PingLucideIcon,
  PingPdfPreview,
  PingEmailPreview,
  PingCircleIconButton,
  SiteHeaderActionButton,
} from "@repo/ping-react-js";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { DOCUMENT_TYPE, SovDataType } from "ts-types/DataTypes";
import {
  setPreviewPdf,
  setClearPreview,
  setPreviewEmail,
} from "reducers/inbox";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { getApiBaseUrl } from "src/utils";
import { useGetEmailCorrespondenceQuery } from "services/pvSlice";
import { FileChangeModal } from "components/modals";
import {
  initiateDownload,
  formatLongDate,
  CheckboxInput,
  PingContextMenu,
} from "@repo/ping-react-js";
import { useAuth } from "ping-vision-client/src/utils/hooks.ts";
import { usePreviewContext } from "./PVDocumentsPreviewContext";
import "./PVAllDocumentsPreviewPanel.scss";

import { DocumentType, DOCUMENT_TYPES } from "./types";

type PVAllDocumentsPreviewPanelProps = {
  selectedItem: SovDataType;
  setInitialPreviewType?: Dispatch<SetStateAction<DocumentType | null>>;
  onSetShowArchived?: Dispatch<SetStateAction<boolean>>;
  onCollapseExpand?: () => void;
  initialPreviewType?: DocumentType | null;
  initialShowArchived?: boolean;
};

export const PVAllDocumentsPreviewPanel: FC<PVAllDocumentsPreviewPanelProps> =
  React.memo(({ selectedItem }) => {
    const dispatch = useAppDispatch();
    const documents = useMemo(
      () => selectedItem?.documents || [],
      [selectedItem],
    );
    const {
      activeType,
      showArchived,
      isCollapsed,
      setActiveType,
      setShowArchived,
      setIsCollapsed,
    } = usePreviewContext();
    const [showOtherFiles, setShowOtherFiles] = useState<boolean>(true);
    const [hoveredDocument, setHoveredDocument] = useState<number | null>(null);
    const fileItemRefs = useRef<{ [key: number]: HTMLButtonElement | null }>(
      {},
    );
    const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<
      (typeof documents)[0] | null
    >(null);

    const documentsByType = useMemo(() => {
      return DOCUMENT_TYPES.reduce(
        (acc, type) => {
          const filteredDocuments = documents.filter(
            (d) => d.document_type === type && !d.is_archived,
          );
          if (filteredDocuments.length > 0) {
            acc[type] = filteredDocuments;
          }
          return acc;
        },
        {} as Record<DocumentType, typeof documents>,
      );
    }, [documents]);

    const archivedCount = useMemo(() => {
      return documents.filter((d) => d.is_archived).length;
    }, [documents]);

    const archivedDocumentsByType = useMemo(() => {
      return DOCUMENT_TYPES.reduce(
        (acc, type) => {
          const filteredDocuments = documents.filter(
            (d) => d.document_type === type && d.is_archived,
          );
          if (filteredDocuments.length > 0) {
            acc[type] = filteredDocuments;
          }
          return acc;
        },
        {} as Record<DocumentType, typeof documents>,
      );
    }, [documents]);

    const activeDocumentsPerType = useMemo(
      () => (activeType ? documentsByType[activeType] || [] : []),
      [activeType, documentsByType],
    );

    const { accessToken: authAccessToken } = useAuth();

    const documentPreviewType = useAppSelector(
      (state) => state.inbox.documentPreview?.type,
    );

    const documentPreviewUrl = useAppSelector((state) =>
      state.inbox.documentPreview?.type === "PDF"
        ? state.inbox.documentPreview?.url
        : null,
    );

    const previewedDocument = useMemo(
      () => documents.find((d) => d.preview_url === documentPreviewUrl),
      [documents, documentPreviewUrl],
    );

    const downloadUrl = previewedDocument?.preview_url
      ? new URL(
          previewedDocument?.preview_url || "",
          getApiBaseUrl(),
        ).toString()
      : null;

    const { data: email, isFetching } = useGetEmailCorrespondenceQuery(
      { sovid: selectedItem.id },
      { skip: documentPreviewType !== "EMAIL" },
    );

    const documentOptions = useMemo(
      () => ({
        httpHeaders: {
          Authorization: `Bearer ${authAccessToken}`,
        },
      }),
      [authAccessToken],
    );

    const handleSetActiveType = useCallback(
      (type: DocumentType | null) => {
        if (isCollapsed) {
          setIsCollapsed(false);
        }

        setShowArchived(false);
        setActiveType(type);

        dispatch(setClearPreview());

        setShowOtherFiles(false);

        if (type) {
          const currentActiveDocuments = documentsByType[type];
          const firstDoc = currentActiveDocuments?.[0];

          if (firstDoc?.document_type !== "EML" && firstDoc?.preview_url) {
            dispatch(setPreviewPdf(firstDoc?.preview_url));
          } else if (type === "EML") {
            dispatch(setPreviewEmail(selectedItem.id));
          }
        }
      },
      [
        isCollapsed,
        setIsCollapsed,
        setShowArchived,
        setShowOtherFiles,
        documentsByType,
        setActiveType,
        dispatch,
        selectedItem,
      ],
    );

    const handlePreviewClick = useCallback(
      (doc: (typeof documents)[0]) => {
        if (doc.preview_url) {
          dispatch(setPreviewPdf(doc.preview_url));
        }
      },
      [dispatch],
    );

    const onClosePreview = useCallback(() => {
      dispatch(setClearPreview());
      handleSetActiveType(null);
    }, [dispatch, handleSetActiveType]);

    const toggleShowOtherFiles = () => {
      setShowOtherFiles((prev) => !prev);
    };

    const handleOpenFilesUpdateModal = useCallback(
      (filename: string) => {
        const doc = documents.find((doc) => doc.filename === filename);
        setSelectedDocument(doc || null);
        setIsChangeModalOpen(true);
      },
      [documents],
    );

    const handleFileUpdate = useCallback(
      (newFileName: string, newDocumentType: DocumentType) => {
        // both file name and document type are returned by the modal, and are the previous values if they haven't changed

        if (newDocumentType && newFileName) {
          setActiveType(newDocumentType as DocumentType);
          if (previewedDocument?.id !== selectedDocument?.id) {
            dispatch(setClearPreview());
          }
        }
        setShowOtherFiles(false);
        setSelectedDocument(null);
      },
      [setActiveType, previewedDocument, dispatch, selectedDocument],
    );

    const handleDownload = useCallback(
      (downloadUrl: string, filename: string) => {
        initiateDownload(downloadUrl, authAccessToken, filename);
      },
      [authAccessToken],
    );

    const handleShowArchived = useCallback(() => {
      dispatch(setClearPreview());
      setActiveType(null);
      setShowArchived(true);
    }, [dispatch, setActiveType, setShowArchived]);

    const handleClosePanel = () => {
      setIsCollapsed(true);
      setActiveType(null);
      setShowArchived(false);
    };

    const formatArchivedReason = (reason: string) => {
      const words = reason.toLowerCase().split("_");
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      return words.join(" ");
    };

    const previousSelectedItemId = useRef<string | null>(null);

    useEffect(() => {
      if (
        selectedItem?.id &&
        selectedItem.id !== previousSelectedItemId.current &&
        !isCollapsed &&
        !activeType
      ) {
        previousSelectedItemId.current = selectedItem.id;
        if (Object.keys(documentsByType).includes("EML")) {
          handleSetActiveType("EML");
        } else if (Object.keys(documentsByType).includes("SOV")) {
          handleSetActiveType("SOV");
        }
      }
    }, [
      selectedItem.id,
      documentsByType,
      handleSetActiveType,
      isCollapsed,
      activeType,
    ]);

    // Structure of template follows as below -
    // Pills header, present both in collapsed and not collapsed view.
    // For not archived and not email view, files per document type. If this is showing, archived view isn't.
    // Archived view, with files per document type. If this is showing, the regular files per document view isn't.
    // Previews, only one at a time. Can be email or pdf. Right now not enabled for archived files

    return (
      <div className="PVAllDocumentsPreviewPanel">
        <div
          className={classNames("PVAllDocumentsPreviewPanel__header", {
            "PVAllDocumentsPreviewPanel__header--no-border": isCollapsed,
          })}
        >
          <div className="PVAllDocumentsPreviewPanel__pills">
            {DOCUMENT_TYPES.map((type) => {
              const count = documentsByType[type]?.length || 0;
              if (count === 0) return null;

              return (
                <button
                  key={type}
                  className={classNames("PVAllDocumentsPreviewPanel__pill", {
                    "PVAllDocumentsPreviewPanel__pill--active":
                      activeType === type,
                  })}
                  onClick={() => handleSetActiveType(type)}
                >
                  {type === "EML" ? "EMAIL" : type.replace(/_/g, " ")}
                  <span className="PVAllDocumentsPreviewPanel__pill-count">
                    {count}
                  </span>
                </button>
              );
            })}
            {archivedCount !== 0 && (
              <button
                className={classNames("PVAllDocumentsPreviewPanel__pill", {
                  "PVAllDocumentsPreviewPanel__pill--active": showArchived,
                })}
                onClick={() => handleShowArchived()}
              >
                ARCHIVED
                <span className="PVAllDocumentsPreviewPanel__pill-count">
                  {archivedCount}
                </span>
              </button>
            )}
          </div>
          {!isCollapsed && (
            <PingCircleIconButton
              className="PVAllDocumentsPreviewPanel__close-button"
              aria-label="Close Documents Preview Panel"
              iconName="close"
              onClick={handleClosePanel}
            />
          )}
        </div>

        {!isCollapsed && (
          <div className="PVAllDocumentsPreviewPanel__content">
            {activeType !== "EML" && !showArchived && (
              <div className="PVAllDocumentsPreviewPanel__files-section">
                {activeDocumentsPerType.length > 1 && documentPreviewType && (
                  <div
                    className={`PVAllDocumentsPreviewPanel__toggle-files-button ${showOtherFiles ? "PVAllDocumentsPreviewPanel__toggle-files-button--increased-margin-class" : ""}`}
                  >
                    <CheckboxInput
                      label={
                        showOtherFiles
                          ? `Hide Other ${activeDocumentsPerType.length - 1} ${activeDocumentsPerType.length === 2 ? "File" : "Files"}`
                          : `Show Other ${activeDocumentsPerType.length - 1} ${activeDocumentsPerType.length === 2 ? "File" : "Files"}`
                      }
                      name="toggleShowOtherFiles"
                      isChecked={showOtherFiles}
                      onChange={() => toggleShowOtherFiles()}
                    />
                  </div>
                )}

                <ul className="PVAllDocumentsPreviewPanel__documents">
                  {activeDocumentsPerType.map((doc) => {
                    if (
                      !documentPreviewType ||
                      doc.preview_url === documentPreviewUrl ||
                      activeDocumentsPerType.length === 1 ||
                      showOtherFiles
                    ) {
                      return (
                        <li key={doc.preview_url || doc.url}>
                          <button
                            ref={(el) => {
                              fileItemRefs.current[doc.id] = el;
                            }}
                            className={classNames(
                              "PVAllDocumentsPreviewPanel__document",
                              {
                                "PVAllDocumentsPreviewPanel__document--active":
                                  previewedDocument?.preview_url &&
                                  previewedDocument?.preview_url ===
                                    doc.preview_url,
                              },
                            )}
                            onClick={() =>
                              hoveredDocument !== doc.id &&
                              setHoveredDocument(doc.id)
                            }
                            onMouseEnter={() =>
                              hoveredDocument !== doc.id &&
                              setHoveredDocument(doc.id)
                            }
                            onMouseLeave={() =>
                              hoveredDocument === doc.id &&
                              setHoveredDocument(null)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Escape") {
                                setHoveredDocument(null);
                              } else if (
                                e.key === "Tab" &&
                                hoveredDocument !== doc.id
                              ) {
                                setHoveredDocument(doc.id);
                              }
                            }}
                          >
                            {hoveredDocument === doc.id && (
                              <PingContextMenu
                                isOpen={hoveredDocument === doc.id}
                                setIsOpen={(isOpen) =>
                                  setHoveredDocument(isOpen ? doc.id : null)
                                }
                                location={{
                                  x:
                                    fileItemRefs.current[
                                      doc.id
                                    ]?.getBoundingClientRect().left || 0,
                                  y:
                                    (fileItemRefs.current[
                                      doc.id
                                    ]?.getBoundingClientRect().bottom || 0) + 8,
                                }}
                                setLocation={() => {}}
                                useWhiteBackground={true}
                                items={[
                                  ...(doc.preview_url
                                    ? [
                                        {
                                          label: "preview",
                                          labelContent: (
                                            <div className="menu-item-content">
                                              <PingLucideIcon
                                                iconName="Eye"
                                                size={16}
                                              />
                                              <span>Preview</span>
                                            </div>
                                          ),
                                          onClick: () =>
                                            handlePreviewClick(doc),
                                        },
                                      ]
                                    : []),
                                  {
                                    label: "download",
                                    labelContent: (
                                      <div className="menu-item-content">
                                        <PingLucideIcon
                                          iconName="Download"
                                          size={16}
                                        />
                                        <span>Download</span>
                                      </div>
                                    ),
                                    onClick: () =>
                                      handleDownload(
                                        doc?.preview_url || doc?.url,
                                        doc.filename,
                                      ),
                                  },
                                  {
                                    label: "update",
                                    labelContent: (
                                      <div className="menu-item-content">
                                        <PingLucideIcon
                                          iconName="Edit"
                                          size={16}
                                        />
                                        <span>Update</span>
                                      </div>
                                    ),
                                    onClick: () =>
                                      handleOpenFilesUpdateModal(doc.filename),
                                  },
                                ]}
                              />
                            )}
                            <PingLucideIcon
                              iconName={
                                doc.document_type === "EML" ? "Mail" : "File"
                              }
                              size={16}
                            />
                            <div>{doc.filename}</div>
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            )}

            {Object.keys(archivedDocumentsByType).length > 0 &&
              showArchived &&
              Object.entries(archivedDocumentsByType).map(
                ([type, documents]) => (
                  <section
                    key={type}
                    className="PVAllDocumentsPreviewPanel__archived-files-section"
                  >
                    <header className="PVAllDocumentsPreviewPanel__archived-type-header">
                      {type.replace(/_/g, " ")}
                    </header>
                    <ul className="PVAllDocumentsPreviewPanel__archived-documents">
                      {documents.map((doc) => (
                        <li
                          key={doc.url}
                          className="PVAllDocumentsPreviewPanel__archived-document"
                        >
                          <div className="PVAllDocumentsPreviewPanel__archived-document__content">
                            <span className="PVAllDocumentsPreviewPanel__archived-document__filename">
                              <span>{doc.filename}</span>
                              <SiteHeaderActionButton
                                className={
                                  "PVAllDocumentsPreviewPanel__archived-document__download-button"
                                }
                                title="Download File"
                                iconName="download_file"
                                tooltipPlacement="left"
                                onClick={() =>
                                  handleDownload(doc.url, doc.filename)
                                }
                              />
                            </span>

                            {doc.archived_on && (
                              <span className="PVAllDocumentsPreviewPanel__archived-document__metadata">
                                Archived Date: {formatLongDate(doc.archived_on)}
                              </span>
                            )}
                            {doc.archived_reason && (
                              <span className="PVAllDocumentsPreviewPanel__archived-document__metadata">
                                Archived Reason:{" "}
                                {formatArchivedReason(doc.archived_reason)}
                              </span>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </section>
                ),
              )}

            {documentPreviewType && (
              <div className="PVAllDocumentsPreviewPanel__preview">
                {documentPreviewType === "PDF" && downloadUrl && (
                  <PingPdfPreview
                    fileName={selectedDocument?.filename || ""}
                    downloadUrl={downloadUrl}
                    documentOptions={documentOptions}
                    onClose={onClosePreview}
                  />
                )}
                {documentPreviewType === "EMAIL" && !isFetching && email && (
                  <PingEmailPreview content={email} onClose={onClosePreview} />
                )}
                {documentPreviewType === "EMPTY" && (
                  <div className="PVAllDocumentsPreviewPanel__empty">
                    <PingCircleIconButton
                      aria-label="Close Document Preview"
                      iconName="close"
                      onClick={onClosePreview}
                    />
                    <PVEmptyPanelMessage
                      className="PVAllDocumentsPreviewPanel__empty__message"
                      message="Select an attachment to preview from the attachments list"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <FileChangeModal
          isOpen={isChangeModalOpen}
          onClose={() => setIsChangeModalOpen(false)}
          onClickOk={handleFileUpdate}
          currentFileName={selectedDocument?.filename || ""}
          pingId={selectedItem.id}
          currentDocumentType={selectedDocument?.document_type as DocumentType}
        />
      </div>
    );
  });
