import { FC, useCallback, useState, useEffect } from "react";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatCurrency } from "utils/formatters";
import { LucideIconName, PingButton } from "@repo/ping-react-js";
import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "features/submission-dashboard/PVFormsLayouts";
import {
  PVTextareaFormField,
  PVTextInputFormField,
  PVLabel,
} from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionMutation } from "services/pvSlice";
import {
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
} from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";
import { emptyStringToNull } from "utils/zod-utils";
import "./PVSOVAndInsuredReview.scss";

const formSchema = z.object({
  inception_date: emptyStringToNull().optional(),
  expiration_date: emptyStringToNull().optional(),
  insured_name: emptyStringToNull().optional(),
});

type FormInput = z.infer<typeof formSchema>;

type PVSOVAndInsuredReviewProps = {
  selectedItem: SovDataType;
};

export const PVSOVAndInsuredReview: FC<PVSOVAndInsuredReviewProps> = ({
  selectedItem,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      inception_date: selectedItem.inception_date || "",
      expiration_date: selectedItem.expiration_date || "",
      insured_name: selectedItem.insured_name || "",
    },
  });

  const readinessScoreData = getHumanFriendlyDataReadinessScore(
    selectedItem?.data_readiness_score,
  );

  const triageOverallResultData = getHumanFriendlyTriageScore(
    selectedItem?.triage_rules_overall_result,
    selectedItem.is_ping_ready,
  );

  const [updateSubmission, { isLoading: isSubmitting }] =
    useUpdateSubmissionMutation();

  useEffect(() => {
    // Update form values when selectedItem changes
    reset({
      inception_date: selectedItem.inception_date || "",
      expiration_date: selectedItem.expiration_date || "",
      insured_name: selectedItem.insured_name || "",
    });
  }, [selectedItem, reset]);

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const result = await updateSubmission({ id: selectedItem.id, data });

      if (!result.error) {
        setIsEditing(false);
        return;
      }

      setServerErrors(result.error.data, setError);
    },
    [selectedItem.id, setError, updateSubmission],
  );

  return (
    <form className="PVSOVAndInsuredReview" onSubmit={handleSubmit(onSubmit)}>
      <PVScrollSpySectionHeader title="SOV and Insured Review">
        {!isEditing ? (
          <PingButton
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
            }}
            disabled={isSubmitting}
            label="Edit"
          />
        ) : (
          <>
            <PingButton
              onClick={(e) => {
                e.preventDefault();
                setIsEditing(false);
                reset();
              }}
              disabled={isSubmitting}
              label="Cancel"
            />
            <PingButton
              type="submit"
              variant="primary"
              disabled={isSubmitting}
              label="Save"
            />
          </>
        )}
      </PVScrollSpySectionHeader>

      <PVInputsColumnsGrid>
        {/* First Column - SOV Review */}
        <PVInputsColumn title="SOV Review">
          <PVLabel
            label="# of Buildings"
            value={selectedItem.num_buildings?.toString() || "---"}
          />

          <PVLabel
            label="TIV"
            value={formatCurrency(selectedItem.limits_total_limit) || "---"}
          />

          <PVLabel
            label="Readiness"
            value={readinessScoreData?.label || "---"}
            icon={readinessScoreData?.icon as LucideIconName}
            iconColor={readinessScoreData?.color}
          />

          <PVLabel
            label="Underwriting Triage"
            value={triageOverallResultData?.label || "---"}
            icon={triageOverallResultData?.icon as LucideIconName}
            iconColor={triageOverallResultData?.color}
          />
        </PVInputsColumn>

        {/* Second Column - Insured Information */}
        <PVInputsColumn title="Insured Information">
          <PVTextareaFormField
            label="Insured Name"
            name="insured_name"
            currentValue={selectedItem.insured_name || "---"}
            defaultValue={selectedItem.insured_name || ""}
            register={register}
            error={errors.insured_name}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Inception Date"
            name="inception_date"
            currentValue={
              selectedItem.inception_date ? selectedItem.inception_date : "---"
            }
            defaultValue={selectedItem.inception_date || ""}
            register={register}
            error={errors.inception_date}
            isEditing={isEditing}
            type="date"
          />

          <PVTextInputFormField
            key={selectedItem.expiration_date}
            label="Expiration Date"
            name="expiration_date"
            currentValue={
              selectedItem.expiration_date
                ? selectedItem.expiration_date
                : "---"
            }
            defaultValue={selectedItem.expiration_date || ""}
            register={register}
            error={errors.expiration_date}
            isEditing={isEditing}
            type="date"
          />

          <PVLabel
            label="Client Reference"
            value={selectedItem.client_ref || "---"}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
