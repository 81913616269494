import { useState, useEffect } from "react";
import cx from "classnames";
import { PingVisionModal } from "./PingVisionModal";
import {
  excludedConvertTypes,
  DocumentType,
  DOCUMENT_TYPES,
} from "features/submission-dashboard/types";
import {
  PingButton,
  PingTextInput,
  PingSelectInput,
} from "@repo/ping-react-js";
import { useUpdateSubmissionDocumentMutation } from "services/pvSlice";
import toast from "react-hot-toast";

import "./FileChangeModal.scss";

interface FileChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClickOk: (newFileName: string, newDocumentType: DocumentType) => void;
  currentFileName: string;
  pingId: string;
  currentDocumentType: DocumentType;
}

const FileChangeModal: React.FC<FileChangeModalProps> = ({
  isOpen,
  onClose,
  onClickOk,
  currentFileName,
  pingId,
  currentDocumentType,
}) => {
  const [newFileName, setNewFileName] = useState(currentFileName);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<DocumentType>(currentDocumentType);
  const [updateSubmissionDocument] = useUpdateSubmissionDocumentMutation();

  useEffect(() => {
    setNewFileName(currentFileName);
  }, [currentFileName]);

  useEffect(() => {
    setSelectedDocumentType(currentDocumentType);
  }, [currentDocumentType]);

  const handleRenameClick = async () => {
    try {
      await updateSubmissionDocument({
        id: pingId,
        filename: currentFileName,
        data: { filename: newFileName, document_type: selectedDocumentType },
      });
      const changes = [];
      if (newFileName !== currentFileName) changes.push("document name");
      if (selectedDocumentType !== currentDocumentType)
        changes.push("document type");
      const changeDescription = changes.join(" and ") + ".";
      toast.success(`Successfully updated ${changeDescription}`);
      onClickOk(newFileName, selectedDocumentType);
      onClose();
    } catch {
      toast.error("Failed to update document. Please try again.");
      onClose();
    }
  };

  const submitButtonDisabled =
    newFileName === currentFileName &&
    selectedDocumentType === currentDocumentType;

  const filteredDocumentTypes = DOCUMENT_TYPES.filter(
    (type) => !excludedConvertTypes.includes(type),
  );

  const options = filteredDocumentTypes.map((type) => ({
    label: type.replace(/_/g, " "),
    value: type,
  }));

  return (
    <PingVisionModal
      className={{ base: "FileChangeModal" }}
      isOpen={isOpen}
      onClose={onClose}
      title={`Update ${currentDocumentType?.replace("_", " ")} - ${currentFileName}`}
      renderFooter={() => (
        <>
          <PingButton
            label="Cancel"
            onClick={() => {
              onClose();
            }}
          />
          <PingButton
            disabled={submitButtonDisabled}
            className={cx("FileChangeModal__OkButton", {
              "FileChangeModal__OkButton--enabled": !submitButtonDisabled,
            })}
            label="Update"
            onClick={handleRenameClick}
          />
        </>
      )}
      renderContent={() => (
        <div className="FileChangeModal__Content">
          <div>
            <label
              htmlFor="new_file_name"
              className="FileChangeModal__Content__Label"
            >
              Change File Name
            </label>
            <PingTextInput
              id="new_file_name"
              type="text"
              value={newFileName}
              onChange={(e) => {
                setNewFileName(e.target.value);
              }}
              inputClassName="FileChangeModal__Content__TextInput"
              placeholder={"Enter new name"}
              name="new_file_name"
            />
          </div>
          <div>
            <label
              htmlFor="document_type"
              className="FileChangeModal__Content__Label"
            >
              Reclassify Document Type
            </label>
            <PingSelectInput
              id="document_type"
              options={options}
              value={selectedDocumentType}
              onChange={(e) => {
                setSelectedDocumentType(e.target.value as DocumentType);
              }}
              name="document_type"
            />
          </div>
        </div>
      )}
    />
  );
};

export default FileChangeModal;
