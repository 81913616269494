import React, {
  createContext,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import slugify from "slugify";

import { useAppSelector } from "src/utils/redux";
import { FRONT_END_BASE_URL } from "constants/index";
import { useSearchQueryBuilder } from "@repo/ping-react-js";
import {
  SEARCH_PARAM_NAME,
  useGetAdvancedSearchFields,
  getSubmissionQueryKeyForSlug,
  useParsedSearchValues,
  omittedSearchFields,
  useGetSubmissionList,
} from "src/features/submission-dashboard/queries";
import { useHistory } from "react-router-dom";
import { setGlobalSortConfig, SortConfig } from "src/reducers/settings";
import { usePingId, useTeamName } from "src/utils/hooks";
import isFunction from "lodash/isFunction";
import omit from "lodash/omit";
import { useGetSingleSubmission } from "features/submission-dashboard/queries";
import { setSelectedSovItem } from "src/services/selectedSovSlice";
import { setCurrentCursorId } from "src/reducers/inbox";

interface SubmissionsQueryGlobalContextType {
  /**
   * Handles navigation to a new submission view while using the correct sort parameters and nullifying the cursor
   * Must be used in place of direct navigation to submission views
   *
   * @param slug - The new submission view slug to navigate to
   * @param teamName - The team name for constructing the URL path
   */
  handleSlugChangeForSubmissionsQuery: (
    slug: string,
    teamName?: string,
  ) => void;

  /**
   * Handles updating the search state in the URL and Redux store
   * @param searchParams - The search parameters to update
   */
  handleSearchStateChange: (
    searchParams: Record<string, string> | null,
  ) => void;

  /**
   * Handles changing the sort configuration
   * @param sortConfig - The new sort configuration
   */
  handleSortChange: (sortConfig: any) => void;

  /**
   * Checks if the assignee of the item filters it out of the current list
   * @param assigneeId - The item's assignee
   * @returns True if the item is filtered out of the current list, false otherwise
   */
  hasAssigneeMismatch: (assigneeId: number | null | undefined) => boolean;

  /**
   * Checks if the workflow status of the item filters it out of the current list
   * @param workflowStatusId - The item's workflow status
   * @returns True if the item is filtered out of the current list, false otherwise
   */
  hasWorkflowMismatch: (workflowStatusId: number) => boolean;

  /**
   * Retrieves the applied filters for a specific submission slug
   *
   * This function fetches the filters for a given slug, preferring the search parameters
   * over the navToQueryParams.
   *
   * @param slug - The submission view slug
   * @returns The applied filters for the view
   */
  getAppliedListFilters: (
    slug: string | null | undefined,
    teamName: string | undefined | null,
    excludeCurrentValues?: boolean,
  ) => Record<string, string>;

  /**
   * Retrieves the global submissions list. A wrapper that makes it easy for UI components
   * to share the submissions query.
   *
   * @returns The global submissions list
   */
  globalSubmissionsData: Record<string, any>;

  /**
   * The current search values
   */
  searchValues: Record<string, string>;
}

const SubmissionsQueryGlobalContext = createContext<
  SubmissionsQueryGlobalContextType | undefined
>(undefined);

/**
 * SubmissionsQueryGlobalProvider is a context provider that manages global submission query state.
 *
 * This component centralizes the management of:
 * - Search parameters and filters for submissions
 * - Sort configuration for submission lists
 * - Navigation between different submission views
 * - Filter matching logic for assignees and workflow statuses
 *
 * It provides a unified interface for components to interact with submission queries
 * without having to manage complex state logic themselves.
 */
export const SubmissionsQueryGlobalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Redux dispatch for state updates
  const dispatch = useDispatch();

  // Get advanced search fields for the search query builder
  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();

  // Router history for navigation and URL manipulation
  const history = useHistory();

  // Search query builder utilities for managing search parameters in the URL
  const { setSearchValues, clearSearchValues } = useSearchQueryBuilder(
    fields,
    history,
    SEARCH_PARAM_NAME,
  );

  // Current user ID from Redux store
  const userId = useAppSelector((state) => state.settings?.envData?.user?.id);

  // Current inbox slug from Redux store
  const inboxSlug = useAppSelector((state) => state.inbox.slug);

  // Current team ID from custom hook
  const teamName = useTeamName();

  // We need to delay calling the submissions query on page load, until we have set the right sort config
  const sortConfigSetOnPageLoad = useRef(false);

  // Sometimes it is necessary to separately fetch the selected submission, which may not be part of the initial pagination
  // set. We consider this use case only on the first page load.
  const singleSubmissionQueryCalled = useRef(false);

  // Extract search parameters from the URL and remove omitted fields
  const searchParamsObject = Object.fromEntries(
    new URLSearchParams(history.location.search),
  );
  const searchValuesWithoutOmitted = omit(
    searchParamsObject,
    omittedSearchFields,
  );
  const searchValues = useParsedSearchValues(searchValuesWithoutOmitted);

  // Get navigation query parameters from Redux store
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );

  const isNotASubmissionsView =
    !navToQueryParams[getSubmissionQueryKeyForSlug(inboxSlug, teamName)];

  const globalSubmissionsData = useGetSubmissionList({
    skipOverride: !sortConfigSetOnPageLoad.current || isNotASubmissionsView,
  });

  /**
   * Retrieves the applied filters for a specific submission slug
   *
   * This function fetches the filters for a given slug, preferring the search parameters
   * over the navToQueryParams.
   *
   * @param slug - The submission view slug
   * @returns The applied filters for the view
   */
  const getAppliedListFilters = useCallback(
    (
      slug: string | null | undefined,
      teamName: string | undefined | null,
      excludeCurrentValues: boolean = false,
    ) => {
      if (!slug) {
        return {};
      }

      const keyForAdditionalFilters = getSubmissionQueryKeyForSlug(
        slug,
        teamName,
      );
      const filters = isFunction(navToQueryParams?.[keyForAdditionalFilters])
        ? navToQueryParams?.[keyForAdditionalFilters](userId)?.filter
        : (navToQueryParams?.[keyForAdditionalFilters]?.filter ?? null);

      // include search filters, where they win
      let appliedFilters = filters || {};

      if (searchValues && !excludeCurrentValues) {
        appliedFilters = { ...appliedFilters, ...searchValues };
      }

      return appliedFilters;
    },
    [navToQueryParams, userId, searchValues],
  );

  /**
   * Converts filter values to URL search parameters, while preserving arrays
   * @param filterValues - The filter values to convert
   * @returns A string that can be used as URL search parameters
   */
  const getAppliedListFiltersAsUrlParams = (
    filterValues: Record<string, string | number | number[] | undefined | null>,
  ): string => {
    if (!filterValues) {
      return "";
    }

    // Create a params object to build the query string
    const params: Record<string, string> = {};

    // Process each filter value
    Object.entries(filterValues).forEach(([key, value]) => {
      // Skip null or undefined values
      if (value === null || value === undefined) {
        return;
      }

      // Handle arrays (like team_id, claimed_by_id, workflow_status_id, etc.)
      if (Array.isArray(value)) {
        // If the array is empty, skip
        if (value.length === 0) {
          return;
        }

        // Format arrays as JSON arrays: key=[1,2,3]
        params[key] = JSON.stringify(value);
      }
      // Handle single values
      else {
        params[key] = value.toString();
      }
    });

    // Convert the params object to a URL query string
    return Object.entries(params)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");
  };

  /**
   * Updates the sort configuration in the Redux store and URL
   *
   * This function handles changes to the sort configuration. It updates the
   * global sort configuration in the Redux store and updates the URL search
   * parameters if they differ from the current values.
   *
   *
   * @param sortConfig - The new sort configuration with field and direction
   */
  const handleSortChange = useCallback(
    (sortConfig: SortConfig) => {
      // Update the global sort configuration in Redux
      dispatch(setGlobalSortConfig(sortConfig));

      // Get current URL search parameters
      const params = new URLSearchParams(window.location.search);

      // Only update URL if sort parameters have changed
      if (
        params.get("sort_field") !== sortConfig?.field ||
        params.get("sort_direction") !== sortConfig?.direction
      ) {
        params.set("sort_field", sortConfig?.field);
        params.set("sort_direction", sortConfig?.direction);
        history.push({ search: params.toString() });
      }
    },
    [dispatch, history],
  );

  /**
   * Handles setting the sort configuration for a specific submission view
   *
   * This function retrieves the sort configuration for a given slug and team ID
   * from the navToQueryParams in the Redux store. If a configuration exists,
   * it updates the global sort configuration. Otherwise, it sets a default
   * configuration sorting by received_time in ascending order.
   *
   * @param slug - The submission view slug
   * @param teamName - The team name associated with the view
   */
  const handleSortViewConfigForSlug = useCallback(
    (slug: string | null | undefined, teamName: string | undefined | null) => {
      // Get the sortConfig for this view from navToQueryParams
      const viewConfig = navToQueryParams[
        getSubmissionQueryKeyForSlug(slug, teamName)
      ] as any;

      if (viewConfig && viewConfig.sortConfig) {
        // Update the global sortConfig in Redux with the view-specific configuration
        handleSortChange(viewConfig.sortConfig as SortConfig);
      } else if (viewConfig) {
        // Set default sortConfig if none exists for this view
        handleSortChange({
          field: "received_time",
          direction: "asc",
        });
      }
    },
    [handleSortChange, navToQueryParams],
  );

  /**
   * Updates the search state in the URL and clears search values if necessary
   *
   * This function handles changes to the search parameters. If data is null,
   * it clears all search values. Otherwise, it updates the search values in
   * the URL and search input box, using the search query builder.
   *
   *
   * @param data - The new search parameters or null to clear all search values
   */
  const handleSearchStateChange = useCallback(
    (data: Record<string, string> | null) => {
      if (!data) {
        clearSearchValues();
        return;
      }

      setSearchValues(data);
    },
    [clearSearchValues, setSearchValues],
  );

  /**
   * The purpose of this useEffect is to set the default sort configuration for the first page load
   * This effect runs only once when the component mounts to handle the first page load
   */
  useEffect(() => {
    if (
      !sortConfigSetOnPageLoad.current &&
      inboxSlug &&
      Object.keys(navToQueryParams).length > 0
    ) {
      handleSortViewConfigForSlug(inboxSlug, teamName);
      const appliedFilters = getAppliedListFilters(inboxSlug, teamName, true);

      // Get the current search parameters
      const currentSearchParams = new URLSearchParams(history.location.search);

      // Initialize query params object
      let queryParams: Record<string, any> = {};

      // Only preserve sort_field and sort_direction from existing parameters
      const sortField = currentSearchParams.get("sort_field");
      const sortDirection = currentSearchParams.get("sort_direction");
      const selected = currentSearchParams.get("selected");
      const search = currentSearchParams.get("search");

      // Add existing sort parameters if they exist, and also the selected and search params
      if (sortField) queryParams.sort_field = sortField;
      if (sortDirection) queryParams.sort_direction = sortDirection;
      if (selected) queryParams.selected = selected;
      if (search) queryParams.search = search;

      // Add filter parameters
      queryParams = { ...queryParams, ...appliedFilters };

      // Convert all parameters to URL search string format
      const queryString = getAppliedListFiltersAsUrlParams(queryParams);

      // Update the URL with the new search parameters while preserving the pathname
      history.push({
        pathname: history.location.pathname,
        search: queryString,
      });

      sortConfigSetOnPageLoad.current = true;
    }
  }, [
    getAppliedListFilters,
    handleSortViewConfigForSlug,
    dispatch,
    inboxSlug,
    teamName,
    navToQueryParams,
    searchParamsObject,
    history,
  ]);

  /**
   * Handles navigation to a new submission view while taking care of sort params associated with that view, and cursor concerns
   * We seem to have to handle sorting and cursor nullification here, and not just in useGetSubmissionList in
   * queries.ts, due to some race conditions from the Redux state.
   *
   * We also handle cursor nullification directly in pvSlice.ts as well. Ultimately, we need to handle cursor
   * nullification here and in pvSlice.ts, in addition to useGetSubmissionList in the queries.ts file, to ensure that the cursor is reset when navigating between views.
   *
   * This function is a critical part of the navigation flow. It performs
   * several important tasks:
   * - Sets the cursor to null on navigation
   * - Sets the appropriate sort configuration for the new view
   * - Keeps the sort parameters in the URL (please note that we do not currently put the filter params in the URL, outside of the user searching actively)
   * - Handles the actual navigation using history.push
   *
   * @param slug - The new submission view slug to navigate to
   * @param teamName - The team name for constructing the URL path
   */
  const handleSlugChangeForSubmissionsQuery = useCallback(
    (slug: string | null | undefined, teamName: string | null | undefined) => {
      // Reset cursor when navigating to a new view
      dispatch(setCurrentCursorId(null));

      // Set the appropriate sort configuration for the new view
      handleSortViewConfigForSlug(slug, teamName);

      // Extract sort-related parameters from the current URL
      const currentParams = new URLSearchParams(history.location.search);
      const sortField = currentParams.get("sort_field");
      const sortDirection = currentParams.get("sort_direction");

      // Get applied filters for the current slug from navToQueryParams and nothing else from URL
      const appliedFilters = getAppliedListFilters(slug, teamName, true);

      // Combine sort parameters with filter parameters
      let queryParams = {};

      // Add sort parameters if they exist
      if (sortField) queryParams = { ...queryParams, sort_field: sortField };
      if (sortDirection)
        queryParams = { ...queryParams, sort_direction: sortDirection };

      // Add filter parameters
      queryParams = { ...queryParams, ...appliedFilters };

      // Convert all parameters to URL search string format
      const queryString = getAppliedListFiltersAsUrlParams(queryParams);

      // Construct the path based on whether we have a team name
      if (slug) {
        const path = teamName
          ? `${FRONT_END_BASE_URL}/${slugify(teamName)}/views/${slug}`
          : `${FRONT_END_BASE_URL}/${slug}`;

        // Create the full URL with all parameters
        const fullUrl = queryString ? `${path}?${queryString}` : path;

        // Navigate to the path with preserved sort parameters and filters
        history.push(fullUrl);
      }
    },
    [getAppliedListFilters, handleSortViewConfigForSlug, dispatch, history],
  );

  /**
   * Determines if an item should be filtered out based on its assignee ID
   *
   * This function checks if an item's assignee matches the current filter criteria.
   * It handles several cases:
   * 1. If claimed_by_id__isnull is false: Filter out items without an assignee
   * 2. If claimed_by_id__isnull is true: Filter out items with an assignee
   * 3. If claimed_by_id is set: Filter out items with a different assignee
   * 4. If no assignee filters are set: Don't filter out any items
   *
   * @param assigneeId - The assignee ID of the item to check
   * @returns true if the item should be filtered out, false if it matches the filter
   */
  const hasAssigneeMismatch = useCallback(
    (assigneeId: number | null | undefined) => {
      const filters = getAppliedListFilters(inboxSlug, teamName);

      const claimed_by_id = filters?.claimed_by_id;
      const claimed_by_id__isnull = filters?.claimed_by_id__isnull;

      // Case 1: Filter is set to show only assigned items
      if (claimed_by_id__isnull === false) {
        return Boolean(!assigneeId); // Filter out items without an assignee
      }

      // Case 2: Filter is set to show only unassigned items
      if (claimed_by_id__isnull === true) {
        return Boolean(assigneeId); // Filter out items with an assignee
      }

      // Case 3: No assignee filters are set
      if (!claimed_by_id?.length && !claimed_by_id__isnull) {
        return false; // Don't filter out any items
      }

      // Case 4: Filter by specific assignee ID
      return !claimed_by_id?.includes(assigneeId);
    },
    [getAppliedListFilters, inboxSlug, teamName],
  );

  /**
   * Determines if an item should be filtered out based on its workflow status ID
   *
   * This function checks if an item's workflow status matches the current filter criteria.
   * It handles two cases:
   * 1. If no workflow status filters are set: Don't filter out any items
   * 2. If workflow status filters are set: Filter out items whose status is not in the filter list
   *
   * @param itemWorkflowStatusId - The workflow status ID of the item to check
   * @returns true if the item should be filtered out, false if it matches the filter
   */
  const hasWorkflowMismatch = useCallback(
    (itemWorkflowStatusId: number): boolean => {
      const filters = getAppliedListFilters(inboxSlug, teamName);

      // Get workflow status filter values from search values or navToQueryParams
      const workflow_status_id = filters?.workflow_status_id || [];

      // Case 1: No workflow status filters are set
      if (!workflow_status_id.length) {
        return false; // Don't filter out any items
      }

      // Case 2: Filter by specific workflow status IDs
      return !workflow_status_id.includes(itemWorkflowStatusId); // Filter out items not in the filter list
    },
    [getAppliedListFilters, inboxSlug, teamName],
  );

  // this gets the selected ping id, either from the URL or the route params
  const selectedItemId = usePingId();

  // This tracks the selected item globally, since we could get it from the main list, or from a separate single submission query on first load.
  const selectedSovItem = useAppSelector(
    (state) => state.selectedSov.selectedSovItem,
  );

  /**
   * Checks if a single submission should be fetched
   *
   * This variable determines whether a single submission should be fetched based on the current state.
   * It checks if the singleSubmissionQueryCalled flag is false, if the navigation parameters have been loaded from the server,
   * if a selected submission ID exists on the URL search params or route params, if the inbox slug is valid, if a selected item has yet been set globally, and if
   * the list search results have loaded without that selected item in it (this can happen when the selected item is not part of the initial pagination set).
   */
  const shouldFetchSingleSubmission =
    !singleSubmissionQueryCalled.current &&
    Object.keys(navToQueryParams).length &&
    selectedItemId &&
    globalSubmissionsData?.data?.results &&
    !globalSubmissionsData?.data?.results.find(
      (submission) => submission.id === selectedItemId,
    ) &&
    inboxSlug &&
    !selectedSovItem?.id;

  const { data: singleSubmission } = useGetSingleSubmission(
    selectedItemId,
    {
      ...getAppliedListFilters(inboxSlug, teamName),
    },
    { skip: !shouldFetchSingleSubmission }, // we skip unless the conditions above have been met
  );

  useEffect(() => {
    if (
      !globalSubmissionsData.isLoading &&
      globalSubmissionsData.data?.results &&
      singleSubmission?.results?.[0]
    ) {
      // Store the selected submission from the single submission call, globally. This useEffect should only ever run once,
      // on first page load.

      // The setSelectedSovItem functionis also dispatched when submissions are selected from the list, in the relevant
      // UI components.

      if (!singleSubmissionQueryCalled.current) {
        dispatch(setSelectedSovItem(singleSubmission.results[0]));

        singleSubmissionQueryCalled.current = true;
      }
    }
  }, [
    globalSubmissionsData.isLoading,
    globalSubmissionsData.data?.results,
    singleSubmission?.results?.[0],
  ]);

  /**
   * The values to be shared through the context provider
   * This object contains all the state and functions that will be accessible
   * to consumers of the context via the useSubmissionsQueryGlobal hook.
   */
  const valuesToShare = {
    // this is how all UI components should be accessing the submissions data
    globalSubmissionsData,
    // Current search values without selected items
    searchValues: searchValuesWithoutOmitted,
    // Functions for managing search, sort, and navigation
    handleSearchStateChange,
    handleSortChange,
    handleSlugChangeForSubmissionsQuery,
    // Functions for checking if items match current filters
    hasAssigneeMismatch,
    hasWorkflowMismatch,
    getAppliedListFilters,
  };

  return (
    <SubmissionsQueryGlobalContext.Provider value={valuesToShare}>
      {children}
    </SubmissionsQueryGlobalContext.Provider>
  );
};

/**
 * Custom hook to use the route change context
 * @returns The route change context
 * @throws Error if used outside of a RouteChangeProvider
 */
export const useSubmissionsQueryGlobal =
  (): SubmissionsQueryGlobalContextType => {
    const context = useContext(SubmissionsQueryGlobalContext);

    if (!context) {
      throw new Error(
        "useSubmissionsQueryGlobal must be used within a SubmissionsQueryGlobalProvider",
      );
    }

    return context;
  };
