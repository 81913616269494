import { DetailedHTMLProps, FC, HTMLProps, InputHTMLAttributes } from "react";
import { LucideIconName, PingLucideIcon } from "@repo/ping-react-js";
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { PVReadOnlyValue } from "features/submission-dashboard/PVFormsLayouts";
import { NOT_AVAILABLE } from "constants/SubmissionConstants";

import {
  PingSelectInput,
  PingSelectOptions,
  PingTextarea,
  PingTextInput,
} from "@repo/ping-react-js";

type PVErrorDisplayProps = HTMLProps<HTMLDivElement>;

const PVErrorDisplay: FC<PVErrorDisplayProps> = ({
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className="PVErrorDisplay">
      {children}
    </div>
  );
};

type PVTextInputFormFieldProps<T extends FieldValues> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  currentValue?: string | number | readonly string[] | null;
  error?: FieldError;
  isEditing?: boolean;
};

interface PVLabelProps {
  value: string | null;
  label: string;
  icon?: LucideIconName;
  iconSize?: number;
  iconColor?: string;
}

const PVLabel = ({
  value,
  label,
  icon,
  iconSize = 16,
  iconColor,
}: PVLabelProps) => {
  return (
    <>
      <label htmlFor={label}>{label}</label>
      <PVReadOnlyValue>
        {icon && (
          <PingLucideIcon
            className="PVLabel__Icon"
            iconName={icon}
            size={iconSize}
            color={iconColor}
            aria-hidden="true"
          />
        )}
        <span>{value || "---"}</span>
      </PVReadOnlyValue>
    </>
  );
};

const PVTextInputFormField = <T extends FieldValues>({
  label,
  name,
  register,
  currentValue,
  error,
  isEditing = false,
  ...restProps
}: PVTextInputFormFieldProps<T>) => {
  return (
    <>
      <label htmlFor={restProps.id}>{label}</label>
      {isEditing ? (
        <PingTextInput
          {...register(name)}
          id={restProps.id}
          defaultValue={currentValue || ""}
          readOnly={restProps.readOnly}
          {...restProps}
        />
      ) : (
        <PVReadOnlyValue>{currentValue || NOT_AVAILABLE}</PVReadOnlyValue>
      )}
      {error && <PVErrorDisplay>{error.message}</PVErrorDisplay>}
    </>
  );
};

type PVSelectInputFormFieldProps<T extends FieldValues> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label: string;
  name: Path<T>;
  options: PingSelectOptions;
  register: UseFormRegister<T>;
  currentValue?: string | number | readonly string[] | null;
  error?: FieldError;
  isEditing?: boolean;
};

const PVSelectInputFormField = <T extends FieldValues>({
  label,
  name,
  options,
  register,
  currentValue,
  error,
  isEditing = false,
  ...restProps
}: PVSelectInputFormFieldProps<T>) => {
  return (
    <>
      <label htmlFor={restProps.id}>{label}</label>
      {isEditing ? (
        <PingSelectInput
          {...register(name)}
          options={options}
          id={restProps.id}
          defaultValue={currentValue || ""}
          readOnly={restProps.readOnly}
          {...restProps}
        />
      ) : (
        <PVReadOnlyValue>{currentValue || NOT_AVAILABLE}</PVReadOnlyValue>
      )}
      {error && <PVErrorDisplay>{error.message}</PVErrorDisplay>}
    </>
  );
};

type PVTextareaFormFieldProps<T extends FieldValues> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  label: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  currentValue?: string | number | readonly string[] | null;
  error?: FieldError;
  isEditing?: boolean;
};

const PVTextareaFormField = <T extends FieldValues>({
  label,
  name,
  register,
  currentValue,
  error,
  isEditing = false,
  ...restProps
}: PVTextareaFormFieldProps<T>) => {
  return (
    <>
      <label htmlFor={restProps.id}>{label}</label>
      {isEditing ? (
        <PingTextarea
          {...register(name)}
          id={restProps.id}
          defaultValue={currentValue || ""}
          readOnly={restProps.readOnly}
          {...restProps}
        />
      ) : (
        <PVReadOnlyValue>{currentValue || NOT_AVAILABLE}</PVReadOnlyValue>
      )}
      {error && <PVErrorDisplay>{error.message}</PVErrorDisplay>}
    </>
  );
};

export {
  PVLabel,
  PVTextInputFormField,
  PVSelectInputFormField,
  PVTextareaFormField,
  PVErrorDisplay,
};
