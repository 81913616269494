import { z } from "zod";

import type { LucideIconName } from "@repo/ping-react-js";

import { UserType, TeamType, SubmissionStatusType } from "./DataTypes";

export type SettingsResponseType = {
  teams: TeamType[];
  submission_status: SubmissionStatusType[];
  users: UserType[];
};

export type NavigationResponse = {
  views: NavigationItem[];
};

export type CategoryView = {
  name: string;
  description?: string;
  items: NavigationItem[];
  icon: LucideIconName;
  team_name?: string;
  team_id?: string | number;
  slug?: string;
  filter?: Record<string, any>;
  originalCustomViewSlug?: string;
  view_type?: "TRIAGE" | "LIST" | "USER_CUSTOM";
};
export type NavigationItem = CategoryView;

export type View = {
  slug?: string;
  name: string;
  view_type?: "TRIAGE" | "LIST";
  description?: string;
  filters?: Record<string, string | number>;
  group_by?: string;
  order_by?: string;
  icon: LucideIconName;
  items?: View[];
  team_name?: string;
  team_id?: number | string;
};

export type ActivityItemType = {
  id: number;
  cause: string;
  action: number;
  actor_id: null | number;
  actor_username: null | string;
  timestamp: string;
  object_id: number;
  messages: string[] | Record<string, string>[];
  verb: string;
  avatar?: string;
  name?: string;
};

const emailCorrespondenceResponseItemSchema = z
  .object({
    id: z.number(),
    submission_id: z.number(),
    subject: z.string().nullable(),
    html: z.string().nullable(),
    from_address: z.string().nullable(),
    to_addresses: z.string().nullable(),
    cc_addresses: z.string().or(z.array(z.string())).nullable(),
    source_email_time: z.string().datetime().nullable(),
  })
  .strict();

export const emailCorrespondenceResponseSchema = z.array(
  emailCorrespondenceResponseItemSchema,
);

export type EmailCorrespondenceResponseItem = z.infer<
  typeof emailCorrespondenceResponseItemSchema
>;

export type EmailCorrespondenceResponse = z.infer<
  typeof emailCorrespondenceResponseSchema
>;

export const genericMutationResponseSchema = z
  .object({
    message: z.string(),
  })
  .strict();

export type GenericMutationResponse = z.infer<
  typeof genericMutationResponseSchema
>;

export const bulkUpdateSubmissionRequestSchema = z
  .object({
    ids: z.array(z.string()),
    changes: z.array(
      z.object({
        action: z.string(),
        parameters: z.record(
          z.string(),
          z.union([z.string(), z.number(), z.null()]),
        ),
      }),
    ),
  })
  .strict();

export type BulkUpdateSubmissionRequest = z.infer<
  typeof bulkUpdateSubmissionRequestSchema
>;

export const bulkUpdateSubmissionResponseSchema = z
  .object({
    results: z.array(
      z.object({
        id: z.string(),
        updated_data: z.record(
          z.string(),
          z.union([z.string(), z.number(), z.null()]),
        ),
      }),
    ),
  })
  .strict();

export type BulkUpdateSubmissionResponse = z.infer<
  typeof bulkUpdateSubmissionResponseSchema
>;

/**
 * A partial set of fields for a submission document. Used for mutations that
 * update only a subset of document fields (e.g renaming, reclassifying, or
 * archiving a document).
 */
export const partialSubmissionDocumentSchema = z
  .object({
    filename: z.string().optional(),
    document_type: z.string().optional(),
    is_archived: z.boolean().optional(),
  })
  .strict();

export type PartialSubmissionDocument = z.infer<
  typeof partialSubmissionDocumentSchema
>;

/**
 * Same as the `partialSubmissionDocumentSchema`, but wrapped in an object.
 */
export const partialSubmissionDocumentResponseSchema = z
  .object({
    document: partialSubmissionDocumentSchema,
  })
  .strict();

export type PartialSubmissionDocumentResponse = z.infer<
  typeof partialSubmissionDocumentResponseSchema
>;
