export const getApiBaseUrl = () => {
  if (import.meta.env.VITE_APP_ENV === "local") {
    const port = import.meta.env.VITE_APP_LOCAL_API_PORT || "80";
    return `http://local.pingintel.com:${port}/`;
  }

  if (import.meta.env.VITE_APP_ENV === "prd") {
    return "https://vision.pingintel.com/";
  } else if (import.meta.env.VITE_APP_ENV === "prdeu") {
    new Error("not supported!");
  } else if (import.meta.env.VITE_APP_ENV === "stg") {
    return "https://vision.staging.pingintel.com/";
  } else {
    return "https://vision.dev.pingintel.com/";
  }
};

export const getMapsBaseUrl = () => {
  if (import.meta.env.VITE_APP_ENV === "local") {
    return `http://local.pingintel.com:8001/`;
  }
  if (import.meta.env.VITE_APP_ENV === "prd") {
    return "https://api.sovfixer.com/";
  } else if (import.meta.env.VITE_APP_ENV === "prdeu") {
    new Error("not supported!");
  } else if (import.meta.env.VITE_APP_ENV === "stg") {
    return "https://api-staging.sovfixer.com/";
  } else {
    return "https://api-dev.sovfixer.com/";
  }
};
