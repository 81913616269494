import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DocumentType } from './types';


// We have two PVAllDocumentsPreviewPanel instances. One is only for the collapsed pills header, one is for the full expanded panel. For them
// to remember and retain states upon collapsing/opening, this context provider has been made.

interface PreviewContextState {
  activeType: DocumentType | null;
  showArchived: boolean;
  isCollapsed: boolean;
  setActiveType: (type: DocumentType | null) => void;
  setShowArchived: (show: boolean) => void;
  setIsCollapsed: (collapsed: boolean) => void;
}

const PreviewContext = createContext<PreviewContextState | undefined>(undefined);

interface PreviewContextProviderProps {
  children: ReactNode;
}

export const PreviewContextProvider: React.FC<PreviewContextProviderProps> = ({ children }) => {
  const [activeType, setActiveType] = useState<DocumentType | null>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <PreviewContext.Provider
      value={{
        activeType,
        showArchived,
        isCollapsed,
        setActiveType,
        setShowArchived,
        setIsCollapsed,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreviewContext = () => {
  const context = useContext(PreviewContext);
  if (context === undefined) {
    throw new Error('usePreviewContext must be used within a PreviewContextProvider');
  }
  return context;
};
