/**
 * Compares two objects by their keys and values
 * @param obj1 - First object to compare
 * @param obj2 - Second object to compare
 * @returns Boolean indicating if objects have the same keys and values
 */
export const areObjectsEqual = (obj1: Record<string, any> | undefined, obj2: Record<string, any> | undefined): boolean => {
    // Handle cases where one or both objects are undefined/null
    if (!obj1 && !obj2) return true;
    if (!obj1 || !obj2) return false;
    
    // Get all keys from both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    
    // If number of keys is different, objects are not equal
    if (keys1.length !== keys2.length) return false;
    
    // Check if all keys in obj1 exist in obj2 with the same values
    return keys1.every(key => {
      // Handle array values
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (obj1[key].length !== obj2[key].length) return false;
        // Compare array elements
        return obj1[key].every((val: any, index: number) => val === obj2[key][index]);
      }
      // Handle nested objects
      if (typeof obj1[key] === 'object' && obj1[key] !== null && 
          typeof obj2[key] === 'object' && obj2[key] !== null) {
        return areObjectsEqual(obj1[key], obj2[key]);
      }
      // Compare primitive values
      return obj1[key] === obj2[key];
    });
  };