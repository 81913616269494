import { useState, FC } from "react";
import cx from "classnames";
import { Schema } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import { undo, redo, history } from "prosemirror-history";
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import { ProseMirror, ProseMirrorDoc } from "@handlewithcare/react-prosemirror";
import "./PingRichTextInputField.scss";

/**
 * An input field that allows applying any CSS styles to the text being edited.
 *
 * The use case for this component is to allow inline editing of content that we
 * want to style more comprehensively compared to what a regular text input
 * allows. For example, a text input field doesn't allow text to wrap in
 * multiple lines, but this component does.
 */
export const PingRichTextInputField: FC<PingRichTextInputField> = ({
  schemaOptions,
  initialContent,
  onBlur,
  className,
}) => {
  const [editorState, setEditorState] = useState(() => {
    let schema;
    let doc;
    if (schemaOptions.type === "heading") {
      schema = makeHeadingSchema(schemaOptions);
      doc = makeHeadingDoc(schema, schemaOptions, initialContent);
    } else if (schemaOptions.type === "text") {
      schema = makeTextSchema();
      doc = makeTextDoc(schema, schemaOptions, initialContent);
    }

    return EditorState.create({
      doc,
      schema,
      plugins: [
        history(),
        keymap({
          "Mod-z": undo,
          "Mod-y": redo,
          Enter: (_state, _dispatch, view) => {
            if (!view) {
              return false;
            }

            view.dom.blur();
            return true;
          },
        }),
        keymap(baseKeymap),
        onBlurPlugin(onBlur),
      ],
    });
  });

  const appliedClasses = cx("PingRichTextInputField", className);

  return (
    <ProseMirror
      state={editorState}
      dispatchTransaction={(transaction) => {
        setEditorState((state) => state.apply(transaction));
      }}
    >
      <div className={appliedClasses}>
        <ProseMirrorDoc />
      </div>
    </ProseMirror>
  );
};

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingSchemaOptions = {
  type: "heading";
  level: HeadingLevel;
};

const makeHeadingSchema = ({ level }: HeadingSchemaOptions) =>
  new Schema({
    nodes: {
      doc: { content: "heading{1}" },
      heading: {
        content: "text*",
        attrs: { level: { default: level } },
        toDOM(node) {
          return ["h" + node.attrs.level, 0];
        },
        parseDOM: [{ tag: "*" }],
      },
      text: { inline: true },
    },
  });

const makeHeadingDoc = (
  schema: Schema,
  schemaOptions: HeadingSchemaOptions,
  content: string,
) =>
  schema.node("doc", null, [
    schema.node("heading", { level: schemaOptions.level }, [
      schema.text(content),
    ]),
  ]);

export type TextSchemaOptions = {
  type: "text";
};

const makeTextSchema = () =>
  new Schema({
    nodes: {
      doc: {
        content: "singleText{1}",
      },
      singleText: {
        content: "text*",
        toDOM: () => ["div", 0],
        parseDOM: [{ tag: "*" }],
      },
      text: {
        group: "inline",
      },
    },
  });

const makeTextDoc = (
  schema: Schema,
  _schemaOptions: TextSchemaOptions,
  content: string,
) =>
  schema.node("doc", null, [
    schema.node("singleText", null, [schema.text(content)]),
  ]);

const onBlurPlugin = (callback: (content: string) => void) =>
  new Plugin({
    props: {
      handleDOMEvents: {
        blur: (view) => {
          callback(view.state.doc.textContent);
        },
      },
    },
  });

type PingRichTextInputField = {
  schemaOptions: HeadingSchemaOptions | TextSchemaOptions;
  initialContent: string;
  onBlur: (newContent: string) => void;
  className?: string;
};
