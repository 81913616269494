import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "services/pvSlice.ts";

/**
 * Interface for the filtered submission IDs state.
 * Maintains a mapping of slugs to arrays of submission IDs that have been filtered out
 * from the view. This allows for persistent filtering across pagination and queries.
 */
interface FilteredSubmissionIdsState {
  /**
   * Record mapping slug (view identifier) to an array of filtered submission IDs for that slug.
   * Each slug represents a different view or context in the application.
   */
  filteredIds: Record<string, string[]>;

  /**
   * Arguments from the last submission query, used to determine when to clear filters.
   * This helps maintain filters during pagination but reset them when other query parameters change.
   */
  lastQueryArgs?: any;
}

/**
 * Initial state for the filtered submission IDs slice.
 */
const initialState: FilteredSubmissionIdsState = {
  filteredIds: {}, // Empty record to start with no filtered IDs
  lastQueryArgs: null, // No previous query args on initialization
};

/**
 * Redux slice for managing filtered submission IDs throughout the application.
 * This slice tracks which submission IDs should be filtered out from the view for each slug (view).
 * It provides actions to add, clear, and manage filtered IDs, and synchronizes with submission queries.
 */
export const filteredSubmissionIdsSlice = createSlice({
  name: "filteredSubmissionIds",
  initialState,
  reducers: {
    /**
     * Adds multiple submission IDs to the filteredIds for a specific slug.
     * This action is used when the user wants to hide/filter out specific submissions from the view.
     * Ensures that no duplicate IDs are added to the filtered list.
     *
     * @param state - The current state of the slice
     * @param action - The action containing the slug and an array of submission IDs to add to the filtered list
     */
    addFilteredSubmissionIds: (
      state,
      action: PayloadAction<{ slug: string; ids: string[] }>,
    ) => {
      const { slug, ids } = action.payload;

      // Initialize the array for this slug if it doesn't exist
      if (!state.filteredIds[slug]) {
        state.filteredIds[slug] = [];
      }

      // Filter out IDs that are already in the array to avoid duplicates
      // This improves performance by preventing unnecessary state updates
      const newIds = ids.filter((id) => !state.filteredIds[slug].includes(id));

      // Add the new IDs to the array for this slug
      // Create a new array to ensure Redux detects the state change
      state.filteredIds[slug] = [...state.filteredIds[slug], ...newIds];
    },

    /**
     * Clears all submission IDs from the filteredIds for all slugs.
     * This action is used when a complete reset of all filters is needed,
     * such as when logging out or performing a global reset.
     *
     * @param state - The current state of the slice
     */
    clearFilteredSubmissionIds: (state) => {
      state.filteredIds = {};
    },

    /**
     * Clears submission IDs for a specific slug only.
     * This action is used when filters should be reset for a particular view
     * but preserved for other views in the application.
     *
     * @param state - The current state of the slice
     * @param action - The action containing the slug identifier to clear filters for
     */
    clearFilteredSubmissionIdsForSlug: (
      state,
      action: PayloadAction<string>,
    ) => {
      const slug = action.payload;
      // Only attempt to delete if the entry exists
      if (state.filteredIds[slug]) {
        delete state.filteredIds[slug];
      }
    },
  },
  /**
   * Extra reducers to handle side effects from other slices and API calls.
   */
  extraReducers: (builder) => {
    /**
     * Handles the successful completion of a getSubmissions query.
     * Clears filtered IDs when query parameters (other than cursor) have changed.
     * This ensures that filters are reset when the user changes search criteria or view context,
     * but maintains filters during simple pagination operations.
     */
    builder.addMatcher(
      api.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        // Extract the current query arguments from the action
        const currentArgs = action.meta.arg.originalArgs;

        // Get the previous query arguments stored in state
        const prevArgs = state.lastQueryArgs;

        // Handle first request case
        if (!prevArgs) {
          state.lastQueryArgs = currentArgs;
          return;
        }

        // Create copies of the args without cursor for comparison
        // This allows pagination to occur without clearing filters
        const currentArgsWithoutCursor = {
          ...currentArgs,
          cursorId: undefined,
        };
        const prevArgsWithoutCursor = { ...prevArgs, cursorId: undefined };

        // Compare the stringified objects to detect non-cursor changes
        // (e.g., filter changes, sort changes, etc.)
        const hasNonCursorChanges =
          JSON.stringify(currentArgsWithoutCursor) !==
          JSON.stringify(prevArgsWithoutCursor);

        // Clear filtered IDs only when substantive query parameters have changed
        if (hasNonCursorChanges) {
          state.filteredIds = {};
        }

        // Store current args for future comparisons
        state.lastQueryArgs = currentArgs;
      },
    );
  },
});

/**
 * Export the action creators for use throughout the application.
 * These actions can be dispatched to add or clear filtered submission IDs.
 */
export const {
  addFilteredSubmissionIds,
  clearFilteredSubmissionIds,
  clearFilteredSubmissionIdsForSlug,
} = filteredSubmissionIdsSlice.actions;

/**
 * Export the reducer as the default export for use in the Redux store configuration.
 */
export default filteredSubmissionIdsSlice.reducer;
