import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createWebSocketUrl,
  createWebSocketConnection,
  registerWebSocketListener,
  closeWebSocketConnection,
} from "./websocketService";

/**
 * Version state interface
 */
interface VersionState {
  currentVersion: string | null;
  wsConnected: boolean;
}

/**
 * Version update message interface
 */
interface VersionUpdateMessage {
  type: "version_update";
  data: {
    version: string;
  };
}

// WebSocket connection ID for version updates
const VERSION_CONNECTION_ID = "version";

/**
 * Initialize the version WebSocket connection
 * @param token Auth token for the WebSocket connection
 * @param dispatch Redux dispatch function
 */
export const initializeVersionWebSocket = (
  token: string,
  dispatch: any,
): void => {
  // Close any existing connection
  closeWebSocketConnection(VERSION_CONNECTION_ID);

  // Create search params with the token
  const searchParams = new URLSearchParams();
  searchParams.append("token", token);

  // Create WebSocket URL
  const wsUrl = createWebSocketUrl("/ws/version/");

  // Create the WebSocket connection
  createWebSocketConnection(VERSION_CONNECTION_ID, wsUrl, searchParams);

  dispatch(versionSlice.actions.setWsConnected(true));

  // Register a listener for version update messages
  registerWebSocketListener(
    VERSION_CONNECTION_ID,
    "version_update",
    (message: VersionUpdateMessage) => {
      if (message.data?.version) {
        dispatch(versionSlice.actions.setCurrentVersion(message.data.version));
      }
    },
  );
};

// Initial state for version slice
const initialState: VersionState = {
  currentVersion: null,
  wsConnected: false,
};

/**
 * Version slice for managing version state
 */
export const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    /**
     * Update the current application version
     */
    setCurrentVersion: (state, action: PayloadAction<string>) => {
      state.currentVersion = action.payload;
    },

    /**
     * Update WebSocket connection status
     */
    setWsConnected: (state, action: PayloadAction<boolean>) => {
      state.wsConnected = action.payload;
    },
  },
});

/**
 * Selector to check if a new version is available
 * Compares the current version with the last known version
 */
// Export actions
export const { setCurrentVersion, setWsConnected } = versionSlice.actions;

// Export reducer
export const versionReducer = versionSlice.reducer;
