import { useState, useEffect } from "react";
import classNames from "classnames";
import { useAppDispatch } from "utils/redux";

import { PingVisionModal } from "./PingVisionModal";
import {
  PingButton,
  PingMaterialIcon,
  PingVirtualizedSearchSelectInput,
} from "@repo/ping-react-js";
import { findSubmissionTitle } from "utils/submission";
import { api } from "services/pvSlice";

import "./MarkSubmissionDuplicateModal.scss";

interface Option {
  label: string;
  value: string;
}

interface FetchOptionsResponse {
  data: Option[];
  nextCursor?: string | null;
  hasMore: boolean;
  totalCount?: number;
}

interface FetchOptionsParams {
  cursor?: string | null;
  search?: string;
}

const MarkSubmissionDuplicateModal = ({
  isOpen,
  onClose,
  onClickYes,
  selectedItem,
}) => {
  const dispatch = useAppDispatch();
  const [duplicateOfSubmissionId, setDuplicateOfSubmissionId] =
    useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [cachedInitialOptions, setCachedInitialOptions] =
    useState<FetchOptionsResponse | null>(null);

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setDuplicateOfSubmissionId("");
      setSelectedOption(null);
      setSearchValue("");
      setCachedInitialOptions(null);
    }
  }, [isOpen]);

  // Clear cache when modal closes
  useEffect(() => {
    if (!isOpen) {
      setCachedInitialOptions(null);
    }
  }, [isOpen]);

  const handleYesClick = () => {
    onClickYes(duplicateOfSubmissionId);
  };

  const fetchOptions = async ({
    cursor,
    search,
  }: FetchOptionsParams): Promise<FetchOptionsResponse> => {
    try {
      // Use cached results if no search term and we have cached data
      if (!search && !cursor && cachedInitialOptions) {
        return cachedInitialOptions;
      }

      const result = await dispatch(
        api.endpoints.getNotRealTimeSubmissions.initiate({
          cursor_id: cursor || undefined,
          search,
          limit: 100,
          team_id: selectedItem?.team_id,
        }),
      ).unwrap();

      // If there are no results, return an empty array
      if (!result.results || result.results.length === 0) {
        return {
          data: [],
          nextCursor: null,
          hasMore: false,
        };
      }

      const options = result.results
        .filter((sov) => sov.id !== selectedItem?.id)
        .map((sov) => ({
          label: `${findSubmissionTitle(sov)} (${sov.id})`,
          value: sov.id.toString(),
          searchText: `${findSubmissionTitle(sov)} ${sov.id}`,
        }));

      // If filtering removed all options, return empty array
      if (options.length === 0) {
        return {
          data: [],
          nextCursor: null,
          hasMore: false,
        };
      }

      const response: FetchOptionsResponse = {
        data: options,
        nextCursor: result.cursor_id,
        hasMore: result.has_remaining ?? false,
      };

      // Cache initial results if this is the first fetch with no search term
      if (!search && !cursor) {
        setCachedInitialOptions(response);
      }

      return response;
    } catch (error) {
      console.error("Error fetching options:", error);
      return {
        data: [],
        nextCursor: null,
        hasMore: false,
      };
    }
  };

  const handleSearchChange = (newValue: string) => {
    setSearchValue(newValue);
    // Clear selection when search is cleared
    if (!newValue.trim()) {
      setDuplicateOfSubmissionId("");
      setSelectedOption(null);
    }
  };

  return (
    <PingVisionModal
      className={{ base: "MarkSubmissionDuplicateModal" }}
      contentClassName="MarkSubmissionDuplicateModal__Content"
      isOpen={isOpen}
      onClose={onClose}
      title="Mark as Duplicate"
      renderFooter={() => (
        <>
          <PingButton label="Cancel" onClick={onClose} />
          <PingButton
            label="OK"
            onClick={handleYesClick}
            disabled={!duplicateOfSubmissionId}
          />
        </>
      )}
      renderContent={() => (
        <div>
          <div className="MarkSubmissionDuplicateModal__Content__Text">
            Mark{" "}
            <span className="MarkSubmissionDuplicateModal__Content__Text__Title">
              {findSubmissionTitle(selectedItem)} ({selectedItem.id})
            </span>{" "}
            as a duplicate of:
          </div>
          <PingVirtualizedSearchSelectInput
            selectedValue={duplicateOfSubmissionId}
            onSelect={(value, option) => {
              setDuplicateOfSubmissionId(value || "");
              setSelectedOption(option || null);
              setSearchValue("");
            }}
            fetchOptions={fetchOptions}
            placeholder="Search by submission title or ID..."
            initialOptions={[]}
            value={searchValue}
            onChange={handleSearchChange}
            clearCache={isOpen}
          />
          <div
            className={classNames(
              "MarkSubmissionDuplicateModal__Content__Status",
              duplicateOfSubmissionId
                ? "MarkSubmissionDuplicateModal__Content__Status--Selected"
                : "MarkSubmissionDuplicateModal__Content__Status--NotSelected",
            )}
          >
            {duplicateOfSubmissionId ? (
              <>
                <PingMaterialIcon iconName="check_circle" />
                Selected: {selectedOption?.label}
              </>
            ) : (
              "No submission selected"
            )}
          </div>
        </div>
      )}
    />
  );
};

export default MarkSubmissionDuplicateModal;
